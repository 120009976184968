import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { BrandsIcon } from '../../assets';
import { replaceIds, ROUTES } from '../../config/routes/config';
import { CREATE_BRAND } from '../../graphql/brands/mutation';
import type { ResCreateBrandType } from '../../types';
import { Button } from '../common/Button';
import { TextInput } from '../inputs/TextInput';
import { PopupLayout } from './PopupLayout';

type TCreateBrand = {
  name: string;
  address: string;
  cif: string;
  paymentTerms: string;
  companyName: string;
};

export function BrandPopUp({
  setOpen,
  refetch,
  redirect = true,
  setCreatedBrand,
}: {
  setOpen: any;
  refetch: any;
  redirect?: Boolean;
  setCreatedBrand?: any;
}) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<TCreateBrand>();
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const [createBrand, { loading }] =
    useMutation<ResCreateBrandType>(CREATE_BRAND);

  const onSubmit = (data: TCreateBrand) => {
    createBrand({
      variables: {
        ...data,
      },
      onCompleted: (res) => {
        if (redirect) {
          navigate(
            replaceIds({
              url: ROUTES.SINGLE_BRAND,
              params: [{ id: res.createBrand.data || '' }],
            }),
          );
        }
        reset();
        setCreatedBrand(res.createBrand.data);
        refetch();
        setOpen(false);
      },
      onError: () => {},
    });
  };

  const closeModal = () => {
    reset();
    setOpen(false);
  };

  const stopPropagationAndSubmit = (event: any) => {
    event.stopPropagation();
    handleSubmit(onSubmit)(event);
  };
  return (
    <>
      <PopupLayout
        title={t('brands.createBrandTitle')}
        closeModal={closeModal}
        icon={<BrandsIcon />}
      >
        <form
          onSubmit={stopPropagationAndSubmit}
          className="flex flex-col gap-y-5 mt-5"
        >
          <div className="flex flex-row gap-x-8 w-full">
            <TextInput
              register={register}
              registerName="name"
              type="text"
              label={t('brands.createName')}
              placeholder={t('brands.createNamePlaceholder')}
              validation={{ required: t('brands.nameRequired') }}
              errors={errors.name}
            />
            <TextInput
              register={register}
              registerName="companyName"
              type="text"
              label={t('brands.createRazon')}
              placeholder={t('brands.createRazon')}
              validation={{ required: t('brands.razonRequired') }}
              errors={errors.companyName}
            />
          </div>
          <TextInput
            register={register}
            registerName="address"
            type="text"
            maxLength={999}
            label={t('brands.createAddress')}
            placeholder={t('brands.createAddressPlaceholder')}
            validation={{ required: t('brands.addressRequired') }}
            errors={errors.address}
          />
          <div className="flex flex-row gap-x-8 w-full">
            <TextInput
              register={register}
              registerName="cif"
              type="text"
              label={t('brands.createCif')}
              placeholder={t('brands.createCifPlaceholder')}
              validation={{ required: t('brands.cifRequired') }}
              errors={errors.cif}
            />
            <TextInput
              register={register}
              registerName="paymentTerms"
              type="number"
              label={t('brands.createPaymentTerms')}
              placeholder={t('brands.createPaymentTermsPlaceholder')}
              validation={{ required: t('brands.paymentTermsRequired') }}
              errors={errors.paymentTerms}
            />
          </div>
          <div className="flex justify-end">
            <div className="w-[150px]">
              <Button
                style="light-blue"
                id="createBrandPopup"
                text={t('global.save')}
                customClassnames="w-full justify-center"
                type="submit"
                loading={loading}
              />
            </div>
          </div>
        </form>
      </PopupLayout>
    </>
  );
}
