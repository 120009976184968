export const formatNumber = (num: string | number) => {
  if (typeof num === 'string')
    return Intl.NumberFormat('es-ES').format(parseInt(num, 10));
  return Intl.NumberFormat('es-ES').format(num);
};

export const formatNumberMoney = (
  num: string | number,
  decimals?: number,
  moneyType?: string
) => {
  let currency = 'EUR';
  if (moneyType === '$') {
    currency = 'USD';
  }
  if (typeof num === 'string')
    return Intl.NumberFormat('es-ES', {
      style: 'currency',
      currency: currency,
      useGrouping: true,
      minimumFractionDigits: decimals !== undefined ? decimals : 2,
    }).format(parseInt(num, 10));
  return Intl.NumberFormat('es-ES', {
    style: 'currency',
    currency: currency,
    useGrouping: true,
    minimumFractionDigits: decimals !== undefined ? decimals : 2,
  }).format(num);
};

export const formatNumberPercentage = (num: string | number) => {
  let number = typeof num === 'string' ? parseInt(num, 10) : num;
  number /= 100;
  return Intl.NumberFormat('es-ES', {
    style: 'percent',
    maximumFractionDigits: 2,
  }).format(number);
};

export function formatDate(timestamp: number): string {
  // Crear un objeto Date a partir del timestamp
  const date = new Date(timestamp);

  // Ajustar la hora restando 2 horas (7200000 milisegundos)
  date.setHours(date.getHours());

  // Definir los meses del año en español
  const months = [
    'ENE',
    'FEB',
    'MAR',
    'ABR',
    'MAY',
    'JUN',
    'JUL',
    'AGO',
    'SEP',
    'OCT',
    'NOV',
    'DIC',
  ];

  // Obtener los componentes de la fecha y hora
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  // Formatear la fecha en el formato deseado
  return `${day} ${month} ${hours}:${minutes}`;
}

export function formatDateFull(timestamp: number): string {
  // Crear un objeto Date a partir del timestamp
  const date = new Date(timestamp);

  // Ajustar la hora restando 2 horas (7200000 milisegundos)
  date.setHours(date.getHours());

  // Definir los meses del año en español
  const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];

  // Obtener los componentes de la fecha y hora
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  // Formatear la fecha en el formato deseado
  return `${day} ${month} de ${year}`;
}

export function formatDateSimple(timestamp: number): string {
  // Crear un objeto Date a partir del timestamp
  const date = new Date(timestamp);

  // Ajustar la hora restando 2 horas (7200000 milisegundos)
  date.setHours(date.getHours());

  // Definir los meses del año en español
  const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];

  // Obtener los componentes de la fecha y hora
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  // Formatear la fecha en el formato deseado
  return `${day} de ${month}`;
}

export function formatCustomDate(dateString: string, userLanguage = 'en') {
  const date = new Date(dateString);

  // Opciones para formatear el día de la semana, mes y día
  const optionsDate = {
    weekday: 'short' as 'short', // Para obtener el nombre corto del día
    month: 'short' as 'short',
    day: 'numeric' as 'numeric',
  };

  // Opciones para formatear la hora
  const optionsTime = {
    hour: '2-digit' as '2-digit',
    minute: '2-digit' as '2-digit',
    hour12: false,
  };

  // Formateamos la fecha (con el día de la semana) y la hora usando el idioma del usuario
  const formattedDatePart = new Intl.DateTimeFormat(
    userLanguage,
    optionsDate
  ).format(date);
  const formattedTimePart = new Intl.DateTimeFormat(
    userLanguage,
    optionsTime
  ).format(date);

  // Extraemos el año y lo formateamos a dos dígitos
  const twoDigitYear = date.getFullYear().toString().slice(-2);

  // Extraemos las partes formateadas de la fecha (día de la semana, día, mes)
  const [weekday, day, month] = formattedDatePart.replace(',', '').split(' ');

  // Construimos el formato personalizado
  const customFormattedDate = `${capitalizeFirstLetter(weekday)} ${day} ${month} ${twoDigitYear} · ${formattedTimePart}`;

  return customFormattedDate;
}


export function capitalizeFirstLetter(string:string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}