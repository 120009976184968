import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { InfluencersIcon } from '../../assets';
import { replaceIds, ROUTES } from '../../config/routes/config';
import { CREATE_USER } from '../../graphql/user/mutation';
import type { ResCreateUserType } from '../../types';
import { Button } from '../common/Button';
import { Error } from '../common/Error';
import { TextInput } from '../inputs/TextInput';
import { Toggle } from '../inputs/Toggle';
import { PopupLayout } from './PopupLayout';

type TCreateInfluencer = {
  name: string;
  email: string;
  hasAccess: boolean;
};

export function InfluencerPopUp({
  setOpen,
  refetch,
  redirect = true,
  setCreatedInfluencer,
}: {
  setOpen: any;
  refetch: any;
  redirect?: Boolean;
  setCreatedInfluencer?: any;
}) {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<TCreateInfluencer>({
    defaultValues: {
      name: '',
      email: '',
      hasAccess: true,
    },
  });
  const navigate = useNavigate();
  const [createUser, { loading }] = useMutation<ResCreateUserType>(CREATE_USER);
  const [notCreated, setNotCreated] = useState('');
  const { t } = useTranslation('common');
  const onSubmit = (data: TCreateInfluencer) => {
    createUser({
      variables: {
        name: data.name,
        email: data.email,
        hasAccess: data.hasAccess,
        role: 'Influencer',
      },
      onCompleted: (res) => {
        if (redirect) {
          navigate(
            replaceIds({
              url: ROUTES.SINGLE_INFLUENCER,
              params: [{ id: res.createUser.data || '' }],
            }),
          );
        }
        setCreatedInfluencer(res.createUser.data);
        reset();
        setOpen(false);
        refetch();
      },
      onError: (error) => {
        setNotCreated(error.message);
      },
    });
  };

  const closeModal = () => {
    reset();
    setOpen(false);
  };
  useEffect(() => {
    if (notCreated === '') return;
    setTimeout(() => {
      setNotCreated('');
    }, 5000);
  }, [notCreated]);

  const stopPropagationAndSubmit = (event: any) => {
    event.stopPropagation();
    handleSubmit(onSubmit)(event);
  };

  return (
    <>
      <PopupLayout
        title={t('influencers.createTitle')}
        closeModal={closeModal}
        icon={<InfluencersIcon />}
      >
        <form
          onSubmit={stopPropagationAndSubmit}
          className="flex flex-col gap-y-5 mt-5"
        >
          <div className="flex flex-row gap-x-8 w-full">
            <TextInput
              register={register}
              registerName="name"
              type="text"
              label={t('influencers.createName')}
              placeholder={t('influencers.createNamePlaceholder')}
              validation={{ required: t('influencers.nameRequired') }}
              errors={errors.name}
            />
            <TextInput
              register={register}
              registerName="email"
              type="text"
              label={t('influencers.createEmail')}
              placeholder={t('influencers.createEmailPlaceholder')}
              validation={{ required: t('influencers.emailRequired') }}
              errors={errors.email}
            />
            <Toggle
              topLabel
              maxWidth="max-w-[300px]"
              label={t('influencers.hasAccess')}
              preText={t('influencers.invitateToPlatform')}
              value={watch('hasAccess')}
              onChange={() => {
                setValue('hasAccess', !watch('hasAccess'));
              }}
            />
          </div>
          {notCreated && notCreated !== '' && <Error error={notCreated} />}
          <div className="flex justify-end">
            <div className="w-[150px]">
              <Button
                id="createInfluencerPopup"
                style="light-blue"
                text={t('global.save')}
                customClassnames="w-full justify-center"
                type="submit"
                loading={loading}
              />
            </div>
          </div>
        </form>
      </PopupLayout>
    </>
  );
}
