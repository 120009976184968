import { Link } from 'react-router-dom';

type LinkButtonProps = {
  to: string;
  text: string;
};

export const LinkButton = ({ to, text }: LinkButtonProps) => {
  return (
    <Link to={to}>
      <span className="text-white underline text-sm font-[500]">{text}</span>
    </Link>
  );
};
