import { gql } from '@apollo/client';

export const UPDATE_INVOICE = gql`
  mutation UpdateInvoice(
    $invoiceId: String!
    $amount: Float
    $paymentDate: String
    $billingDate: String
    $status: String
    $amountType: String
  ) {
    updateInvoice(
      invoiceId: $invoiceId
      amount: $amount
      paymentDate: $paymentDate
      billingDate: $billingDate
      status: $status
      amountType: $amountType
    ) {
      status
      code
      data
      error
    }
  }
`;

export const DELETE_INVOICE = gql`
  mutation DeleteInvoice($invoiceId: String!) {
    deleteInvoice(invoiceId: $invoiceId) {
      status
      code
      data
      error
    }
  }
`;
export const CREATE_INVOICE = gql`
  mutation CreateInvoice(
    $campaignId: String!
    $file: Upload!
    $brandId: String
    $userId: String
    $price: String
    $paymentDate: String
    $billingDate: String
    $currency: String
  ) {
    createInvoice(
      campaignId: $campaignId
      file: $file
      brandId: $brandId
      userId: $userId
      price: $price
      paymentDate: $paymentDate
      billingDate: $billingDate
      currency: $currency
    ) {
      status
      code
      data
      error
    }
  }
`;
