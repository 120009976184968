import 'dayjs/locale/es';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { GET_ALL_INVOICES } from '../../graphql/invoices/query';
import { useTableDataAndFilters } from '../../hooks/useTableDataAndFilters';
import {
  brandsInvoicesFilterAtom,
  influencersInvoicesFilterAtom,
} from '../../states/atoms';
import type {
  InvoicesFilters,
  InvoicesTable,
  ResponseDataBrandList,
} from '../../types';
import { ActiveFilters } from '../filters/ActiveFilters';
import { BrandInvoicesFilters } from '../filters/BrandInvoicesFilters';
import { InfluencerInvoicesFilters } from '../filters/InfluencerInvoicesFilters';
import { TableSearchInput } from '../inputs/TableSearchInput';
import DataTable from '../tables/DataTable';
import {
  getBrandsInvoicesTableHeaders,
  getInfluencersInvoicesTableHeaders,
} from '../tables/headers/invoices';

type InvoicesTableTableProps = {
  brand: boolean;
};

function InvoicesTableTable({ brand }: InvoicesTableTableProps) {
  const { t } = useTranslation('common');

  /**
   * Hook in charge of the logic of the data, filtering and pagination
   */
  const { resetPagination, total, filters, setFilters, table, error, loading } =
    useTableDataAndFilters<
      InvoicesFilters,
      InvoicesTable,
      {},
      ResponseDataBrandList
    >({
      filterAtom: brand
        ? brandsInvoicesFilterAtom
        : influencersInvoicesFilterAtom,
      getColumns: brand
        ? getBrandsInvoicesTableHeaders
        : getInfluencersInvoicesTableHeaders,
      columnsExtras: {},
      responseAccessKey: 'invoices',
      query: GET_ALL_INVOICES,
      extraVariables: {
        brand,
      },
    });

  return (
    <div className="flex flex-col gap-y-4">
      <div className="flex flex-row justify-between">
        <div>
          <TableSearchInput
            placeholder={
              brand
                ? t('invoices.searchInputPlaceholder')
                : t('invoices.searchInputPlaceholderInfluencer')
            }
            filters={{ ...filters }}
            setFilters={(val) => {
              setFilters(val);
              resetPagination();
            }}
            searchKey="name"
          />
        </div>
        <div className="flex flex-row gap-x-2">
          {/* <CampaignFiltersPopover
              _setFilters={(val) => {
                setFilters(val);
                resetPagination();
              }}
            /> */}
          {brand ? (
            <BrandInvoicesFilters
              setFilters={(val) => {
                setFilters(val);
                resetPagination();
              }}
              filters={filters}
            />
          ) : (
            <InfluencerInvoicesFilters
              setFilters={(val) => {
                setFilters(val);
                resetPagination();
              }}
              filters={filters}
            />
          )}
        </div>
      </div>
      <ActiveFilters filters={filters} setFilters={setFilters} />
      <DataTable
        error={error}
        loading={loading}
        table={table}
        total={total}
        tableId="InvoicesTable"
        sorting={{ ...filters }}
      />
    </div>
  );
}

export default InvoicesTableTable;
