import { useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import { useOutletContext } from 'react-router-dom';

import { CREATE_METRIC } from '../../../graphql/metrics/mutation';
import { GET_METRICS } from '../../../graphql/metrics/query';
import { useNotification } from '../../../hooks/useNotification';
import { usePermissions } from '../../../hooks/usePermissions';
import type { Metric } from '../../../types';
import FileUploader from '../../common/FileUploader';
import { Metrics } from '../metrics/Metrics';

export const SingleCampaignMetricsInfluencersTab = () => {
  const [campaignId]: [string] = useOutletContext();
  const { isInfluencer } = usePermissions();
  const [metrics, setMetrics] = useState<Metric[]>();
  const { notifySaveSuccess, notifySaveError } = useNotification();

  const { refetch } = useQuery(GET_METRICS, {
    variables: {
      campaignId,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setMetrics(data.metrics.data);
    },
  });
  const [createMetric, { loading }] = useMutation(CREATE_METRIC);

  const handleFile = (file: File) => {
    createMetric({
      variables: {
        campaignId,
        type: 'picture',
        file,
      },
      context: {
        headers: {
          'content-type': 'multipart/form-data',
          'x-apollo-operation-name': 'createMetric',
        },
      },
      onCompleted: () => {
        notifySaveSuccess();
        refetch();
      },
      onError: () => {
        notifySaveError();
      },
    });
  };
  return (
    <div>
      <div className={`${!isInfluencer() && 'hidden'} mb-5 `}>
        <FileUploader
          submitFile={handleFile}
          fileType="Metric"
          loading={loading}
        />
      </div>
      {metrics &&
        metrics.map(
          (metric: any) =>
            metric.metrics.length > 0 && (
              <Metrics
                key={metric.id + Math.random()}
                metric={metric}
                refetch={refetch}
              />
            ),
        )}
    </div>
  );
};
