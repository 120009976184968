import type { ChangeEvent, DragEvent } from 'react';
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  CalendarIcon,
  FileIcon,
  MoneyCashIcon,
  UploadIcon,
} from '../../assets';
import { useNotification } from '../../hooks/useNotification';
import { usePermissions } from '../../hooks/usePermissions';
import { DatePickerInput } from '../inputs/DatePicker';
import { MoneyInput } from '../inputs/MoneyInput';
import { Button } from './Button';

type Props = {
  submitFile: (file: File) => void;
  resetOnSubmit?: boolean;
  fileType?: string;
  loading?: boolean;
  setDirty?: any;
  control?: any;
  currentCurrency?: string;
  handleSubmit?: any;
  errors?: any;
};

const FileUploader = forwardRef(
  (
    {
      submitFile,
      resetOnSubmit = true,
      handleSubmit,
      fileType,
      loading,
      setDirty,
      control,
      currentCurrency,
      errors,
    }: Props,
    ref,
  ) => {
    const [file, setFile] = useState<File | null>(null);
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const { t } = useTranslation('common');
    let fileTypes: string[] = [];
    let maxSize: number = 10;

    // Determine allowed file types and max size based on fileType prop
    if (fileType === 'Script') {
      fileTypes = ['.txt', '.pdf', '.doc'];
    } else if (fileType === 'Preview') {
      fileTypes = ['.mp4'];
      maxSize = 100;
    } else if (fileType === 'Metric') {
      fileTypes = ['.png', '.jpg'];
    } else if (
      fileType === 'Report' ||
      fileType === 'Invoice' ||
      fileType === 'Briefing'
    ) {
      fileTypes = ['.pdf'];
    } else if (fileType === 'Resource') {
      fileTypes = ['.pdf'];
      maxSize = 100;
    }

    const { notifyFileError, notifySelectedFile, notifyFileSizeError } =
      useNotification();

    const resetInput = (isCancel?: Boolean) => {
      if (resetOnSubmit || isCancel) {
        setFile(null);
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      }
    };

    useImperativeHandle(ref, () => ({
      resetInput,
    }));

    const selectFile = (selectedFile: File) => {
      if (selectedFile) {
        const filesize = (selectedFile.size / 1024 / 1024).toFixed(4); // MB
        if (Number(filesize) > maxSize) {
          notifyFileSizeError(maxSize);
          setFile(null);
          if (fileInputRef.current) {
            fileInputRef.current.value = '';
          }
          return;
        }
        const ext = `.${selectedFile.name.split('.').pop()?.toLowerCase()}`;
        if (fileTypes.includes(String(ext))) {
          setFile(selectedFile);
          if (setDirty) {
            setDirty(true);
          }
          notifySelectedFile();
        } else {
          notifyFileError();
          resetInput();
        }
      }
    };

    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      if (event.dataTransfer.files) {
        const selectedFile = event.dataTransfer.files[0] || null;
        if (selectedFile) {
          selectFile(selectedFile);
        }
      }
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      if (event.target.files) {
        const selectedFile = event.target.files[0] || null;
        if (selectedFile) {
          selectFile(selectedFile);
        }
      }
    };

    const { isInfluencer, isInfluentia } = usePermissions();
    const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault();
    };

    const openFileDialog = () => {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    };

    const text = () => {
      return t(`singleCampaign.select${fileType}`);
    };

    const uploadFile = () => {
      if (file) {
        if (handleSubmit) {
          handleSubmit(submitFile(file));
        } else {
          submitFile(file);
        }
        resetInput();
      }
    };

    return (
      <form
        onSubmit={(event) => {
          event.preventDefault();
          event.stopPropagation();
          if (handleSubmit) {
            handleSubmit(uploadFile)();
          } else {
            uploadFile();
          }
        }}
        className="flex flex-col"
      >
        <div
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          // eslint-disable-next-line no-nested-ternary
          // className={`relative flex ${file ? (errors && errors.billingDate && handleSubmit ? 'h-[300px]' : 'h-[100px]') : 'h-[150px] border-2 border-dashed'} rounded-[5px]  border-light-blue gap-5`}
          className={`relative flex ${file ? '' : 'min-h-[150px] border-2 border-dashed'} rounded-[5px]  border-light-blue gap-5`}
        >
          <input
            type="file"
            onChange={handleFileChange}
            className="hidden"
            id="fileUploaderInput"
            ref={fileInputRef}
            accept={fileTypes.map((type) => `${type}`).join(',')}
          />
          {file ? (
            <div className="w-full object-contain flex items-center justify-center flex-col text-sm font-inter text-light-blue">
              <div className="flex flex-row w-full items-center">
                <div
                  className={`flex flex-col gap-2.5 ${fileType === 'Invoice' && isInfluentia() ? 'items-end' : 'items-center'} w-full mr-6`}
                >
                  <div className="flex flex-col gap-2.5 text-center items-center">
                    <div>
                      <FileIcon className="size-[35px]" />
                    </div>
                    <span>{t('singleCampaign.selectedFile')}</span>
                    <span>{file.name}</span>
                  </div>
                </div>
                <div
                  className={`w-full ${fileType === 'Invoice' && isInfluentia() ? '' : 'hidden'}`}
                >
                  <div className="max-w-[250px]">
                    <div
                      className={`flex flex-row gap-1.5 ${isInfluencer() && 'hidden'}`}
                    >
                      <div className="bg-white rounded-full size-7 p-1.5 mt-4 flex items-center justify-center">
                        <MoneyCashIcon className="size-[16px] text-influentia-black" />
                      </div>
                      {isInfluentia() && control && (
                        <MoneyInput
                          height="h-[33px]"
                          control={control}
                          type="blue"
                          name="Price"
                          label={t('singleCampaign.invoiceCampaignPrice')}
                          validation={{
                            required: t('singleCampaign.PriceRequired'),
                          }}
                          currencySelect={true}
                          currentCurrency={currentCurrency}
                          errors={errors.Price}
                        />
                      )}
                    </div>
                    <div
                      className={`flex flex-row gap-1.5 ${isInfluencer() && 'hidden'}`}
                    >
                      <div className="bg-white rounded-full size-7 p-1.5 flex mt-4 items-center justify-center">
                        <CalendarIcon className="size-[16px] text-influentia-black" />
                      </div>
                      {isInfluentia() && control && (
                        <DatePickerInput
                          height="h-[37px]"
                          control={control}
                          type="blue"
                          name="billingDate"
                          label={t('global.billingDate')}
                          errors={errors.billingDate}
                          validation={{
                            required: t('singleCampaign.billingDateRequired'),
                          }}
                        />
                      )}
                    </div>
                    <div className="flex flex-row gap-1.5">
                      {isInfluentia() && control && (
                        <>
                          <div className="bg-white rounded-full size-7 p-1.5 flex mt-4 items-center justify-center">
                            <CalendarIcon className="size-[16px] text-influentia-black" />
                          </div>
                          <DatePickerInput
                            height="h-[37px]"
                            control={control}
                            type="blue"
                            name="paymentDate"
                            label={t('global.paymentDate')}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center size-full absolute left-0 top-0">
              <div className="flex flex-col size-full items-center justify-center gap-2">
                <div className="flex items-center justify-center">
                  <UploadIcon className="size-[35px] text-light-blue" />
                </div>
                <div className="flex flex-col items-center justify-center">
                  <div className="text-sm font-inter text-light-blue">
                    {text()}
                  </div>
                  <div
                    onClick={openFileDialog}
                    className="my-2 inline-flex cursor-pointer items-center justify-center rounded bg-white px-2 py-[2px]"
                  >
                    <span className="text-sm font-inter text-influentia-black">
                      {t('singleCampaign.selectFile')}
                    </span>
                  </div>
                </div>
              </div>
              <span className="text-xs font-inter text-light-blue mb-2">
                {t('singleCampaign.maxSize', { size: maxSize })}
              </span>
            </div>
          )}
        </div>
        <div
          className={`w-full flex flex-row gap-5 items-center justify-center mr-6 ${!file && 'hidden'}`}
        >
          <Button
            style="light-blue"
            text={t('global.post')}
            id="fileUploaderButton"
            loading={loading}
            type="submit"
            customClassnames="flex w-[25%] items-center justify-center mt-2"
          />
          <Button
            style="dark-blue"
            text={t('global.cancel')}
            loading={loading}
            customClassnames={`flex w-[25%] items-center !font-normal justify-center mt-2 ${file === null && 'hidden'}`}
            onClick={() => {
              if (file) {
                resetInput(true);
              }
            }}
          />
        </div>
      </form>
    );
  },
);

export default FileUploader;
