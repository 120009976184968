import React from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from '../../../assets';
import type { Referral } from '../../../types';
import { CopyButton } from '../../common/CopyButton';

type LinksProps = {
  links: Referral[];
};

export const CampaignLinksSidebar = ({ links }: LinksProps) => {
  const { t } = useTranslation('common');

  return (
    <>
      <div className="flex flex-row gap-[6px]">
        <div className="rounded-full bg-white flex justify-center items-center size-[28px] text-influentia-black">
          <Link />
        </div>
        <div className="flex flex-col gap-[5px] w-full">
          <span className="text-influentia-light-blue font-medium text-[10px]">
            {t('singleCampaign.referrerLinks')}
          </span>
          {links && links.length > 0 && (
            <div className="flex flex-col gap-1 w-full">
              {links.map((link, i) => (
                <div
                  key={link.id + i + Math.random()}
                  className="flex flex-row justify-between w-full gap-1 text-sm items-center"
                >
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                      link.referral.startsWith('https://')
                        ? link.referral
                        : `https://${link.referral}`
                    }
                    className="font-medium text-white max-w-[250px] truncate underline"
                  >
                    {link.referral}
                  </a>
                  <CopyButton text={link.referral} />
                </div>
              ))}
            </div>
          )}
          {!links ||
            (links && links.length === 0 && (
              <span className="text-[10px] text-white italic">
                {t('singleCampaign.noLinks')}
              </span>
            ))}
        </div>
      </div>
    </>
  );
};
