import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DefaultBrandIcon, DefaultInfluencerIcon } from '../../assets';
import type { CampaignStatusType } from '../../types';
import CampaignStatusDropdown from '../inputs/CampaignStatusDropdown';
import InfluencerStatusDropdown from '../inputs/InfluencerStatusDropdown';

type UserInfoCardProps = {
  image?: string;
  status: any;
  campaignStatus?: CampaignStatusType;
  name: string;
  date: string;
  influencerId: string;
  campaignId: string;
  isBrand?: boolean; // if is false, it is an influencer
  refetch?: () => void;
  refetchTabs?: () => void;
  influencerSidebar: boolean;
  campaignPaused?: boolean;
  influencerPaused?: boolean;
  showDate: boolean;
  recurrent?: boolean;
};

export const UserInfoCard = ({
  image,
  status,
  campaignStatus,
  name,
  date,
  isBrand,
  influencerId,
  campaignId,
  refetch,
  refetchTabs,
  influencerSidebar,
  showDate,
  recurrent,
  influencerPaused,
  campaignPaused,
}: UserInfoCardProps) => {
  const { t } = useTranslation('common');
  const [statusState, setStatus] = useState(status);
  const [campaignStatusState, setCampaignStatus] = useState<CampaignStatusType>(
    campaignStatus || 'pending',
  );
  useEffect(() => {
    setStatus(status);
  }, [status]);
  useEffect(() => {
    setCampaignStatus(campaignStatus || 'pending');
  }, [campaignStatus]);
  return (
    <div className="bg-influentia-black rounded-10 min-w-[380px] max-h-[126px] p-4">
      <div className="flex flex-row gap-5">
        <div className="size-[96px] flex flex-none">
          {image && (
            <img
              src={`${process.env.REACT_APP_AWS_PUBLIC_ENDPOINT}/${image}`}
              className={`rounded-full size-[inherit] object-cover`}
            />
          )}
          {!image && !isBrand && (
            <DefaultInfluencerIcon className="size-[inherit]" />
          )}
          {!image && isBrand && <DefaultBrandIcon className="size-[inherit]" />}
        </div>
        <div className="flex flex-col justify-center w-full">
          <span className="text-white font-bold">{name}</span>
          {showDate ? (
            <span
              className={`text-white text-sm mb-2 ${date ? 'font-medium' : ''}`}
            >
              {date || t('global.noPublishDate')}
            </span>
          ) : (
            <div className="h-[10px]" />
          )}
          {influencerSidebar ? (
            <>
              <InfluencerStatusDropdown
                defaultStatus={statusState}
                influencerPaused={influencerPaused}
                recurrent={recurrent}
                influencerId={influencerId}
                campaignId={campaignId}
                refetch={refetch}
                refetchTabs={refetchTabs}
              />
            </>
          ) : (
            <CampaignStatusDropdown
              recurrent={recurrent}
              defaultStatus={campaignStatusState}
              campaignPaused={campaignPaused}
              campaignId={campaignId}
              refetch={refetch}
              refetchTabs={refetchTabs}
            />
          )}
        </div>
      </div>
    </div>
  );
};
