import type { ColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { type TFunction } from 'i18next';

import type { BrandsTable } from '../../../types';
import { BrandImage } from '../../images/BrandImage';
import DTBoldCell from '../DTBoldCell';
import { DTCell } from '../DTCell';
import DTHeader from '../DTHeader';

type GetBrandsTableHeadersProps = {
  columnHelper: ColumnHelper<BrandsTable>;
  handleChangeSortDirection: (id: string) => void;
  t: TFunction;
  extras: {};
};

export const getBrandsTableHeaders = ({
  columnHelper,
  handleChangeSortDirection,
  t,
}: GetBrandsTableHeadersProps) => [
  // columnHelper.accessor('select', {
  //   size: 30,
  //   minSize: 30,
  //   maxSize: 30,
  //   header: ({ table }) => (
  //     <input
  //       checked={table.getIsAllPageRowsSelected()}
  //       onChange={table.getToggleAllPageRowsSelectedHandler()}
  //       type="checkbox"
  //       name="select"
  //       id="select"
  //       className="table-checkbox-th flex size-[18px] items-center justify-center rounded-md border border-white bg-green focus:ring-0 focus:ring-transparent focus:ring-offset-0"
  //     />
  //   ),
  //   cell: ({ row }) => (
  //     <input
  //       checked={row.getIsSelected()}
  //       disabled={!row.getCanSelect()}
  //       onChange={row.getToggleSelectedHandler()}
  //       type="checkbox"
  //       name="select"
  //       id="select"
  //       className="flex size-[18px] items-center justify-center rounded-md border border-green bg-transparent text-green checked:bg-green focus:ring-0 focus:ring-transparent focus:ring-offset-0"
  //     />
  //   ),
  //   enableSorting: false,
  //   enableHiding: false,
  // }),
  columnHelper.accessor('name', {
    size: 150,
    minSize: 150,
    maxSize: 200,
    cell: (info) => {
      return (
        <DTBoldCell
          content={info.getValue()}
          id={info.cell.id}
          image={
            <BrandImage
              size="size-[38px]"
              image={info.row.original.profile_picture}
            />
          }
        ></DTBoldCell>
      );
    },
    header: ({ header, ...info }) => {
      return (
        <DTHeader
          sortable
          first
          onClick={() => handleChangeSortDirection(header.id)}
          title={t('brands.header1')}
          sorting={(info as any).orderBy}
          sortingDirection={(info as any).orderDirection}
          accessor="name"
        />
      );
    },
  }),
  columnHelper.accessor('campaignCount', {
    size: 150,
    minSize: 150,
    maxSize: 200,
    header: ({ header }) => (
      <DTHeader
        onClick={() => handleChangeSortDirection(header.id)}
        title={t('brands.header2')}
        accessor="platforms"
      />
    ),
    cell: (info) => (
      <DTCell
        text={`${info?.getValue()?.toString() || 0} ${info?.getValue() !== 1 ? t('brands.columnCampaignsTextPlural') : t('brands.columnCampaignsTextSingular')}`}
      />
    ),
  }),
  columnHelper.accessor('createdAt', {
    size: 150,
    minSize: 150,
    maxSize: 200,
    header: ({ header, ...info }) => (
      <DTHeader
        sortable
        onClick={() => handleChangeSortDirection(header.id)}
        title={t('brands.header3')}
        sorting={(info as any).orderBy}
        sortingDirection={(info as any).orderDirection}
        accessor="createdAt"
        last
      />
    ),
    cell: (info) => (
      <DTCell
        text={
          info.getValue()
            ? dayjs(new Date(parseInt(info.getValue(), 10))).format(
                'DD-MM-YYYY',
              )
            : ''
        }
      />
    ),
  }),
];
