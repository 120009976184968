/* eslint-disable no-nested-ternary */
import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useOutletContext, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { CREATE_INVOICE } from '../../../graphql/invoices/mutation';
import {
  GET_ALL_INVOICES,
  USER_INVOICES,
} from '../../../graphql/invoices/query';
import { useNotification } from '../../../hooks/useNotification';
import { usePermissions } from '../../../hooks/usePermissions';
import { userAtom } from '../../../states/atoms';
import FileUploader from '../../common/FileUploader';
import { SingleInvoice } from '../invoices/SingleInvoice';

type SingleCampaignInvoicesTabProps = {
  brand?: boolean;
};

export const SingleCampaignInvoicesTab = ({
  brand,
}: SingleCampaignInvoicesTabProps) => {
  const { id } = useParams();
  const [campaignId, influencerId, brandId]: [string, string, string] =
    useOutletContext();
  const { t } = useTranslation('common');
  const isBrandMemo = useMemo(() => brandId === id, [brandId, id]);
  const [invoices, setInvoices] = useState<any>(null);
  const { isInfluentia, isInfluencer } = usePermissions();
  const user = useRecoilValue(userAtom);

  useEffect(() => {
    setInvoices([]);
  }, [id]);

  const { refetch } = useQuery(USER_INVOICES, {
    skip: id !== 'influencers',
    variables: {
      campaignId,
      // userId:
      //   // eslint-disable-next-line no-nested-ternary
      //   isInfluentia() && !isBrandMemo
      //     ? undefined
      //     : isInfluencer()
      //       ? user.id
      //       : undefined,
      // brandId: isBrandMemo ? id : undefined,
      // brand: isBrandMemo,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setInvoices(data.invoicesUser.data);
    },
  });
  const { refetch: refetchBrand } = useQuery(GET_ALL_INVOICES, {
    skip: id === 'influencers',
    variables: {
      campaignId,
      brandId: isBrandMemo ? id : undefined,
      brand: isBrandMemo,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setInvoices(data.invoices.data);
    },
  });
  const {
    control,
    watch,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      currency: '€',
      billingDate: undefined,
      Price: undefined,
      paymentDate: undefined,
    },
  });
  const fileUploaderRef = useRef<{
    resetInput: (reset: Boolean) => void;
  } | null>(null);

  const handleReset = () => {
    if (fileUploaderRef.current) {
      fileUploaderRef.current.resetInput(true);
    }
  };

  const [uploadInvoice, { loading }] = useMutation(CREATE_INVOICE);
  const { notifySaveSuccess, notifySaveError, notifyError } = useNotification();
  const handleFile = (file: File) => {
    // if (isInfluentia()) {
    //   if (!watch('billingDate')) {
    //     trigger('billingDate');
    //     return;
    //   }
    //   if (!watch('Price')) {
    //     trigger('Price');
    //     return;
    //   }
    // }

    uploadInvoice({
      variables: {
        file,
        campaignId,
        paymentDate: watch('paymentDate'),
        billingDate: watch('billingDate'),
        currency: watch('currency'),
        price: watch('Price'),
        userId:
          // eslint-disable-next-line no-nested-ternary
          (isInfluentia() || isInfluencer()) && !isBrandMemo
            ? influencerId
            : isInfluencer()
              ? user.id
              : undefined,
        brandId: isBrandMemo ? id : undefined,
      },
      context: {
        headers: {
          'content-type': 'multipart/form-data',
          'x-apollo-operation-name': 'CreateInvoice',
        },
      },
      onCompleted: () => {
        notifySaveSuccess();
        refetch();
        handleReset();
        refetchBrand();
        reset();
      },
      onError: () => {
        notifySaveError();
      },
    });
  };
  const { isBrand } = usePermissions();
  return (
    <div className="flex flex-col gap-5 h-full">
      <div className="flex flex-col gap-5 h-full">
        <div className="flex flex-col size-full gap-5">
          {invoices && invoices.length > 0 ? (
            isBrandMemo ? (
              <div
                className={`${
                  isInfluentia()
                    ? 'grid 2xl:grid-cols-2 xl:grid-cols-1 lg:grid-cols-1 md:grid-cols-1 grid-cols-1 sm:grid-cols-2'
                    : 'flex flex-col'
                } gap-2.5`}
              >
                {invoices.map((invoice: any) => (
                  <SingleInvoice
                    key={invoice.id}
                    invoice={invoice}
                    refetch={refetchBrand}
                  />
                ))}
              </div>
            ) : (
              invoices.map(
                (userInvoice: any) =>
                  userInvoice &&
                  userInvoice.user &&
                  userInvoice.invoices &&
                  userInvoice.invoices.length > 0 && (
                    <div
                      key={userInvoice.user.id}
                      className={`flex flex-col gap-2.5`}
                    >
                      <span
                        className={`text-white italic ${isInfluencer() && 'hidden'}`}
                      >
                        {userInvoice.user.name}
                      </span>
                      <div
                        className={`${
                          isInfluentia()
                            ? 'grid 2xl:grid-cols-2 xl:grid-cols-1 lg:grid-cols-1 md:grid-cols-1 grid-cols-1 sm:grid-cols-2'
                            : 'flex flex-col'
                        } gap-2.5`}
                      >
                        {userInvoice.invoices.map(
                          (invoice: any) =>
                            invoice &&
                            invoice.id && (
                              <SingleInvoice
                                key={invoice.id}
                                invoice={invoice}
                                refetch={refetch}
                              />
                            ),
                        )}
                      </div>
                    </div>
                  ),
              )
            )
          ) : (
            <>
              {(!invoices || (invoices && invoices.length === 0)) &&
                (isBrandMemo || isBrand()) && (
                  <span className="text-white italic">
                    {t('singleCampaign.noInvoicesBrand')}
                  </span>
                )}
              {(!invoices || (invoices && invoices.length === 0)) &&
                !isBrandMemo &&
                isInfluentia() && (
                  <span className="text-white italic">
                    {t('singleCampaign.noInvoices')}
                  </span>
                )}
            </>
          )}
          {((isInfluentia() && isBrandMemo) ||
            (isInfluencer() && !isBrandMemo)) && (
            <FileUploader
              fileType="Invoice"
              handleSubmit={handleSubmit}
              resetOnSubmit={true}
              ref={fileUploaderRef}
              submitFile={handleFile}
              loading={loading}
              control={control}
              currentCurrency={watch('currency')}
              errors={errors}
            />
          )}
        </div>

        <div>
          {isInfluencer() && (
            <div className="flex flex-col">
              <span className="uppercase text-white">
                {t('singleCampaign.facturationInfo')}
              </span>
              <span className="text-white">
                {t('singleCampaign.facturationElection')}
              </span>
              <div className="bg-dark-blue p-2.5 rounded-10 flex flex-col">
                <span className="text-[10px] text-influentia-light-blue font-medium">
                  {t('singleCampaign.companyName')}
                </span>
                <span className="text-white">
                  {t('singleCampaign.americanCompany')}
                </span>
                <span className="text-[10px] text-influentia-light-blue font-medium">
                  {t('singleCampaign.fiscalAddress')}
                </span>
                <span className="text-white">
                  {t('singleCampaign.americanCompanyAddress')}
                </span>
                <span className="text-[10px] text-influentia-light-blue font-medium">
                  {t('singleCampaign.tin')}
                </span>
                <span className="text-white">
                  {t('singleCampaign.americanCompanyTin')}
                </span>
              </div>
              <span className="text-white">
                {t('singleCampaign.facturationElection1')}
              </span>
              <div className="bg-dark-blue p-2.5 rounded-10 flex flex-col">
                <span className="text-[10px] text-influentia-light-blue font-medium">
                  {t('singleCampaign.companyName')}
                </span>
                <span className="text-white">
                  {t('singleCampaign.spanishCompany')}
                </span>
                <span className="text-[10px] text-influentia-light-blue font-medium">
                  {t('singleCampaign.fiscalAddress')}
                </span>
                <span className="text-white">
                  {t('singleCampaign.spanishCompanyAddress')}
                </span>
                <span className="text-[10px] text-influentia-light-blue font-medium">
                  {t('singleCampaign.cif')}
                </span>
                <span className="text-white">
                  {t('singleCampaign.spanishCompanyCif')}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
