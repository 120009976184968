import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

export const Toggle = forwardRef(function Toggle(
  {
    label,
    value,
    onChange,
    disabled = false,
    maxWidth = 'max-w-[170px]',
    topLabel = false,
    preText = '',
    backgroundColor = 'bg-dark-blue',
    minWidth = 'min-w-[170px]',
  }: {
    label?: string;
    value: boolean;
    onChange: Function;
    disabled?: boolean;
    maxWidth?: string;
    topLabel?: boolean;
    preText?: string;
    backgroundColor?: string;
    minWidth?: string;
  },
  ref,
) {
  const [checked, setChecked] = useState(value);
  const inputRef = useRef<HTMLInputElement>(null); // Create a ref for the input element

  useEffect(() => {
    setChecked(value);
  }, [value]);

  // Expose imperative methods
  useImperativeHandle(ref, () => ({
    toggle: (valueToggle: boolean) => {
      if (!disabled && inputRef.current) {
        inputRef.current.checked = valueToggle;
        setChecked(valueToggle);
        inputRef.current.dispatchEvent(new Event('change', { bubbles: true }));
      }
    },
  }));

  return (
    <div className={`flex ${topLabel && 'w-full'}`}>
      <div
        className={`${
          !topLabel ? 'items-center justify-center' : 'w-full'
        } flex flex-col ${minWidth}`}
      >
        {/* Top Label */}
        {label && topLabel && (
          <label htmlFor={label} className="my-2 block text-[14px] font-[600]">
            {label}
          </label>
        )}

        {/* Toggle Container */}
        <div
          className={`flex items-center ${maxWidth} ${topLabel && 'h-full'}`}
        >
          {/* Wrapper label for making the div clickable */}
          <label className="flex items-center w-full cursor-pointer">
            {/* Hidden checkbox input */}
            <input
              id={label}
              type="checkbox"
              checked={checked}
              onChange={() => {
                if (disabled) return;
                const newValue = !checked;
                setChecked(newValue);
                onChange(newValue);
              }}
              className="sr-only relative peer invisible"
              disabled={disabled}
              ref={inputRef} // Use the inputRef for the actual input element
            />
            {/* Optional Pre-text */}
            {preText && (
              <span className="w-full font-inter text-[14px] ml-2">
                {preText}
              </span>
            )}
            {/* Toggle switch */}
            <div
              className={`relative min-w-11 h-6 ${backgroundColor} rounded-full transition-all
                ${checked ? `peer-checked:${backgroundColor}` : ''}
                ${disabled ? '!bg-[#43525F]' : `peer-checked:${backgroundColor}`}`}
            >
              {/* Switch knob */}
              <div
                className={`absolute top-[2px] left-[2px] bg-influentia-light-blue/60 rounded-full size-5 transition-all
                  ${checked ? 'translate-x-full !bg-influentia-light-blue' : ''}
                  ${disabled ? 'bg-light-blue' : 'peer-checked:bg-influentia-light-blue'}`}
              ></div>
            </div>
          </label>

          {/* Bottom Label */}
          {!topLabel && (
            <span className="w-fit flex-none ms-3 text-sm font-inter text-gray-status break-words">
              {label}
            </span>
          )}
        </div>
      </div>
    </div>
  );
});
