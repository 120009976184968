import { createPortal } from 'react-dom';

export const ModalPortal = (props: any) => {
  /**
   * Issue #2: Cannot nest forms directly in DOM
   * https://html.spec.whatwg.org/multipage/forms.html#the-form-element
   * This is a basic html spec, the fix is using portals to unest Modals
   * https://reactjs.org/docs/portals.html
   */
  return createPortal(
    props.children,
    document.getElementById('layout') as Element,
  );
};
