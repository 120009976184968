import { useMutation, useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { CheckBoxReferralIcon } from '../../../assets';
import { RefetchLateral } from '../../../context/refetchLateral/refetchProvider';
import { UPDATE_INFLUENCER_SETTINGS } from '../../../graphql/campaign/mutation';
import { GET_INFLUENCER_SETTINGS } from '../../../graphql/campaign/query';
import { useNotification } from '../../../hooks/useNotification';
import { usePrompt } from '../../../hooks/usePrompt';
import { Button } from '../../common/Button';
import { MoneyInput } from '../../inputs/MoneyInput';
import ActionSettings from '../settings/influencer/ActionSettings';
import ReferralCode from '../settings/influencer/ReferralCode';

type InfluencerSettings = {
  status: string;
  total_amount: number;
  total_amountType: String;
  influencer_amountType: String;
  influencer_amount: number;
  referrals: {
    id: string;
    type: string;
    referral: string;
    platforms: any;
  }[];
  actions: {
    hasPreview: any;
    hasScript: any;
    id: string;
    type: string;
    platform: any;
    description: string;
    number_of_action: number;
  }[];
};

export const SingleCampaignSettingsInfluencerTab = () => {
  const [campaignId, influencerId]: [string, string] = useOutletContext();
  const { refetchLateral, refetchTabs } = useContext(RefetchLateral);
  const { t } = useTranslation('common');
  const [generalInfo, setGeneralInfo] = useState<InfluencerSettings>();
  const [referrals, setReferrals] = useState<InfluencerSettings['referrals']>(
    generalInfo?.referrals || [],
  );
  const [referralKey, setReferralKey] = useState(0); // Key for forcing rerender
  const [referralKey1, setReferralKey1] = useState(0); // Key for forcing rerender
  const [dirty, setDirty] = useState(false);
  const [actions, setActions] = useState<InfluencerSettings['actions']>(
    generalInfo?.actions || [],
  );
  const {
    control,
    setValue,
    reset,
    watch,
    formState: { dirtyFields },
  } = useForm({
    defaultValues: {
      total_value: generalInfo?.total_amount,
      influencer_value: generalInfo?.influencer_amount,
      total_amountType: generalInfo?.total_amountType,
      influencer_amountType: generalInfo?.influencer_amountType,
    },
  });
  const { refetch } = useQuery(GET_INFLUENCER_SETTINGS, {
    variables: {
      influencerId,
      campaignId,
    },
    fetchPolicy: 'network-only', // Used for first execution
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const info = data.influencerCampaignSettings.data;
      setDirty(false);
      reset();
      setGeneralInfo(info);
      setValue('total_value', info.total_amount, { shouldDirty: false });
      setValue('influencer_value', info.influencer_amount, {
        shouldDirty: false,
      });
      setValue('total_amountType', info.total_amountType, {
        shouldDirty: false,
      });
      setValue('influencer_amountType', info.influencer_amountType, {
        shouldDirty: false,
      });
    },
  });
  const [updateInfluencerSettings, { loading }] = useMutation(
    UPDATE_INFLUENCER_SETTINGS,
  );
  const { notifySaveSuccess, notifySaveError } = useNotification();

  const handleNewReferral = (type: String) => {
    setReferrals((prevReferrals: any) => {
      return [
        ...prevReferrals,
        {
          id: Math.floor(Math.random() * 1000).toString(),
          type,
          referral: '',
          platforms: '',
        },
      ];
    });
    setDirty(true);
  };
  const handleNewAction = () => {
    setDirty(true);
    setActions((prevActions: any) => {
      return [
        ...prevActions,
        {
          id: Math.floor(Math.random() * 1000).toString(),
          type: '',
          platform: 'Youtube',
          description: '',
          number_of_action: 1,
          hasScript: true,
          hasPreview: true,
          disabled: false,
        },
      ];
    });
  };
  const { notifyError } = useNotification();

  const handleSave = async (status?: string) => {
    if (status) {
      if (Number(watch('influencer_value')) === 0) {
        notifyError(t('singleCampaign.influencerPriceRequired'));
        return;
      }
      if (actions.length === 0) {
        notifyError(t('singleCampaign.actionsRequired'));
        return;
      }
    }
    updateInfluencerSettings({
      variables: {
        influencerId,
        status,
        campaignId,
        influencerAmount: Number(watch('influencer_value')),
        influencerAmountType: watch('influencer_amountType'),
        totalAmount: Number(watch('total_value')),
        referrals,
        actions: actions.map((action) => ({
          id: action.id,
          type: action.type,
          platform: action.platform,
          description: action.description,
          hasScript: action.hasScript,
          hasPreview: action.hasPreview,
          number_of_action: action.number_of_action,
        })),
      },
    })
      .then(() => {
        notifySaveSuccess();
        refetch();
        // window.location.reload();
        reset();
        setDirty(false);
        refetchLateral();
        refetchTabs();
      })
      .catch(() => {
        notifySaveError();
      });
  };
  useEffect(() => {
    // refetch();
    setReferrals(generalInfo?.referrals || []);
    setActions(
      generalInfo?.actions.map((action) => ({
        id: action.id,
        type: action.type,
        platform: action.platform,
        description: action.description,
        number_of_action: action.number_of_action,
        hasScript: action.hasScript,
        hasPreview: action.hasPreview,
        disabled: true,
      })) || [],
    );
  }, [generalInfo]);

  const deleteReferral = (id: string) => {
    setReferrals((prevReferrals: any) => {
      return prevReferrals.filter((referral: any) => referral.id !== id);
    });
    setReferralKey((prevKey) => prevKey + 1); // Change the key to force rerender
    setDirty(true);
  };
  const deleteAction = (id: string) => {
    setActions((prevActions: any) => {
      return prevActions.filter((action: any) => action.id !== id);
    });
    setReferralKey1((prevKey) => prevKey + 1); // Change the key to force rerender
    setDirty(true);
  };
  usePrompt({
    isDirty: Object.keys(dirtyFields).length > 0 || dirty,
  });

  return (
    <>
      {generalInfo && (
        <div className="pb-20">
          <div className="flex lg:flex-row flex-col w-full gap-5">
            <div className="w-full">
              <MoneyInput
                name="total_value"
                label={t('singleCampaign.totalPrice')}
                control={control}
                currentCurrency={watch('total_amountType')}
                tooltipText={t('singleCampaign.totalPriceTooltip')}
              />
            </div>
            <div className="w-full">
              <MoneyInput
                currencySelect={true}
                name="influencer_value"
                currentCurrency={watch('influencer_amountType')}
                registerName="influencer_amountType"
                label={t('singleCampaign.influencerPrice')}
                control={control}
              />
            </div>
          </div>
          <div className="flex lg:flex-row flex-col mt-5 gap-8">
            <div className="w-full flex flex-col">
              <span>{t('singleCampaign.referrerCode')}</span>
              <div key={referralKey} className="flex flex-row w-full gap-8">
                <div className="w-full flex flex-col gap-1">
                  {referrals.map((referral: any) => {
                    if (referral.type === 'Code') {
                      return (
                        <ReferralCode
                          key={referral.id}
                          referral={referral}
                          setDirty={setDirty}
                          setReferrals={setReferrals}
                          deleteReferral={() => deleteReferral(referral.id)}
                        />
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
              <button
                className="w-full flex items-center justify-center"
                onClick={() => handleNewReferral('Code')}
              >
                <div className="flex flex-row items-center justify-center mt-1 gap-1 border-b border-influentia-light-blue w-fit">
                  <CheckBoxReferralIcon />
                  <span className="text-[10px] text-influentia-light-blue font-medium">
                    {t('singleCampaign.addCode')}
                  </span>
                </div>
              </button>
            </div>
            <div className="w-full">
              <span>{t('singleCampaign.referrerLink')}</span>
              <div key={referralKey + 1} className="flex flex-row w-full gap-8">
                <div className="w-full flex flex-col gap-1">
                  {referrals.map((referral: any) => {
                    if (referral.type === 'Link') {
                      return (
                        <ReferralCode
                          key={referral.id}
                          setDirty={setDirty}
                          referral={referral}
                          setReferrals={setReferrals}
                          deleteReferral={() => deleteReferral(referral.id)}
                        />
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
              <div className="w-full flex items-center justify-center">
                <button onClick={() => handleNewReferral('Link')}>
                  <div className="flex flex-row items-center justify-center mt-1 gap-1 border-b border-influentia-light-blue w-fit">
                    <CheckBoxReferralIcon />
                    <span className="text-[10px] text-influentia-light-blue font-medium">
                      {t('singleCampaign.addLink')}
                    </span>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div className="mt-5">
            <span>{t('global.actions')}</span>
            <div key={referralKey1} className="flex flex-col gap-2">
              {actions.map((action) => (
                <ActionSettings
                  key={action.id}
                  setDirty={setDirty}
                  action={action}
                  dirty={dirty}
                  setActions={setActions}
                  deleteAction={() => deleteAction(action.id)}
                />
              ))}
            </div>
            <div className="w-full flex items-center justify-center">
              <button id="addAction" onClick={() => handleNewAction()}>
                <div className="flex flex-row items-center justify-center mt-1 gap-1 border-b border-influentia-light-blue w-fit">
                  <CheckBoxReferralIcon />
                  <span className="text-[10px] text-influentia-light-blue font-medium">
                    {t('singleCampaign.addAction')}
                  </span>
                </div>
              </button>
            </div>
          </div>
          <div className="mt-5 lg:items-end flex lg:justify-end justify-center w-full flex-row gap-3">
            <Button
              id="saveInfluencerSettings"
              style="light-blue"
              onClick={() => handleSave()}
              loading={loading}
              text={t('singleCampaign.updateInfo')}
            />
            {generalInfo.status === 'not_send' && (
              <Button
                style="light-blue"
                onClick={() => handleSave('accepted_pending')}
                loading={loading}
                text={t('singleCampaign.sendProposal')}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};
