import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { UsersIcon } from '../../assets';
import { CREATE_USER } from '../../graphql/user/mutation';
import { usePermissions } from '../../hooks/usePermissions';
import type { ResCreateUserType } from '../../types';
import { Button } from '../common/Button';
import { TextInput } from '../inputs/TextInput';
import { Toggle } from '../inputs/Toggle';
import { PopupLayout } from './PopupLayout';

type TCreateInfluentiaUser = {
  name: string;
  email: string;
  hasAccess: boolean;
};

export const CreateBrandUser = ({
  open,
  setOpen,
  brandId,
  refetch,
}: {
  open: any;
  setOpen: any;
  brandId: string;
  refetch: () => void;
}) => {
  const { t } = useTranslation('common');
  const [notCreated, setNotCreated] = useState('');

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm<TCreateInfluentiaUser>({
    defaultValues: {
      name: '',
      email: '',
      hasAccess: true,
    },
  });

  const [createUser, { loading }] = useMutation<ResCreateUserType>(CREATE_USER);

  const onSubmit = (data: TCreateInfluentiaUser) => {
    createUser({
      variables: {
        name: data.name,
        email: data.email,
        role: 'Brand',
        hasAccess: data.hasAccess,
        brandId,
      },
      onCompleted: (res) => {
        reset();
        setOpen(false);
        refetch();
      },
      onError: (error) => {
        setNotCreated(error.message);
      },
    });
  };

  const closeModal = () => {
    reset();
    setOpen(false);
  };
  useEffect(() => {
    if (notCreated === '') return;
    setTimeout(() => {
      setNotCreated('');
    }, 5000);
  }, [notCreated]);
  const { isInfluentia } = usePermissions();
  return (
    <>
      {open && (
        <PopupLayout
          title={t('brandUsers.createUserTitle')}
          closeModal={closeModal}
          icon={<UsersIcon />}
        >
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-y-5 mt-5"
          >
            <div className="flex flex-row gap-x-8 w-full">
              <TextInput
                register={register}
                registerName="name"
                type="text"
                label={t('brandUsers.createName')}
                placeholder={t('brandUsers.createNamePlaceholder')}
                validation={{ required: t('influentiaUsers.nameRequired') }}
                errors={errors.name}
              />
              <TextInput
                register={register}
                registerName="email"
                type="text"
                label={t('brandUsers.createEmail')}
                placeholder={t('brandUsers.createEmailPlaceholder')}
                validation={{
                  required: t('influentiaUsers.emailRequired'),
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: t('influentiaUsers.emailInvalid'),
                  },
                }}
                errors={errors.email}
              />
              {isInfluentia() && (
                <Toggle
                  topLabel
                  maxWidth="max-w-[300px]"
                  label={t('influencers.hasAccess')}
                  preText={t('influencers.invitateToPlatform')}
                  value={watch('hasAccess')}
                  onChange={() => {
                    setValue('hasAccess', !watch('hasAccess'));
                  }}
                />
              )}
            </div>
            {notCreated && notCreated !== '' && (
              <span className="text-red-status">{notCreated}</span>
            )}
            <div className="flex justify-end">
              <div className="w-[150px]">
                <Button
                  onClick={() => {}}
                  id="saveTeamMember"
                  style="light-blue"
                  text={t('global.save')}
                  customClassnames="w-full justify-center"
                  type="submit"
                  loading={loading}
                />
              </div>
            </div>
          </form>
        </PopupLayout>
      )}
    </>
  );
};
