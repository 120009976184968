import 'dayjs/locale/es';

import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { BrandsIcon } from '../assets';
import { Breadcrumbs } from '../components/common/Breadcrumbs';
import InvoicesTableTable from '../components/invoices/InvoicesTablePartial';
import { usePermissions } from '../hooks/usePermissions';

dayjs.extend(localizedFormat);
dayjs.locale('es');

function Invoices() {
  const { t } = useTranslation('common');
  const { isInfluentia } = usePermissions();
  return (
    <div className="w-full flex flex-col gap-y-5">
      <Breadcrumbs
        icon={<BrandsIcon />}
        breadcrumbs={[
          {
            text: t('invoices.title'),
          },
        ]}
      />
      <InvoicesTableTable brand />
      {isInfluentia() && <InvoicesTableTable brand={false} />}
    </div>
  );
}

export default Invoices;
