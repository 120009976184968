import React from 'react';
import { useTranslation } from 'react-i18next';

import { MobileUser } from '../../../assets';
import type { PlatformType } from '../../../types';
import { SocialNetworkImage } from '../../images/SocialNetworkImage';

type PlatformsUrlProps = {
  platforms: {
    name: string;
    url: string;
  }[];
};

export const PlatformsUrl = ({ platforms }: PlatformsUrlProps) => {
  const { t } = useTranslation('common');

  return (
    <div className="flex flex-row gap-[6px]">
      <div className="rounded-full bg-white flex justify-center items-center size-[28px] text-influentia-black">
        <MobileUser />
      </div>
      <div className="flex flex-col gap-[5px]">
        <span className="text-influentia-light-blue font-medium text-[10px]">
          {t('singleCampaign.influencerPlatforms')}
        </span>
        {platforms && platforms.length > 0 && (
          <div className="flex flex-col gap-1 ">
            {platforms.map((p) => (
              <div className="flex flex-row gap-1 text-sm items-center">
                <SocialNetworkImage socialNetwork={p.name as PlatformType} />
                <a
                  href={p.url}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="font-medium text-white truncate max-w-[250px] underline"
                >
                  {p.url}
                </a>
              </div>
            ))}
          </div>
        )}
        {!platforms ||
          (platforms && platforms.length === 0 && (
            <span className="text-[10px] text-white italic">
              {t('singleCampaign.noInfluencerPlatforms')}
            </span>
          ))}
      </div>
    </div>
  );
};
