import React from 'react';
import { useTranslation } from 'react-i18next';

import { MobileUser } from '../../../assets';
import type { PlatformType } from '../../../types';
import { SocialNetworkImage } from '../../images/SocialNetworkImage';

type HiredPlatformsProps = {
  platforms: string[];
};

export const HiredPlatforms = ({ platforms }: HiredPlatformsProps) => {
  const { t } = useTranslation('common');
  return (
    <div className="flex flex-row gap-[6px] items-center">
      <div className="rounded-full bg-white flex justify-center items-center size-[28px] text-influentia-black">
        <MobileUser />
      </div>
      <div className="flex flex-col gap-[5px]">
        <span className="text-influentia-light-blue font-medium text-[10px]">
          {t('singleCampaign.hiredPlatforms')}
        </span>
        {platforms && platforms.length > 0 && (
          <div className="flex flex-row">
            {platforms &&
              platforms.map((p) => (
                <SocialNetworkImage key={p} socialNetwork={p as PlatformType} />
              ))}
          </div>
        )}
        {!platforms ||
          (platforms && platforms.length === 0 && (
            <span className="text-[10px] text-white italic">
              {t('singleCampaign.noHiredPlatforms')}
            </span>
          ))}
      </div>
    </div>
  );
};
