import { gql } from '@apollo/client';

export const CREATE_USER = gql`
  mutation CreateUser(
    $name: String!
    $role: String!
    $email: String!
    $brandId: String
    $hasAccess: Boolean
  ) {
    createUser(
      name: $name
      role: $role
      email: $email
      brandId: $brandId
      hasAccess: $hasAccess
    ) {
      status
      code
      data
      error
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation UpdateUser(
    $userId: String!
    $email: String
    $email_enabled: Boolean
    $name: String
    $phone: String
    $time_zone: String
    $time_zone_automatic: Boolean
    $hasAccess: Boolean
    $language: String
    $language_automatic: Boolean
  ) {
    updateUser(
      userId: $userId
      email: $email
      name: $name
      phone: $phone
      email_enabled: $email_enabled
      time_zone: $time_zone
      time_zone_automatic: $time_zone_automatic
      hasAccess: $hasAccess
      language: $language
      language_automatic: $language_automatic
    ) {
      status
      code
      data
      error
    }
  }
`;

export const USER_SET_SOCIAL_NETWORK = gql`
  mutation SetSocialNetwork($userId: String!, $networks: [newNetwork]!) {
    setSocialNetwork(userId: $userId, networks: $networks) {
      status
      code
      data
      error
    }
  }
`;

export const UPDATE_PROFILE_PICTURE = gql`
  mutation UpdateUserProfilePicture($userId: String!, $file: Upload!) {
    updateUserProfilePicture(userId: $userId, file: $file) {
      status
      code
      data
      error
    }
  }
`;

export const UPDATE_PROFILE_NOTIFICATION = gql`
  mutation UpdateProfileNotification(
    $type: String!
    $key: String!
    $value: Boolean!
  ) {
    updateProfileNotification(type: $type, key: $key, value: $value) {
      status
      code
      data
      error
    }
  }
`;
