import 'dayjs/locale/es';

import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { BrandsIcon } from '../assets';
import { Breadcrumbs } from '../components/common/Breadcrumbs';
import { ActiveFilters } from '../components/filters/ActiveFilters';
import { BrandFiltersPopover } from '../components/filters/BrandFilters';
import { TableSearchInput } from '../components/inputs/TableSearchInput';
import { CreateBrand } from '../components/popups/CreateBrand';
import DataTable from '../components/tables/DataTable';
import { getBrandsTableHeaders } from '../components/tables/headers/brands';
import { replaceIds, ROUTES } from '../config/routes/config';
import { GET_BRANDS } from '../graphql/brands/query';
import { useTableDataAndFilters } from '../hooks/useTableDataAndFilters';
import { brandsFilterAtom } from '../states/atoms';
import type {
  BrandsFilters,
  BrandsTable,
  ResponseDataBrandList,
} from '../types';

dayjs.extend(localizedFormat);
dayjs.locale('es');

function Brands() {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  /**
   * Hook in charge of the logic of the data, filtering and pagination
   */
  const {
    resetPagination,
    total,
    filters,
    setFilters,
    table,
    error,
    loading,
    refetch,
  } = useTableDataAndFilters<
    BrandsFilters,
    BrandsTable,
    {},
    ResponseDataBrandList
  >({
    filterAtom: brandsFilterAtom,
    getColumns: getBrandsTableHeaders,
    columnsExtras: {},
    responseAccessKey: 'brands',
    query: GET_BRANDS,
  });

  /**
   * Handles click on any row of the table and navigates to single brand
   * @param id id of the row clicked
   */
  const handleTableClick = (id: string) => {
    navigate(
      replaceIds({
        url: ROUTES.SINGLE_BRAND,
        params: [{ id }],
      }),
    );
  };

  return (
    <div className="w-full flex flex-col gap-y-5">
      <Breadcrumbs
        icon={<BrandsIcon />}
        breadcrumbs={[
          {
            text: t('brands.title'),
          },
        ]}
      />
      <div className="flex flex-col gap-y-4">
        <div className="flex flex-row justify-between">
          <div>
            <TableSearchInput
              placeholder={t('brands.searchInputPlaceholder')}
              filters={{ ...filters }}
              setFilters={(val) => {
                setFilters(val);
                resetPagination();
              }}
              searchKey="name"
            />
          </div>
          <div className="flex flex-row gap-x-2">
            <BrandFiltersPopover
              setFilters={(val) => {
                setFilters(val);
                resetPagination();
              }}
              filters={filters}
            />
            <CreateBrand refetch={refetch} />
          </div>
        </div>
        <ActiveFilters filters={filters} setFilters={setFilters} />
        <DataTable
          error={error}
          handleRowClick={(id) => handleTableClick(id)}
          loading={loading}
          table={table}
          total={total}
          tableId="BrandsTable"
          sorting={{ ...filters }}
        />
      </div>
    </div>
  );
}

export default Brands;
