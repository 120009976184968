import { ErrorFeedback } from '../../assets';

export const Error = ({ error }: { error: string }) => {
  return (
    <div className="bg-[#FFA5A5] w-full flex items-center rounded-[5px] border-DEFAULT border-red-status text-[#7F0F06] font-inter text-sm pr-1">
      <div>
        <ErrorFeedback className="mx-1" />
      </div>
      {error}
    </div>
  );
};
