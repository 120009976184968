import 'dayjs/locale/es';

import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CampaignRecurrent } from '../assets';
import { SubrowTableRecurrent } from '../components/campaigns/SubrowsTableRecurrent';
import { Breadcrumbs } from '../components/common/Breadcrumbs';
import { ActiveFilters } from '../components/filters/ActiveFilters';
import { CampaignFiltersPopover } from '../components/filters/CampaignFilters';
import { TableSearchInput } from '../components/inputs/TableSearchInput';
import { CreateCampaignRecurrent } from '../components/popups/CreateCampaignRecurrent';
import DataTable from '../components/tables/DataTable';
import type { ExtraInfoCampaignHeader } from '../components/tables/headers/campaigns';
import { getCampaignsRecurrentTableHeaders } from '../components/tables/headers/campaignsRecurrent';
import { replaceIds, ROUTES } from '../config/routes/config';
import { GET_CAMPAIGNS_RECURRENT_LIST } from '../graphql/campaign/query';
import { usePermissions } from '../hooks/usePermissions';
import { useTableDataAndFilters } from '../hooks/useTableDataAndFilters';
import { campaignFilterAtom } from '../states/atoms';
import type {
  CampaignFilters,
  CampaignTable,
  ResCampaignsList,
} from '../types';

dayjs.extend(localizedFormat);
dayjs.locale('es');

function CampaignsRecurrent() {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const { isInfluencer } = usePermissions();

  /**
   * Hook in charge of the logic of the data, filtering and pagination
   */
  const {
    data: campaigns,
    resetPagination,
    total,
    error,
    loading,
    filters,
    setFilters,
    table,
    refetch,
  } = useTableDataAndFilters<
    CampaignFilters,
    CampaignTable,
    ExtraInfoCampaignHeader,
    ResCampaignsList
  >({
    filterAtom: campaignFilterAtom,
    getColumns: getCampaignsRecurrentTableHeaders,
    columnsExtras: { isInfluencer: isInfluencer() },
    responseAccessKey: 'campaignsRecurrent',
    query: GET_CAMPAIGNS_RECURRENT_LIST,
    subrowAccesor: 'users',
  });

  /**
   * Handles click on any row of the table and navigates to single campaign
   * @param id id of the row clicked
   */
  const handleTableClick = (id: string, influencer_id?: string) => {
    const campaign = campaigns.find((c) => c.id === id);
    if (campaign)
      navigate(
        replaceIds({
          url: ROUTES.SINGLE_CAMPAIGN_RECURRENT_SETTINGS_INFLUENCER,
          params: [
            {
              campaignId: campaign.id,
            },
            {
              influencerId:
                influencer_id ||
                (campaign.users[0] ? campaign.users[0].user_id : '') ||
                '',
            },
          ],
        }),
      );
  };

  return (
    <div className="w-full flex flex-col gap-y-5">
      <Breadcrumbs
        icon={<CampaignRecurrent />}
        breadcrumbs={[
          {
            text: t('campaignsRecurrent.title'),
          },
        ]}
      />
      <div className="flex flex-col gap-y-4">
        <div className="flex flex-row justify-between">
          <div>
            <TableSearchInput
              placeholder={t('campaigns.searchInputPlaceholder')}
              filters={{ ...filters }}
              setFilters={(val) => {
                setFilters(val);
                resetPagination();
              }}
              searchKey="campaignName"
            />
          </div>
          <div className="flex flex-row gap-x-2">
            <CampaignFiltersPopover
              setFilters={(val) => {
                setFilters(val);
                resetPagination();
              }}
              recurrent
              filters={filters}
            />
            <CreateCampaignRecurrent refetch={refetch} />
          </div>
        </div>
        <ActiveFilters
          type="campaign"
          filters={filters}
          setFilters={setFilters}
        />
        <DataTable
          error={error}
          handleRowClick={(id) => handleTableClick(id)}
          handleSubrowClick={(id, influencer_id) =>
            handleTableClick(id, influencer_id)
          }
          loading={loading}
          table={table}
          total={total}
          tableId="CampaignsRecurrentTable"
          sorting={{ ...filters }}
          SubrowsComponent={SubrowTableRecurrent}
        />
      </div>
    </div>
  );
}

export default CampaignsRecurrent;
