import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TrashIcon } from '../../../../assets';
import { ACTION_TYPES } from '../../../../config/constants';
import { Confirm } from '../../../../context/confirm/confirm';
import type { PlatformType } from '../../../../types';
import { GenericDropdown } from '../../../inputs/GenericDropdown';
import PlatformDropdown from '../../../inputs/PlatformDropdown';
import { TextInput } from '../../../inputs/TextInput';
import { Toggle } from '../../../inputs/Toggle';

export default function ActionSettings({
  action,
  setActions,
  setDirty,
  dirty,
  deleteAction,
}: {
  action: any;
  setActions: any;
  setDirty: any;
  dirty: boolean;
  deleteAction: any;
}) {
  const {
    control,
    setValue,
    watch,
    formState: { dirtyFields },
  } = useForm({
    defaultValues: {
      actionType: action.type,
      hasScript: action.hasScript,
      hasPreview: action.hasPreview,
      description: action.description,
      numberAction: action.number_of_action,
    },
  });
  const { t } = useTranslation('common');
  const [platform, setPlatform] = useState<PlatformType>(action.platform);

  const { hasPreview, hasScript, description, numberAction } = watch();
  const handleUpdate = () => {
    setActions((prevActions: any) => {
      const updatedActions = prevActions.map((a: any) => {
        if (a.id === action.id) {
          return {
            ...a,
            type: watch('actionType'),
            platform,
            description: watch('description'),
            status: a.status,
            number_of_action: numberAction,
            hasScript: watch('hasScript'),
            hasPreview: watch('hasPreview'),
          };
        }
        return a;
      });
      return updatedActions;
    });
  };

  useEffect(() => {
    setDirty(Object.keys(dirtyFields).length > 0 || dirty);
  }, [dirtyFields]);

  // Watch changes in platform, description, and numberAction states
  useEffect(() => {
    handleUpdate();
    if (platform && !watch('actionType')) {
      setValue('actionType', ACTION_TYPES[platform][0]);
    }
  }, [
    platform,
    description,
    numberAction,
    watch('actionType'),
    watch('hasScript'),
    watch('hasPreview'),
  ]);
  const [open, setOpen] = useState(false);
  return (
    <div className="flex flex-row gap-5">
      <Confirm
        open={open}
        onConfirm={() => deleteAction()}
        setOpen={setOpen}
        type="delete"
        title={t('global.deleteTitle')}
        subtitle={t('global.deleteSubtitle')}
        confirmText={t('global.delete')}
        cancelText={t('global.cancel')}
      />

      <div className="flex flex-col size-full gap-1.5">
        <div className="flex lg:flex-row flex-col gap-1.5">
          <PlatformDropdown
            disabled={action.disabled}
            platformSelected={platform}
            onChange={(selectedPlatform) => {
              setPlatform(selectedPlatform as PlatformType);
              setValue(
                'actionType',
                ACTION_TYPES[selectedPlatform as PlatformType][0],
              );
              setDirty(true);
              if (
                ['Blog', 'Substack', 'Twitter', 'Telegram'].includes(
                  selectedPlatform,
                )
              ) {
                setValue('hasPreview', false);
              } else {
                setValue('hasPreview', true);
              }
            }}
          />
          <TextInput
            disabled={action.disabled}
            type="number"
            placeholder={t('singleCampaign.numberOfPieces')}
            selectedValue={numberAction}
            onSelectChange={(value) => {
              setValue('numberAction', Number(value));
              setDirty(true);
            }}
          />
          <GenericDropdown
            options={ACTION_TYPES[platform].map((type: string) => ({
              value: type,
              label: t(`actionType.${type}`),
            }))}
            height="h-[41px]"
            control={control}
            name="actionType"
          />
          <Toggle
            label={t('singleCampaign.validateScript')}
            disabled={action.disabled}
            value={hasScript}
            onChange={() => {
              setValue('hasScript', !hasScript);
              setDirty(true);
            }}
          />
          <Toggle
            disabled={action.disabled}
            label={t('singleCampaign.validatePreview')}
            value={hasPreview}
            onChange={() => {
              setValue('hasPreview', !hasPreview);
              setDirty(true);
            }}
          />
        </div>

        <TextInput
          type="text"
          placeholder={t('singleCampaign.descriptionActionPlaceholder')}
          maxLength={500}
          selectedValue={description}
          onSelectChange={(value) => {
            setValue('description', value);
            setDirty(true);
          }}
        />
      </div>
      <div
        className={`flex lg:items-center lg:justify-center lg:mt-0 mt-4 cursor-pointer`}
        onClick={() => setOpen(true)}
      >
        <TrashIcon className="size-[15px] text-red-status" />
      </div>
    </div>
  );
}
