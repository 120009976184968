import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CampaignIcon } from '../../assets';
import { replaceIds, ROUTES } from '../../config/routes/config';
import { CREATE_CAMPAIGN_RECURRENT } from '../../graphql/campaign/mutation';
import { usePermissions } from '../../hooks/usePermissions';
import type { ResCreateCampaignRecurrentType } from '../../types';
import { Button } from '../common/Button';
import { TextInput } from '../inputs/TextInput';
import { UserDropdown } from '../inputs/UserDropdown';
import { UserDropdownList } from '../inputs/UserDropdownList';
import { PopupLayout } from './PopupLayout';

type TCreateCampaignForm = {
  name: string;
  client: string;
  influencers: Array<{ value: string; id: string }>;
};

export const CreateCampaignRecurrent = ({ refetch }: { refetch: any }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);

  const { ...methods } = useForm<TCreateCampaignForm>();
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const [createCampaignRecurrent, { loading }] =
    useMutation<ResCreateCampaignRecurrentType>(CREATE_CAMPAIGN_RECURRENT);
  const { isInfluentia } = usePermissions();
  const onSubmit = (data: TCreateCampaignForm) => {
    createCampaignRecurrent({
      variables: {
        name: data.name,
        brand_id: data.client,
        influencers: data.influencers.map((i) => i.value) || [],
      },
      onCompleted: (res) => {
        navigate(
          replaceIds({
            url: ROUTES.SINGLE_CAMPAIGN_RECURRENT_SETTINGS_INFLUENCER,
            params: [
              {
                influencerId: data.influencers[0].value || '',
              },
              { campaignId: res.createCampaignRecurrent.data || '' },
            ],
          }),
        );
        reset();
        setOpen(false);
        refetch();
      },
      onError: () => {},
    });
  };

  const closeModal = () => {
    reset();
    setOpen(false);
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        style="light-blue"
        text={t('campaigns.createCampaign')}
        iconLeft={<CampaignIcon />}
        customClassnames={`${!isInfluentia() && 'hidden'}`}
      />
      {open && (
        <PopupLayout
          title={t('campaigns.createCampaignTitle')}
          closeModal={closeModal}
          icon={<CampaignIcon />}
        >
          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-col gap-y-5 mt-5"
            >
              <div className="flex flex-row gap-x-8 w-full">
                <TextInput
                  register={register}
                  registerName="name"
                  type="text"
                  label={t('campaigns.createName')}
                  placeholder={t('campaigns.createNamePlaceholder')}
                  validation={{ required: t('campaigns.nameRequired') }}
                  errors={errors.name}
                />
                <div className="w-full">
                  <UserDropdown
                    control={control}
                    name="client"
                    label={t('campaigns.createClient')}
                    placeholder={t('campaigns.createClientPlaceholder')}
                    validation={{ required: t('campaigns.clientRequired') }}
                    type="brand"
                    error={errors.client?.message}
                  />
                </div>
              </div>
              <UserDropdownList
                type="influencer"
                accessName="influencers"
                label={t('campaigns.createInfluencers')}
                accessKey="value"
                addText={t('campaigns.createInfluencersAdd')}
                placeholderSelects={t('campaigns.createInfluencersPlaceholder')}
                control={control}
                error={
                  errors.influencers?.root
                    ? t('campaigns.addInfluencerError')
                    : ''
                }
              />
              <div className="flex justify-end">
                <div className="w-[150px]">
                  <Button
                    onClick={() => {}}
                    style="light-blue"
                    text={t('global.save')}
                    customClassnames="w-full justify-center"
                    type="submit"
                    loading={loading}
                  />
                </div>
              </div>
            </form>
          </FormProvider>
        </PopupLayout>
      )}
    </>
  );
};
