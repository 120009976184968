import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { InfluencersIcon } from '../assets';
import { Tab } from '../components/campaigns/SingleCampaignTabs';
import { SubrowTable } from '../components/campaigns/SubrowsTable';
import { Breadcrumbs } from '../components/common/Breadcrumbs';
import { LateralProfileMenu } from '../components/common/LateralProfileMenu';
import DataTable from '../components/tables/DataTable';
import type { ExtraInfoCampaignHeader } from '../components/tables/headers/campaigns';
import { getCampaignsTableHeaders } from '../components/tables/headers/campaigns';
import { replaceIds, ROUTES } from '../config/routes/config';
import { GET_CAMPAIGNS_LIST } from '../graphql/campaign/query';
import { GET_SINGLE_USER } from '../graphql/user/query';
import { useNotification } from '../hooks/useNotification';
import { usePermissions } from '../hooks/usePermissions';
import { useTableDataAndFilters } from '../hooks/useTableDataAndFilters';
import { campaignFilterUserAtom, userAtom } from '../states/atoms';
import type {
  CampaignFilters,
  CampaignTable,
  ResCampaignsList,
  ResponseSingleUser,
} from '../types';

export const SingleInfluencerCampaigns = () => {
  const { id } = useParams();
  const { t } = useTranslation('common');
  const myUser = useRecoilValue(userAtom);

  const navigate = useNavigate();
  const { notifyWarning } = useNotification();
  const location = useLocation();

  const { isInfluentia, isInfluencer } = usePermissions();
  const [user, setUser] = useState<any>({});
  const { data, refetch } = useQuery<ResponseSingleUser>(
    // const { loading } = useQuery<ResSingleCampaignType>(
    GET_SINGLE_USER,
    {
      variables: {
        id,
      },
      onError: () => {
        // TODO: SHOW MISSING CAMPAIGN ID NOTIFICATION
        notifyWarning(t('global.errorSingleInfluencer'));
        navigate(ROUTES.INFLUENCERS);
      },
      onCompleted: (info: any) => {
        setUser(info.user.data);
      },
      skip: !id,
    },
  );

  const {
    data: campaigns,
    resetPagination,
    total,
    error,
    loading,
    filters,
    setFilters,
    table,
    refetch: refetchTable,
  } = useTableDataAndFilters<
    CampaignFilters,
    CampaignTable,
    ExtraInfoCampaignHeader,
    ResCampaignsList
  >({
    filterAtom: campaignFilterUserAtom,
    getColumns: getCampaignsTableHeaders,
    columnsExtras: { isInfluencer: isInfluencer() },
    responseAccessKey: 'campaigns',
    query: GET_CAMPAIGNS_LIST,
    subrowAccesor: 'users',
  });

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      influencers: [{ label: '', value: data?.user.data.id || '' }],
    }));
    return () => {
      resetPagination();
      setFilters((prev) => ({
        ...prev,
        influencers: undefined,
      }));
    };
  }, [user]);

  const handleTableClick = (idClick: string, influencer_id?: string) => {
    const campaign = campaigns.find((c) => c.id === idClick);
    if (campaign)
      navigate(
        replaceIds({
          url: ROUTES.SINGLE_CAMPAIGN_GENERAL,
          params: [
            {
              campaignId: campaign.id,
            },
            {
              influencerId:
                influencer_id ||
                (campaign.users[0] ? campaign.users[0].user_id : '') ||
                '',
            },
          ],
        }),
      );
  };

  return (
    <div className="w-full flex flex-col gap-y-5">
      <Breadcrumbs
        icon={<InfluencersIcon />}
        breadcrumbs={[
          {
            text: t('singleInfluencer.influencerBreadcrumps'),
            link: ROUTES.INFLUENCERS,
          },
          {
            text: data?.user.data.name || '',
            link: ROUTES.SINGLE_INFLUENCER.replace(':id', user.id),
          },
          {
            text: t('campaigns.title'),
          },
        ]}
      />
      <div>
        {user.id !== myUser.id &&
          isInfluentia() &&
          !location.pathname.includes('user') && (
            <div className="flex-row gap-x-2 overflow-x-auto customScroll pb-1 lg:hidden flex">
              <Tab
                to={ROUTES.SINGLE_INFLUENCER.replace(':id', user.id)}
                text={t('profile.menu.title1')}
              />
              <Tab
                to={ROUTES.SINGLE_INFLUENCER_CAMPAIGNS.replace(':id', user.id)}
                text={t('profile.menu.title2')}
              />
            </div>
          )}
        <div className="flex flex-row gap-12">
          {user.id !== myUser.id &&
            isInfluentia() &&
            !location.pathname.includes('user') && (
              <div className="h-screen sticky top-0 lg:flex hidden">
                <LateralProfileMenu user={user} />
              </div>
            )}
          <div>
            <DataTable
              error={error}
              handleRowClick={(idClick) => handleTableClick(idClick)}
              handleSubrowClick={(idClick, influencer_id) =>
                handleTableClick(idClick, influencer_id)
              }
              loading={loading}
              table={table}
              total={total}
              tableId="CampaignsTable"
              sorting={{ ...filters }}
              SubrowsComponent={SubrowTable}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
