import { gql } from '@apollo/client';

export const UPDATE_CONTENT = gql`
  mutation UpdateContent(
    $file: Upload
    $contentId: String!
    $link: String
    $status: String
  ) {
    updateContent(
      file: $file
      contentId: $contentId
      link: $link
      status: $status
    ) {
      status
      code
      data
      error
    }
  }
`;

export const DELETE_FILE = gql`
  mutation DeleteFile($fileId: String!) {
    deleteFile(fileId: $fileId) {
      status
      code
      data
      error
    }
  }
`;
