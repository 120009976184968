import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DownloadIcon, FileIcon, TrashIcon } from '../../../assets';
import { Confirm } from '../../../context/confirm/confirm';
import { downloadDocumentClick } from '../../../utils/utils';

type FileProps = {
  name: string;
  fileUrl?: string;
  deleteContent: () => void;
};

export function File({ name, fileUrl, deleteContent }: FileProps) {
  const { getAccessTokenSilently } = useAuth0();

  const fetchItems = async () => {
    if (!fileUrl) return;
    await downloadDocumentClick(fileUrl, await getAccessTokenSilently());
  };
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('common');
  return (
    <div className="flex gap-5 mb-1 h-[41px]">
      <Confirm
        open={open}
        onConfirm={() => deleteContent()}
        setOpen={setOpen}
        type="delete"
        title={t('global.deleteTitle')}
        subtitle={t('global.deleteSubtitle')}
        confirmText={t('global.delete')}
        cancelText={t('global.cancel')}
      />
      <div
        className={`bg-white p-1 text-influentia-black rounded-[4px] w-full flex items-center`}
      >
        <div className="flex flex-row justify-between w-full">
          <div className="flex flex-row gap-[10px] items-center justify-center">
            <div className="flex items-center justify-center">
              <FileIcon className={`size-[25px]`} />
            </div>
            <span className="text-sm font-inter ">{name}</span>
          </div>
        </div>
        <div className="flex flex-row gap-2 items-center justify-center pr-2">
          <a
            onClick={fetchItems}
            target="_blank"
            rel="noreferrer noopener"
            className={`cursor-pointer`}
          >
            <DownloadIcon />
          </a>
        </div>
      </div>
      <div
        className={`flex items-center justify-center cursor-pointer mr-[5px]`}
        onClick={() => setOpen(true)}
      >
        <TrashIcon className="size-[15px] text-red-status" />
      </div>
    </div>
  );
}
