import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DownloadIcon, FileIcon, Link, TrashIcon } from '../../../assets';
import { Confirm } from '../../../context/confirm/confirm';
import { usePermissions } from '../../../hooks/usePermissions';
import { downloadDocumentClick } from '../../../utils/utils';

type ScriptVersionProps = {
  version: number;
  contentLink?: string;
  fileUrl?: string;
  contentStatus: string;
  deleteContent: () => void;
};

export function ScriptVersion({
  version,
  contentLink,
  fileUrl,
  contentStatus,
  deleteContent,
}: ScriptVersionProps) {
  const { getAccessTokenSilently } = useAuth0();
  const [open, setOpen] = useState(false);

  const fetchItems = async () => {
    if (!fileUrl) return;
    await downloadDocumentClick(fileUrl, await getAccessTokenSilently());
  };
  const { isBrand } = usePermissions();
  const { t } = useTranslation('common');
  return (
    <div className="flex gap-5 mb-1">
      <Confirm
        open={open}
        onConfirm={() => deleteContent()}
        setOpen={setOpen}
        type="delete"
        title={t('global.deleteTitle')}
        subtitle={t('global.deleteSubtitle')}
        confirmText={t('global.delete')}
        cancelText={t('global.cancel')}
      />
      <div
        className={`bg-white p-1 text-influentia-black rounded-[4px] w-full`}
      >
        <div className="flex flex-row justify-between ">
          <div className="flex flex-row gap-[10px]  items-center justify-center">
            <div className="flex items-center justify-center">
              {contentLink ? (
                <Link className={`size-[25px]`} />
              ) : (
                <FileIcon className={`size-[25px]`} />
              )}
            </div>

            <div className="text-sm font-inter">
              {contentLink ? (
                <a
                  className="underline"
                  href={contentLink}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {t('singleCampaign.linkScript')}
                </a>
              ) : (
                <div>{t('contentType.Script')}</div>
              )}
            </div>
          </div>
          <div className="flex flex-row gap-2 items-center justify-center">
            <a
              onClick={fetchItems}
              target="_blank"
              rel="noreferrer noopener"
              className={`cursor-pointer ${contentLink && 'hidden'}`}
            >
              <DownloadIcon />
            </a>
            <div className="">V{version}</div>
          </div>
        </div>
      </div>
      <div
        className={`flex items-center justify-center cursor-pointer mr-[5px] ${contentStatus === 'validated' || isBrand() ? 'hidden' : ''}`}
        onClick={() => setOpen(true)}
      >
        <TrashIcon className="size-[15px] text-red-status" />
      </div>
    </div>
  );
}
