import { useMutation } from '@apollo/client';

import { DELETE_METRIC } from '../../../graphql/metrics/mutation';
import { useNotification } from '../../../hooks/useNotification';
import { usePermissions } from '../../../hooks/usePermissions';
import type { Metric, SingleMetric } from '../../../types';
import { MetricPicture } from './MetricPicture';

type MetricsProps = {
  metric: Metric;
  refetch: () => void;
};
export function Metrics({ metric, refetch }: MetricsProps) {
  const [deleteMetric] = useMutation(DELETE_METRIC);
  const { notifySaveSuccess, notifySaveError } = useNotification();
  const { isInfluencer } = usePermissions();
  const deleteFileHandler = (metricId: string) => {
    deleteMetric({
      variables: {
        metricId,
      },
      onCompleted: () => {
        notifySaveSuccess();
        refetch();
      },
      onError: () => {
        notifySaveError();
      },
    });
  };
  return (
    <div className="flex flex-col gap-4 mb-2.5">
      <span className={`text-white ${isInfluencer() && 'hidden'}`}>
        {metric.user.name}
      </span>
      <div className="lg:flex lg:flex-row grid sm:grid-cols-2 md:grid-cols-4 gap-4">
        {metric.metrics.length > 0 &&
          metric.metrics.map((infoMetric: SingleMetric) => (
            <MetricPicture
              key={infoMetric.id}
              fileUrl={infoMetric.file.url}
              deleteContent={() => deleteFileHandler(infoMetric.id)}
            />
          ))}
      </div>
    </div>
  );
}
