import type { ReactNode } from 'react';
import { Tooltip } from 'react-tooltip';

type Props = {
  content: string;
  id: string;
  image?: ReactNode;
};

const DTBoldCell = ({ id, content, image }: Props) => {
  return (
    <>
      <Tooltip
        id={id}
        className="!rounded-[5px] !bg-greenextralight !px-3 !py-1 !font-bold !text-white"
        noArrow
        float
        place="right"
      />
      <span
        data-tooltip-id={id}
        data-tooltip-content={content}
        className="cell-class cursor-pointer text-sm font-bold flex items-center flex-row gap-x-2"
      >
        {image}
        {content}
      </span>
    </>
  );
};

export default DTBoldCell;
