/* eslint-disable max-len */
import React, { useEffect } from 'react';

import {
  CloseIcon,
  ErrorIcon,
  InfoIcon,
  SuccessIcon,
  WarningIcon,
} from '../../assets';
import { Button } from '../../components/common/Button';
import type { ConfirmOptions } from '../../types/confirm';

type Props = ConfirmOptions & {
  open: boolean;
  setOpen: (value: boolean) => void;
} & {
  id?: string;
};

type ColorValues = {
  bgColor: string;
  textColor: string;
  iconColor: string;
};

type NotOptional<T> = T extends undefined ? never : T;

const colorMap: Record<NotOptional<Props['type']>, ColorValues> = {
  error: {
    bgColor: '#213A51',
    iconColor: '#00E7FF',
    textColor: '#FFFFFF',
  },
  info: {
    bgColor: '#213A51',
    iconColor: '#00E7FF',
    textColor: '#FFFFFF',
  },
  success: {
    bgColor: '#213A51',
    iconColor: '#00E7FF',
    textColor: '#FFFFFF',
  },
  warning: {
    bgColor: '#213A51',
    iconColor: '#00E7FF',
    textColor: '#FFFFFF',
  },
  delete: {
    bgColor: '#213A51',
    iconColor: '#00E7FF',
    textColor: '#FFFFFF',
  },
};

const iconMap: Record<NotOptional<Props['type']>, React.ReactNode> = {
  error: <ErrorIcon />,
  info: <InfoIcon />,
  success: <SuccessIcon />,
  warning: <WarningIcon className="text-influentia-light-blue" />,
  delete: <WarningIcon className="text-influentia-light-blue" />,
};

export function Confirm({
  open,
  setOpen,
  title,
  subtitle,
  cancelText,
  confirmText,
  onCancel,
  onConfirm,
  type = 'success',
  id,
}: Props) {
  useEffect(() => {
    if (open) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [open]);

  return (
    <dialog
      onClick={() => {
        if (onCancel) onCancel();
        setOpen(false);
      }}
      id={id}
      className={`fixed top-0 left-0 z-50 flex-col items-center justify-center size-full overflow-auto bg-black/50 min-h-full ${
        open ? 'flex' : 'hidden'
      }`}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="flex flex-col items-center justify-between w-10/12 p-4 rounded-lg lg:w-5/12 h-48 z-[100]"
        style={{
          background: colorMap[type].bgColor,
        }}
      >
        <header
          className="flex items-center justify-between w-full pb-2 border-b-2"
          style={{
            color: colorMap[type].textColor,
            borderColor: colorMap[type].textColor,
          }}
        >
          <div className="flex flex-row items-center justify-center gap-x-4">
            {iconMap[type]}
            <label className="text-lg font-bold ">{title}</label>
          </div>
          <CloseIcon
            className="cursor-pointer"
            onClick={() => {
              if (onCancel) onCancel();
              setOpen(false);
            }}
          />
        </header>
        <div className="flex items-center justify-center m-auto rounded-lg">
          <label
            className="text-sm text-center"
            style={{
              color: colorMap[type].textColor,
            }}
          >
            {subtitle}
          </label>
        </div>
        <div className="flex items-center justify-center gap-x-4">
          <Button
            text={String(cancelText)}
            id="popupCancel"
            type="button"
            style="black"
            onClick={() => {
              if (onCancel) onCancel();
              setOpen(false);
            }}
          />
          <Button
            text={String(confirmText)}
            id="popupConfirm"
            type="button"
            style="light-blue"
            customClassnames={`${type === 'delete' ? '!bg-red-status !text-white' : ''}`}
            onClick={() => {
              if (onConfirm) onConfirm();
              setOpen(false);
            }}
          />
        </div>
      </div>
    </dialog>
  );
}
