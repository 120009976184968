import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TrashIcon } from '../../../assets';
import { Confirm } from '../../../context/confirm/confirm';

export function MonthActionRecurrent({
  month,
  removeMonth,
}: {
  month: number;
  removeMonth: (month: number) => void;
}) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('common');
  return (
    <>
      <Confirm
        open={open}
        onConfirm={() => removeMonth(month)}
        setOpen={setOpen}
        type="delete"
        title={t('global.deleteTitle')}
        subtitle={t('global.deleteSubtitle')}
        confirmText={t('global.delete')}
        cancelText={t('global.cancel')}
      />
      <div className="flex flex-row gap-3 items-center">
        <span className="font-inter text-[14px] text-light-blue">
          MES {month}
        </span>
        <div
          onClick={() => setOpen(true)}
          className="cursor-pointer size-5 rounded-[2px] bg-dark-blue flex items-center justify-center"
        >
          <TrashIcon className="text-red-status size-[14px]" />
        </div>
      </div>
    </>
  );
}
