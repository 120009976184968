import type { ReactNode } from 'react';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { CloseIcon, FilterIcon } from '../../assets';
import { Button } from '../common/Button';

type FiltersPopoverProps = {
  closeModal: () => void;
  children: ReactNode;
  title: string;
  resetFilter: () => void;
  onSubmit: any;
};

export const FiltersPopover = ({
  closeModal,
  children,
  title,
  resetFilter,
  onSubmit,
}: FiltersPopoverProps) => {
  const { t } = useTranslation('common');
  const modalRef = useRef(null);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (
        modalRef.current &&
        !(modalRef.current as any).contains(event.target) &&
        event.target.id === 'openedModal'
      ) {
        closeModal();
      }
    }
    // Bind the event listener
    document.addEventListener('click', handleClickOutside);
    document.body.className = 'overflow-hidden bg-influentia-black';
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('click', handleClickOutside);
      document.body.className = 'overflow-auto bg-influentia-black';
    };
  }, [modalRef]);

  const onReset = () => {
    resetFilter();
    closeModal();
  };

  return (
    <div
      id="openedModal"
      className="fixed left-0 top-0 z-50 size-full bg-gray-hover/50"
    >
      <div
        ref={modalRef}
        className="absolute top-0 h-screen right-0 rounded-l-[30px] bg-dark-blue-popups md:min-w-[450px] md:max-w-[450px] px-5 py-10"
      >
        <div className="flex flex-col w-full">
          <div className="flex flex-row justify-between w-full">
            <div className="flex flex-row gap-x-2 w-full">
              <FilterIcon />
              <p>{title}</p>
            </div>
            <div className="flex w-full justify-end">
              <button className="rounded-md" onClick={closeModal}>
                <CloseIcon />
              </button>
            </div>
          </div>

          <form onSubmit={onSubmit} className="flex flex-col gap-y-5">
            {children}
            <div className="flex justify-end w-full gap-x-5">
              <Button
                text={t('global.resetFilter')}
                style="black"
                onClick={onReset}
                type="button"
              />
              <Button
                text={t('global.search')}
                style="light-blue"
                type="submit"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
