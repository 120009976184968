import { useEffect, useState } from 'react';

import { SearchInputIcon } from '../../assets';
import { useChangeWithDebounce } from '../../hooks/useChangeWithDebounce';

type TableSearchInputProps = {
  filters: {
    [key: string]: any;
  };
  setFilters: (val: any) => void;
  searchKey: string;
  placeholder: string;
};

export const TableSearchInput = ({
  placeholder,
  searchKey,
  filters,
  setFilters,
}: TableSearchInputProps) => {
  const [value, setValue] = useState<string>('');
  const { handleFilterChangeWithDebounce } = useChangeWithDebounce({
    setFilterValue: setValue,
    filters,
    setFilters,
    searchKey,
  });

  useEffect(() => {
    if (filters[searchKey]) setValue(filters[searchKey]);
  }, []);

  return (
    <div className={`relative flex w-full items-center text-dark-blue`}>
      <div className="flex items-center justify-start">
        <input
          id={searchKey}
          value={value}
          onChange={handleFilterChangeWithDebounce}
          type={'text'}
          className={`block h-[41px] w-full min-w-[370px] border-none pr-3 pl-9 py-[8px] text-sm font-medium placeholder:text-[#5A5A5A] focus:outline-none focus:ring-0 rounded-[4px]`}
          placeholder={placeholder}
          autoComplete="off"
        />
        <div className="absolute left-3">
          <SearchInputIcon />
        </div>
      </div>
    </div>
  );
};
