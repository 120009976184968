import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { components } from 'react-select';

import { PauseIcon, PlayIcon } from '../../assets';
import { Confirm } from '../../context/confirm/confirm';
import {
  UPDATE_INFLUENCER_SETTINGS,
  UPDATE_INFLUENCER_SETTINGS_RECURRENT,
} from '../../graphql/campaign/mutation';
import { useNotification } from '../../hooks/useNotification';
import { usePermissions } from '../../hooks/usePermissions';
import type { InfluencerCampaignTranslatedStatusType } from '../../types';
import { StatusText } from '../common/StatusText';

const STATUS_COLOR: Record<string, string> = {
  not_send: '!bg-gray-status !text-dark-blue',
  accepted_pending: '!bg-pink-status !text-white',
  declined: '!bg-red-status !text-white',
  confirmation_pending: '!bg-influentia-yellow !text-dark-blue',
  active: '!bg-blue-status !text-white',
  analysis: '!bg-orange-status !text-dark-blue',
  facturation: '!bg-violet-status !text-white',
  canceled: '!bg-red-status !text-white',
  finished: '!bg-green-status !text-dark-blue',
};
// const STATUS_COLOR = {
//   'No Enviada': '!bg-gray-status !text-dark-blue',
//   'Pendiente de Aceptación': '!bg-pink-status !text-white',
//   Rechazada: '!bg-red-status !text-white',
//   'Pendiente de Confirmación': '!bg-influentia-yellow !text-dark-blue',
//   Activa: '!bg-blue-status !text-white',
//   Análisis: '!bg-orange-status !text-dark-blue',
//   Facturación: '!bg-violet-status !text-white',
//   Cancelada: '!bg-red-status !text-white',
//   Finalizada: '!bg-green-status !text-dark-blue',
// };

interface InfluencerStatusDropdownProps {
  // defaultStatus: InfluencerCampaignTranslatedStatusType | any;
  defaultStatus: any;
  onChange?: (status: InfluencerCampaignTranslatedStatusType) => void;
  influencerId: string;
  campaignId: string;
  refetch?: () => void;
  refetchTabs?: () => void;
  recurrent?: boolean;
  influencerPaused?: boolean;
}

const InfluencerStatusDropdown = ({
  defaultStatus,
  onChange,
  influencerId,
  campaignId,
  refetch,
  refetchTabs,
  recurrent,
  influencerPaused,
}: InfluencerStatusDropdownProps) => {
  const { t } = useTranslation('common');
  const [selectedStatus, setSelectedStatus] = useState(defaultStatus);
  const { isInfluentia } = usePermissions();
  const statusOptions = Object.keys(STATUS_COLOR).map((status) => ({
    value: status,
    label: t(`influencerStatus.${status}`),
  }));
  const [paused, setPaused] = useState(influencerPaused);
  const { notifySaveSuccess, notifySaveError } = useNotification();
  const [open, setOpen] = useState(false);
  const [updateInfluencerSettings] = useMutation(UPDATE_INFLUENCER_SETTINGS);
  const [updateInfluencerSettingsRecurrent] = useMutation(
    UPDATE_INFLUENCER_SETTINGS_RECURRENT,
  );

  const handlePause = () => {
    updateInfluencerSettingsRecurrent({
      variables: {
        influencerId,
        campaignId,
        paused: !paused,
      },
    })
      .then(() => {
        notifySaveSuccess();
        if (refetch) refetch();
        if (refetchTabs) refetchTabs();
      })
      .catch(() => {
        notifySaveError();
      });
  };

  const handleSave = async (status: string) => {
    if (!recurrent) {
      updateInfluencerSettings({
        variables: {
          influencerId,
          status,
          campaignId,
        },
      })
        .then(() => {
          notifySaveSuccess();
          if (refetch) refetch();
          if (refetchTabs) refetchTabs();
        })
        .catch(() => {
          notifySaveError();
        });
    } else {
      updateInfluencerSettingsRecurrent({
        variables: {
          influencerId,
          status,
          campaignId,
        },
      })
        .then(() => {
          notifySaveSuccess();
          if (refetch) refetch();
          if (refetchTabs) refetchTabs();
        })
        .catch(() => {
          notifySaveError();
        });
    }
  };

  useEffect(() => {
    setSelectedStatus(defaultStatus);
    setPaused(influencerPaused);
  }, [defaultStatus, influencerPaused]);

  const handleStatusChange = (
    selectedOption: {
      value: string;
      label: string;
    } | null,
  ) => {
    if (selectedOption) {
      const newStatus =
        selectedOption.value as InfluencerCampaignTranslatedStatusType;
      setSelectedStatus(newStatus);
      if (onChange) onChange(newStatus);
      handleSave(newStatus);
    }
  };

  const customStyles = {
    menuList: (base: any) => ({
      ...base,
      padding: 0,
      borderRadius: '4px',
      '::-webkit-scrollbar': {
        width: '6px',
        height: '0px',
      },
      '::-webkit-scrollbar-track': {
        background: 'white',
        borderRadius: '20px',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#16162C',
        borderRadius: '20px',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: '#16162C',
        borderRadius: '20px',
      },
    }),
    option: (base: any, props: any) => ({
      ...base,
      background: props.isSelected ? '#00E7FF' : 'white',
      ':hover': {
        border: 'none',
        boxShadow: 'none',
        background: props.isSelected ? '#00E7FF' : '#00E7FF',
      },
    }),
  };
  const CustomOption = (props: any) => (
    <components.Option
      className="bg-white hover:!bg-dark-blue hover:!text-white !text-dark-blue"
      {...props}
    >
      <div className="flex items-center justify-center">
        <div
          className={` !cursor-pointer text-center rounded-10 py-1 flex flex-row justify-center items-center gap-x-2`}
        >
          <div
            className={`size-[10px] flex-none rounded-full ${STATUS_COLOR[props.data.value as keyof typeof STATUS_COLOR]}`}
          />
          <div className="items-center text-center text-[13px] font-medium">
            {props.data.label}
          </div>
        </div>
      </div>
    </components.Option>
  );
  return (
    <>
      <Confirm
        open={open}
        onConfirm={() => handlePause()}
        setOpen={setOpen}
        type="info"
        title={paused ? t('global.titlePlayUser') : t('global.titlePauseUser')}
        subtitle={
          paused ? t('global.subTitlePlayUser') : t('global.subTitlePauseUser')
        }
        confirmText={
          paused ? t('global.confirmPlay') : t('global.confirmPause')
        }
        cancelText={t('global.cancel')}
      />
      {!influencerPaused && (
        <div className="relative inline-block w-full">
          <div className="flex flex-row gap-2">
            <Select
              value={statusOptions.find(
                (option) => option.value === selectedStatus,
              )}
              onChange={handleStatusChange}
              options={statusOptions}
              styles={customStyles}
              components={{
                Option: CustomOption,
              }}
              classNames={{
                control: () =>
                  `!border-none !shadow-none rounded-md !w-full ${recurrent ? 'min-w-[200px]' : 'min-w-[232px]'} text-center ${STATUS_COLOR[selectedStatus]}`,
                singleValue: () => `${STATUS_COLOR[selectedStatus]}`,
                indicatorSeparator: () => '!hidden',
                dropdownIndicator: () => '!hidden',
                indicatorsContainer: () => '!hidden',
              }}
              isDisabled={!isInfluentia()}
              classNamePrefix="react-select"
              isSearchable={false}
            />
            <button
              onClick={() => setOpen(true)}
              className={`${!recurrent && 'hidden'}`}
            >
              <div className="size-[26px] rounded-full bg-red-status flex items-center justify-center">
                <PauseIcon className="size-[14px]" />
              </div>
            </button>
          </div>
        </div>
      )}
      {influencerPaused && recurrent && (
        <div className="flex flex-row gap-2">
          <StatusText
            extraClassnames="px-12 min-h-[38px]"
            type="action"
            status={'paused'}
          />
          <button onClick={() => setOpen(true)}>
            <div className="size-[26px] rounded-full bg-influentia-light-blue flex items-center justify-center">
              <PlayIcon className="size-[14px]" />
            </div>
          </button>
        </div>
      )}
    </>
  );
};

export default InfluencerStatusDropdown;
