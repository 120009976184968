import { gql } from '@apollo/client';

export const GET_METRIC_REPORT = gql`
  query MetricsReport($campaignId: String!) {
    metricsReport(campaignId: $campaignId) {
      status
      code
      error
      data {
        id
        file {
          id
          name
          url
        }
      }
    }
  }
`;

export const GET_METRICS = gql`
  query Metrics($campaignId: String!) {
    metrics(campaignId: $campaignId) {
      status
      code
      error
      data {
        user {
          id
          name
        }
        metrics {
          id
          type
          file {
            id
            name
            url
          }
        }
      }
    }
  }
`;
