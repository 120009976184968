import React from 'react';
import { useTranslation } from 'react-i18next';

import { UserIconList } from '../../../assets';

type CampaignInfluencersProps = {
  influencers: {
    id: string;
    name: string;
  }[];
};

export const CampaignInfluencers = ({
  influencers,
}: CampaignInfluencersProps) => {
  const { t } = useTranslation('common');

  return (
    <div className="flex flex-row gap-[6px]">
      <div className="rounded-full bg-white flex justify-center items-center size-[28px] text-influentia-black">
        <UserIconList />
      </div>
      <div className="flex flex-col">
        <span className="text-influentia-light-blue font-medium text-[10px]">
          {t('global.influencers')}
        </span>
        <div className="flex flex-col gap-1">
          {influencers.map((influencer) => (
            <div
              key={influencer.id}
              className="flex flex-row gap-1 text-sm items-center"
            >
              <span className="font-medium text-white">{influencer.name}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
