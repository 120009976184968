import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { BrandsIcon } from '../assets';
import { Breadcrumbs } from '../components/common/Breadcrumbs';
import { BrandForm } from '../components/users/brandForm';
import { ROUTES } from '../config/routes/config';
import { GET_BRAND } from '../graphql/brands/query';
import { useNotification } from '../hooks/useNotification';

export const SingleBrand = () => {
  const { id } = useParams();
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const { notifyWarning } = useNotification();
  const { data, refetch } = useQuery(
    // const { loading } = useQuery<ResSingleCampaignType>(
    GET_BRAND,
    {
      variables: {
        brandId: id,
      },
      onError: () => {
        notifyWarning(t('global.errorSingleBrand'));
        navigate(ROUTES.BRANDS);
      },
      skip: !id,
    },
  );
  return (
    <div className="w-full flex flex-col gap-y-5">
      {data && data.brand && data.brand.data && (
        <>
          <Breadcrumbs
            icon={<BrandsIcon />}
            breadcrumbs={[
              {
                text: t('singleBrand.Breadcrumps'),
                link: ROUTES.BRANDS,
              },
              {
                text: data?.brand.data.name || '',
              },
            ]}
          />
          <BrandForm info={data?.brand.data} refetch={refetch} />
        </>
      )}
    </div>
  );
};
