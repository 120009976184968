import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AddUserButtonIcon } from '../../assets';
import { Button } from '../common/Button';
import { InfluencerPopUp } from './InfluencerPopUp';

type TCreateInfluencer = {
  name: string;
  email: string;
};

export const CreateInfluencer = ({ refetch }: { refetch: any }) => {
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        style="light-blue"
        text={t('influencers.createInfluencer')}
        id="createInfluencer"
        iconLeft={<AddUserButtonIcon />}
      />
      {open && <InfluencerPopUp setOpen={setOpen} refetch={refetch} />}
    </>
  );
};
