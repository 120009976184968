import React from 'react';
import { useTranslation } from 'react-i18next';

import { ROUTES } from '../../config/routes/config';
import { Tab } from '../campaigns/SingleCampaignTabs';

interface ProfileTabsProps {
  location: { pathname: string };
  user: { id: string };
  myUser: { id: string; role: string };
}

const ProfileTabs: React.FC<ProfileTabsProps> = ({
  location,
  user,
  myUser,
}) => {
  const { t } = useTranslation('common');

  return (
    <div
      className={`flex flex-row gap-x-2 overflow-auto customScroll pb-1 w-full ${location.pathname.includes('user') && 'hidden'}`}
    >
      <Tab
        id="personalInfoTab"
        to={
          user.id === myUser.id
            ? ROUTES.PROFILE
            : ROUTES.SINGLE_INFLUENCER.replace(':id', user.id)
        }
        text={t('profile.menu.title1')}
      />
      {user.id === myUser.id && myUser.role === 'Brand' && (
        <Tab
          to={ROUTES.PROFILE_ORG_AND_ACCESS.replace(':id', user.id)}
          id="accessTab"
          text={t('profile.menu.title4')}
        />
      )}
      {user.id === myUser.id && (
        <Tab
          id="notificationsTab"
          to={ROUTES.PROFILE_NOTIFICATIONS}
          text={t('profile.menu.title3')}
        />
      )}
      {user.id !== myUser.id && (
        <Tab
          to={ROUTES.SINGLE_INFLUENCER_CAMPAIGNS.replace(':id', user.id)}
          id="campaignsTab"
          text={t('profile.menu.title2')}
        />
      )}
    </div>
  );
};

export default ProfileTabs;
