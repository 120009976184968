import React, { useState } from 'react';
import type { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TrashIcon } from '../../../assets';
import { Confirm } from '../../../context/confirm/confirm';
import { GenericDropdown } from '../../inputs/GenericDropdown';

interface InfluencerItem {
  id: string;
  name: string;
  File?: {
    url: string;
  };
}

interface InfluencerProps {
  index: number;
  control: Control;
  onSelectInfluencer: (val: any, index: number) => void;
  allInfluencers: InfluencerItem[];
  selectedInfluencers: InfluencerItem[];
  deleteInfluencer: () => void;
}

const SelectInfluencer: React.FC<InfluencerProps> = ({
  index,
  control,
  onSelectInfluencer,
  allInfluencers,
  selectedInfluencers,
  deleteInfluencer,
}) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('common');
  const currentInfluencer = selectedInfluencers[index];

  // Filter out already selected influencers
  const availableInfluencers = allInfluencers.filter(
    (influencer) =>
      !selectedInfluencers.some(
        (selected) =>
          selected.id === influencer.id &&
          selected.id !== currentInfluencer?.id,
      ),
  );

  return (
    <div className="flex flex-row gap-2.5 items-center justify-center">
      <Confirm
        open={open}
        onConfirm={() => deleteInfluencer()}
        setOpen={setOpen}
        type="delete"
        title={t('global.deleteTitle')}
        subtitle={t('global.deleteSubtitle')}
        confirmText={t('global.delete')}
        cancelText={t('global.cancel')}
      />

      <GenericDropdown
        isSearchable={true}
        hasProfilePicture={true}
        name={`influencer-${index}`}
        control={control}
        onChangeManual={(val: any) => onSelectInfluencer(val, index)}
        options={availableInfluencers.map((influencer) => ({
          value: influencer.id,
          label: influencer.name,
          profile_picture: influencer.File ? influencer.File.url : undefined,
        }))}
      />
      <div
        className="flex items-center justify-center cursor-pointer ml-[5px]"
        onClick={() => setOpen(true)}
      >
        <TrashIcon className="size-[15px] text-red-status" />
      </div>
    </div>
  );
};

export default SelectInfluencer;
