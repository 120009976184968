import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FilterIcon } from '../../assets';
import type { BrandsFilters } from '../../types';
import { Button } from '../common/Button';
import { TextInput } from '../inputs/TextInput';
import { FiltersPopover } from './FiltersPopover';

type BrandFiltersProps = {
  setFilters: (val: BrandsFilters) => void;
  filters: BrandsFilters;
};

export const BrandFiltersPopover = ({
  setFilters,
  filters,
}: BrandFiltersProps) => {
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);

  const { handleSubmit, reset, register } = useForm<BrandsFilters>({
    defaultValues: {},
  });

  useEffect(() => {
    reset(filters);
  }, [filters]);

  const handleSearch = (data: BrandsFilters) => {
    setFilters({
      ...data,
      fromCampaignActives:
        data.fromCampaignActives !== undefined
          ? Number(data.fromCampaignActives)
          : undefined,
      toCampaignActives:
        data.toCampaignActives !== undefined
          ? Number(data.toCampaignActives)
          : undefined,
    });
    setOpen(false);
  };

  const onReset = () => {
    setFilters({});
    reset({});
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        style="light-blue"
        text=""
        iconLeft={<FilterIcon />}
      />
      {open && (
        <FiltersPopover
          title={t('brands.filtersTitle')}
          closeModal={() => setOpen(false)}
          resetFilter={onReset}
          onSubmit={handleSubmit(handleSearch)}
        >
          <div className="flex flex-col gap-y-5">
            <div className="flex flex-row gap-x-2">
              <TextInput
                register={register}
                registerName="fromCampaignActives"
                type="number"
                label={t('brands.minCampaignsActive')}
                placeholder={t('brands.minCampaignsActivePlaceholder')}
              />
              <TextInput
                register={register}
                registerName="toCampaignActives"
                type="number"
                label={t('brands.maxCampaignsActive')}
                placeholder={t('brands.maxCampaignsActivePlaceholder')}
              />
            </div>
          </div>
        </FiltersPopover>
      )}
    </>
  );
};
