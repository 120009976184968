import { gql } from '@apollo/client';

export const CREATE_METRIC = gql`
  mutation CreateMetric($campaignId: String!, $file: Upload!, $type: String!) {
    createMetric(campaignId: $campaignId, file: $file, type: $type) {
      status
      code
      data
      error
    }
  }
`;

export const DELETE_METRIC = gql`
  mutation DeleteMetric($metricId: String!) {
    deleteMetric(metricId: $metricId) {
      status
      code
      data
      error
    }
  }
`;
