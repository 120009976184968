import type { MouseEvent } from 'react';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import {
  BrandsIcon,
  CalendarIcon,
  CampaignIcon,
  CampaignRecurrent,
  HomeIcon,
  InfluencersIcon,
  InvoiceIcon,
  UsersIcon,
} from '../../assets';
import { ROUTES } from '../../config/routes/config';
import { usePermissions } from '../../hooks/usePermissions';

const TooltipWrapper = ({ id }: { id: string }) => {
  return (
    <Tooltip
      className="!bg-influentia-light-blue !text-dark-blue font-montserrat !p-1 !text-sm"
      noArrow
      id={id}
      place="right"
    />
  );
};

interface MenuModalProps {
  closeModal: () => void;
}

export const MenuModal = ({ closeModal }: MenuModalProps) => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const modalRef = useRef(null);
  const { isInfluentia } = usePermissions();

  const onClick = (event: MouseEvent, url: string) => {
    closeModal();
    event.stopPropagation();
    navigate(url);
  };

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (
        modalRef.current &&
        !(modalRef.current as any).contains(event.target) &&
        event.target.id === 'openedModal'
      ) {
        closeModal();
      }
    }
    // Bind the event listener
    document.addEventListener('click', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('click', handleClickOutside);
    };
  }, [modalRef]);

  return (
    <div id="openedModal" className="fixed left-0 top-0 z-[60] size-full">
      <div
        ref={modalRef}
        className="absolute top-16 left-0 rounded-lg translate-x-1/4"
      >
        <nav className="flex flex-col gap-y-10 py-10 p-2 border-4 border-white/40 bg-dark-blue rounded-lg">
          <button
            onClick={(e) => onClick(e, ROUTES.DASHBOARD)}
            className="hover:text-influentia-light-blue"
            data-tooltip-id="tooltip-menu-1"
            data-tooltip-content={t('menu.home')}
          >
            <HomeIcon />
            <TooltipWrapper id="tooltip-menu-1" />
          </button>
          <button
            onClick={(e) => onClick(e, ROUTES.CAMPAIGNS)}
            className="hover:text-influentia-light-blue"
            data-tooltip-id="tooltip-menu-2"
            data-tooltip-content={t('menu.campaigns')}
          >
            <CampaignIcon />
            <TooltipWrapper id="tooltip-menu-2" />
          </button>
          {isInfluentia() && (
            <button
              onClick={(e) => onClick(e, ROUTES.CAMPAIGNS_RECURRENT)}
              className="hover:text-influentia-light-blue"
              data-tooltip-id="tooltip-menu-4"
              data-tooltip-content={t('menu.campaignsRecurrent')}
            >
              <CampaignRecurrent />
              <TooltipWrapper id="tooltip-menu-4" />
            </button>
          )}
          <button
            onClick={(e) => onClick(e, ROUTES.CALENDAR)}
            className="hover:text-influentia-light-blue"
            data-tooltip-id="tooltip-menu-3"
            data-tooltip-content={t('menu.calendar')}
          >
            <CalendarIcon />
            <TooltipWrapper id="tooltip-menu-3" />
          </button>
          <button
            onClick={(e) => onClick(e, ROUTES.INVOICES)}
            className="hover:text-influentia-light-blue"
            data-tooltip-id="tooltip-menu-4"
            data-tooltip-content={t('menu.invoices')}
          >
            <InvoiceIcon />
            <TooltipWrapper id="tooltip-menu-4" />
          </button>

          {isInfluentia() && (
            <>
              <button
                onClick={(e) => onClick(e, ROUTES.INFLUENCERS)}
                className="hover:text-influentia-light-blue"
                data-tooltip-id="tooltip-menu-4"
                data-tooltip-content={t('menu.influencers')}
              >
                <InfluencersIcon />
                <TooltipWrapper id="tooltip-menu-4" />
              </button>

              <button
                onClick={(e) => onClick(e, ROUTES.BRANDS)}
                className="hover:text-influentia-light-blue"
                data-tooltip-id="tooltip-menu-4"
                data-tooltip-content={t('menu.brands')}
              >
                <BrandsIcon />
                <TooltipWrapper id="tooltip-menu-4" />
              </button>
              <button
                onClick={(e) => onClick(e, ROUTES.INFLUENTIA_USERS)}
                className="hover:text-influentia-light-blue"
                data-tooltip-id="tooltip-menu-4"
                data-tooltip-content={t('menu.users')}
              >
                <UsersIcon />
                <TooltipWrapper id="tooltip-menu-4" />
              </button>
            </>
          )}
        </nav>
      </div>
    </div>
  );
};
