import { gql } from '@apollo/client';

export const MARK_ALL_NOTIFICATIONS_READ = gql`
  mutation SetAllNotificationReaded {
    setAllNotificationReaded {
      status
      code
      data
      error
    }
  }
`;

export const MARK_NOTIFICATION_READ = gql`
  mutation SetNotificationReaded($id: String!) {
    setNotificationReaded(id: $id) {
      status
      code
      data
      error
    }
  }
`;

export const REGISTER_NOTIFICATION_PUSH = gql`
  mutation RegisterNotificationPush($permissions: String) {
    registerNotificationPush(permissions: $permissions) {
      status
      code
      data
      error
    }
  }
`;
