import { DefaultBrandIcon } from '../../assets';

export const BrandImage = ({
  image,
  size = 'size-[76px]',
}: {
  image?: string;
  size?:
    | 'size-[76px]'
    | 'size-[50px]'
    | 'size-[20px]'
    | 'size-[38px]'
    | 'size-[24px]';
}) => {
  return (
    <>
      {image ? (
        <img
          src={`${process.env.REACT_APP_AWS_PUBLIC_ENDPOINT}/${image}`}
          className={`${size} rounded-full object-cover`}
        />
      ) : (
        <div className={`${size} rounded-full`}>
          <DefaultBrandIcon className="size-[inherit]" />
        </div>
      )}
    </>
  );
};
