import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FileIcon, TrashIcon } from '../../../assets';
import { Confirm } from '../../../context/confirm/confirm';
import { usePermissions } from '../../../hooks/usePermissions';
import { downloadDocumentClick } from '../../../utils/utils';

type MetricReportProps = {
  name: string;
  fileUrl?: string | undefined;
  deleteContent?: () => void;
};

export function MetricReport({
  name,
  fileUrl,
  deleteContent,
}: MetricReportProps) {
  const { getAccessTokenSilently } = useAuth0();
  const { isBrand } = usePermissions();
  const fetchItems = async () => {
    if (!fileUrl) return;
    await downloadDocumentClick(fileUrl, await getAccessTokenSilently());
  };
  const [open, setOpen] = useState(false);

  const { t } = useTranslation('common');
  return (
    <div className="bg-dark-blue w-[176px] px-2.5 py-5 rounded-[20px] flex items-center flex-col">
      <Confirm
        open={open}
        onConfirm={deleteContent}
        setOpen={setOpen}
        type="delete"
        title={t('global.deleteTitle')}
        subtitle={t('global.deleteSubtitle')}
        confirmText={t('global.delete')}
        cancelText={t('global.cancel')}
      />
      {fileUrl && (
        <a
          onClick={fetchItems}
          target="_blank"
          rel="noreferrer noopener"
          className="cursor-pointer bg-white flex flex-col p-5 rounded items-center justify-center text-center gap-2.5 max-w-[143px] max-h-[119px]"
        >
          <FileIcon className={`size-[35px] text-influentia-black`} />
          <div className="text-influentia-black text-sm font-inter truncate max-w-[100px]">
            {name}
          </div>
        </a>
      )}
      {!fileUrl && (
        <div className="italic text-light-blue text-[14px] max-w-[143px] max-h-[119px] items-center justify-center flex w-full text-center">
          {t('singleCampaign.pendingMetrics')}
        </div>
      )}
      <div
        className={`flex items-center justify-center cursor-pointer mt-2.5 ${isBrand() && `hidden`}`}
        onClick={() => setOpen(true)}
      >
        <TrashIcon className="size-[15px] text-red-status" />
        <span className="text-red-status text-[10px] font-medium">
          {t('global.delete')}
        </span>
      </div>
    </div>
  );
}

/*

*/
