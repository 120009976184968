import 'dayjs/locale/es';

import { useMutation, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { CampaignRecurrent, TrashIcon } from '../assets';
import { SingleCampaignRecurrentTabs } from '../components/campaigns/recurrents/SingleCampaignRecurrentTabs';
import { SingleCampaignInfo } from '../components/campaigns/Sidebar/SingleCampaignInfo';
import { Breadcrumbs } from '../components/common/Breadcrumbs';
import { Button } from '../components/common/Button';
import { replaceIds, ROUTES } from '../config/routes/config';
import { Confirm } from '../context/confirm/confirm';
import { RefetchLateral } from '../context/refetchLateral/refetchProvider';
import { DELETE_CAMPAIGN } from '../graphql/campaign/mutation';
import {
  GET_SINGLE_CAMPAIGN_RECURRENT_MAIN_INFO,
  SINGLE_CAMPAIGN_RECURRENT,
} from '../graphql/campaign/query';
import { useNotification } from '../hooks/useNotification';
import { usePermissions } from '../hooks/usePermissions';
import { userAtom } from '../states/atoms';
import type {
  ResSingleCampaignRecurrentType,
  SingleCampaignData,
  SingleCampaignMainInfo,
} from '../types';

dayjs.extend(localizedFormat);
dayjs.locale('es');
function getSegmentBeforeSecondUUID(url: string): string | null {
  const segments = url.split('/');
  let uuidCount = 0;

  for (let i = 0; i < segments.length; i += 1) {
    if (/^[0-9a-fA-F-]{36}$/.test(segments[i])) {
      uuidCount += 1;
      if (uuidCount === 2) {
        return segments[i - 1];
      }
      // If it's the first UUID and there is no second one, return the next segment
      if (
        uuidCount === 1 &&
        i + 1 < segments.length &&
        !segments.slice(i + 1).some((seg) => /^[0-9a-fA-F-]{36}$/.test(seg))
      ) {
        return segments[i + 1];
      }
    }
  }

  // if no second uuid, return whatever is after the first one
  for (let i = 0; i < segments.length; i += 1) {
    if (/^[0-9a-fA-F-]{36}$/.test(segments[i])) {
      return segments[i + 1];
    }
  }

  return null;
}

function SingleCampaignRecurrent() {
  const location = getSegmentBeforeSecondUUID(window.location.pathname) || '';
  const { isInfluencer } = usePermissions();
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const { campaignId, influencerId, id } = useParams();
  const user = useRecoilValue(userAtom);
  const [campaignIdState] = useState<string>(campaignId ?? '');
  const [influencerIdState, setInfluencerId] = useState(influencerId);
  // IdState used for invoices tab where id can be both influencers and brands id
  const [idState, setId] = useState(id || influencerId);
  const [campaignInfo, setCampaignInfo] = useState<SingleCampaignMainInfo>();
  const [singleCampaignInfo, setSingleCampaignInfo] =
    useState<SingleCampaignData>();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (influencerId) {
      setInfluencerId(influencerId);
      setId(influencerId);
    }
  }, [influencerId]);

  useEffect(() => {
    if (id) setId(id);
    if (campaignInfo?.influencers.map((i) => i.id).includes(id as string))
      setInfluencerId(id);
  }, [id]);

  useEffect(() => {
    if (!campaignIdState) {
      navigate(ROUTES.CAMPAIGNS_RECURRENT);
      // TODO: SHOW MISSING CAMPAIGN ID NOTIFICATION
    }
  }, []);
  const { notifyWarning } = useNotification();

  const { refetch } = useQuery(SINGLE_CAMPAIGN_RECURRENT, {
    skip:
      !influencerIdState ||
      (idState !== undefined && idState !== influencerIdState),
    variables: {
      campaignId,
      influencerId: influencerIdState,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (!data.singleCampaignRecurrent.data) {
        navigate(ROUTES.CAMPAIGNS_RECURRENT);
        notifyWarning(t('global.errorCampaign'));
        return;
      }
      setSingleCampaignInfo(data.singleCampaignRecurrent.data);
    },
    onError: () => {
      notifyWarning(t('global.errorCampaign'));
      navigate(ROUTES.CAMPAIGNS_RECURRENT);
    },
  });

  const { refetch: refetchTabs } = useQuery<ResSingleCampaignRecurrentType>(
    GET_SINGLE_CAMPAIGN_RECURRENT_MAIN_INFO,
    {
      variables: {
        campaignId,
      },
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (!data.campaignRecurrent.data) {
          navigate(ROUTES.CAMPAIGNS_RECURRENT);
          notifyWarning(t('global.errorCampaign'));
          return;
        }
        setCampaignInfo(data.campaignRecurrent.data);
        if (isInfluencer()) setInfluencerId(user.id);
        else if (!influencerIdState) {
          if (
            data.campaignRecurrent.data?.influencers
              .map((i) => i.id)
              .includes(id as string)
          ) {
            setInfluencerId(id);
          } else {
            setInfluencerId(
              data.campaignRecurrent.data?.influencers[0]?.id || '',
            );
          }
        }
      },
      onError: () => {
        notifyWarning(t('global.errorCampaign'));
        navigate(ROUTES.CAMPAIGNS_RECURRENT);
      },
    },
  );
  const { isInfluentia } = usePermissions();
  const { notifySaveSuccess, notifySaveError } = useNotification();
  const [deleteCampaign] = useMutation(DELETE_CAMPAIGN);

  const deleteCampaignDB = () => {
    deleteCampaign({
      variables: {
        campaignId,
        recurrent: true,
      },
      context: {
        headers: {
          'x-apollo-operation-name': 'DeleteCampaign',
        },
      },
      onCompleted: () => {
        notifySaveSuccess();
        navigate(ROUTES.CAMPAIGNS_RECURRENT);
      },
      onError: () => {
        notifySaveError();
      },
    });
  };
  return (
    <RefetchLateral.Provider
      value={{
        refetchLateral: refetch,
        refetchTabs,
        campaignId: campaignIdState,
        influencerId: influencerIdState || '',
        brandId: campaignInfo?.brand_id || '',
      }}
    >
      <div className="flex flex-row gap-x-5">
        <Confirm
          open={open}
          onConfirm={() => deleteCampaignDB()}
          setOpen={setOpen}
          type="delete"
          title={t('global.deleteTitle')}
          subtitle={t('global.deleteSubtitle')}
          confirmText={t('global.delete')}
          cancelText={t('global.cancel')}
        />
        <div
          className={`w-full flex flex-col gap-y-5 ${location !== 'general' && 'lg:max-w-[calc(100%-420px)]'}`}
        >
          <Breadcrumbs
            icon={<CampaignRecurrent />}
            breadcrumbs={[
              {
                text: t('campaignsRecurrent.title'),
                link: ROUTES.CAMPAIGNS_RECURRENT,
              },
              {
                text: campaignInfo?.name || '',
                link: replaceIds({
                  url: ROUTES.SINGLE_CAMPAIGN_RECURRENT_SETTINGS_INFLUENCER,
                  params: [
                    {
                      influencerId:
                        influencerIdState ||
                        campaignInfo?.influencers[0]?.id ||
                        '',
                    },
                    {
                      campaignId: campaignIdState!,
                    },
                  ],
                }),
              },
            ]}
          />
          <div>
            <SingleCampaignRecurrentTabs
              campaignId={campaignIdState!}
              idState={idState!}
              influencerId={influencerIdState || ''}
              influencers={campaignInfo?.influencers || []}
              brandName={campaignInfo?.brand_name!}
              brandProfilePicture={campaignInfo?.brand_profile_picture}
              brandId={campaignInfo?.brand_id!}
            />
          </div>
          {/* Tab Component from Route File */}
          <Outlet
            context={[campaignId, influencerIdState, campaignInfo?.brand_id]}
          />
        </div>
        <div className="">
          <div
            className={`flex items-end justify-end ${location === 'settings' && isInfluentia() ? '' : 'hidden'}`}
          >
            <Button
              text={t('singleCampaign.delete')}
              style="dark-blue"
              customClassnames="!px-2 !text-light-blue hover:bg-red-status hover:!text-white group"
              iconLeft={
                <TrashIcon className="text-red-status group-hover:text-white" />
              }
              onClick={() => setOpen(true)}
            />
          </div>
          <div
            className={`mt-12 lg:block hidden ${location !== 'general' && 'lg:w-[420px]'}`}
          >
            {campaignInfo &&
              influencerIdState &&
              campaignIdState &&
              location !== 'general' && (
                <div className="mt-12 lg:block hidden lg:w-[420px]">
                  <SingleCampaignInfo
                    recurrent
                    campaignInfo={{ ...campaignInfo }}
                    influencerInfo={{ ...singleCampaignInfo! }}
                    refetch={refetch}
                    isBrand={
                      idState !== undefined && idState !== influencerIdState
                    }
                    refetchTabs={refetchTabs}
                    influencerId={influencerIdState}
                    campaignId={campaignIdState}
                    location={location}
                  />
                </div>
              )}
          </div>
        </div>
      </div>
    </RefetchLateral.Provider>
  );
}

export default SingleCampaignRecurrent;
