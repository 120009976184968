import React from 'react';
import { useTranslation } from 'react-i18next';

import { MoneyCashIcon } from '../../../assets';
import { formatNumberMoney } from '../../../utils/numberFormatter';

type CampaignPriceProps = {
  price: number;
  moneyType?: string;
};

export const CampaignPrice = ({ price, moneyType }: CampaignPriceProps) => {
  const { t } = useTranslation('common');

  return (
    <div className="flex flex-row gap-[6px]">
      <div className="rounded-full bg-white flex justify-center items-center size-[28px] text-influentia-black">
        <MoneyCashIcon className="size-[16px]" />
      </div>
      <div className="flex flex-col gap-[5px]">
        <span className="text-influentia-light-blue font-medium text-[10px]">
          {t('singleCampaign.campaignPrice')}
        </span>
        <span className="font-medium text-sm text-white">
          {formatNumberMoney(price, undefined, moneyType)}
        </span>
      </div>
    </div>
  );
};
