import type { ReactNode } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';

import { BreadcrumbSeparator } from '../../assets';

type SingleBreadcrumb = {
  text: string;
  link?: string;
};

type ArrayOneOrMore<T> = {
  0: T;
} & Array<T>;

type BreadcrumbsProps = {
  icon: ReactNode;
  breadcrumbs: ArrayOneOrMore<SingleBreadcrumb>;
};

export const Breadcrumbs = ({ icon, breadcrumbs }: BreadcrumbsProps) => {
  return (
    <div className="flex flex-row gap-x-4 items-center">
      <div className="flex flex-row gap-x-2 items-center">
        {breadcrumbs[0].link ? (
          <Link
            to={breadcrumbs[0].link}
            className="text-white hover:text-influentia-light-blue flex flex-row gap-x-2 items-center"
          >
            {icon}
            <h2 className="font-[600] text-xl">{breadcrumbs[0].text}</h2>
          </Link>
        ) : (
          <div className="text-white flex flex-row gap-x-2 items-center">
            {icon}
            <h2 className="font-[600] text-xl">{breadcrumbs[0].text}</h2>
          </div>
        )}
      </div>
      {breadcrumbs.slice(1, breadcrumbs.length).map((b) => (
        <React.Fragment key={b.text + Math.random()}>
          <BreadcrumbSeparator />
          {b.link ? (
            <Link
              to={b.link}
              className="text-white hover:text-influentia-light-blue flex flex-row gap-x-2 items-center"
            >
              <h2 className="font-[600] text-xl">{b.text}</h2>
            </Link>
          ) : (
            <div className="text-white">
              <h2 className="font-[600] text-xl">{b.text}</h2>
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};
