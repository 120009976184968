import { useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import { useOutletContext } from 'react-router-dom';

import {
  CREATE_METRIC,
  DELETE_METRIC,
} from '../../../graphql/metrics/mutation';
import { GET_METRIC_REPORT } from '../../../graphql/metrics/query';
import { useNotification } from '../../../hooks/useNotification';
import { usePermissions } from '../../../hooks/usePermissions';
import FileUploader from '../../common/FileUploader';
import { MetricReport } from '../metrics/MetricReport';

export const SingleCampaignMetricsTab = () => {
  const [campaignId]: [string] = useOutletContext();
  const [createMetric, { loading }] = useMutation(CREATE_METRIC);
  const [deleteMetric] = useMutation(DELETE_METRIC);
  const { notifySaveSuccess, notifySaveError } = useNotification();
  const [metricReport, setMetricReport] = useState<any>(null);

  const { refetch } = useQuery(GET_METRIC_REPORT, {
    variables: {
      campaignId,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setMetricReport(data.metricsReport.data);
    },
  });
  const handleFile = (file: File) => {
    createMetric({
      variables: {
        campaignId,
        type: 'report',
        file,
      },
      context: {
        headers: {
          'content-type': 'multipart/form-data',
          'x-apollo-operation-name': 'createMetric',
        },
      },
      onCompleted: () => {
        notifySaveSuccess();
        refetch();
      },
      onError: () => {
        notifySaveError();
      },
    });
  };

  const deleteFileHandler = () => {
    deleteMetric({
      variables: {
        metricId: metricReport.id,
      },
      onCompleted: () => {
        notifySaveSuccess();
        refetch();
      },
      onError: () => {
        notifySaveError();
      },
    });
  };
  const { isBrand } = usePermissions();
  return (
    <div>
      <div
        className={`${((metricReport && metricReport.file !== null) || isBrand()) && 'hidden'} `}
      >
        <FileUploader
          submitFile={handleFile}
          fileType="Report"
          loading={loading}
        />
      </div>
      {metricReport && metricReport.file && (
        <div>
          <MetricReport
            fileUrl={metricReport.file.url}
            name={metricReport.file.name}
            deleteContent={deleteFileHandler}
          ></MetricReport>
        </div>
      )}
      {!metricReport && isBrand() && (
        <div>
          <MetricReport fileUrl={undefined} name={''}></MetricReport>
        </div>
      )}
    </div>
  );
};
