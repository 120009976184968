import type { ReactNode } from 'react';
import { useEffect, useRef } from 'react';

import { CloseIcon } from '../../assets';

type PopupLayoutProps = {
  closeModal: () => void;
  children: ReactNode;
  title: string;
  icon: ReactNode;
};

export const PopupLayout = ({
  closeModal,
  children,
  title,
  icon,
}: PopupLayoutProps) => {
  const modalRef = useRef(null);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (
        modalRef.current &&
        !(modalRef.current as any).contains(event.target) &&
        event.target.id === 'openedModal'
      ) {
        closeModal();
      }
    }
    // Bind the event listener
    document.addEventListener('click', handleClickOutside);
    document.body.className = 'overflow-hidden bg-influentia-black';
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('click', handleClickOutside);
      document.body.className = 'overflow-auto bg-influentia-black';
    };
  }, [modalRef]);

  return (
    <div
      id="openedModal"
      className="fixed left-0 top-0 z-50 size-full bg-gray-hover/50"
    >
      <div
        ref={modalRef}
        className="absolute inset-0 m-auto h-fit rounded-[20px] bg-dark-blue-popups max-w-[850px] p-8 shadow-xl"
      >
        <div className="flex flex-col w-full">
          <div className="flex flex-row justify-between w-full">
            <div className="flex flex-row gap-x-2 w-full items-center">
              {icon}
              <p>{title}</p>
            </div>
            <div className="flex w-full justify-end">
              <button className="rounded-md" onClick={closeModal}>
                <CloseIcon />
              </button>
            </div>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};
