import 'dayjs/locale/es';

import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { HomeIcon } from '../assets';
import { DashboardInfoCard } from '../components/campaigns/DashboardInfoCard';
import { SmallCalendar } from '../components/campaigns/SmallCalendar';
import { Breadcrumbs } from '../components/common/Breadcrumbs';
import { LinkButton } from '../components/common/LinkButton';
import { replaceIds, ROUTES } from '../config/routes/config';
import {
  GET_DASHBOARD_INFO,
  GET_DASHBOARD_INFO_PENDING,
} from '../graphql/campaign/query';
import { usePermissions } from '../hooks/usePermissions';
import type {
  ResponseDashboardInfo,
  ResponseDashboardInfoPending,
  SingleDashboardInfo,
  SingleDateDashboardInfo,
  SingleFutureDate,
} from '../types';
import { formatNumberMoney } from '../utils/numberFormatter';

dayjs.extend(localizedFormat);
dayjs.locale('es');

function Dashboard() {
  const { t } = useTranslation('common');
  const { isInfluencer } = usePermissions();
  const [campaigns, setCampaigns] = useState<SingleDashboardInfo[]>([]);
  const [campaignsPending, setCampaignsPending] = useState<
    SingleDashboardInfo[]
  >([]);
  const [dates, setDates] = useState<SingleDateDashboardInfo[]>([]);
  const [futureDates, setFutureDates] = useState<SingleFutureDate[]>([]);
  const { isInfluentia } = usePermissions();
  useQuery<ResponseDashboardInfo>(GET_DASHBOARD_INFO, {
    onCompleted: (data) => {
      setCampaigns(data.dashboardInfo.data.campaigns.slice(0, 4));
      const datesArray = data.dashboardInfo.data.dates;
      setDates(datesArray);
      const future: SingleFutureDate[] = [];
      datesArray.forEach((d) => {
        console.log(dayjs(d.date));
        if (dayjs(d.date).isBefore(dayjs().startOf('day'))) return;
        const i = future.findIndex((f) => f.date === d.date);
        const action = t('dashboard.publicationListElement', { ...d });
        if (i > -1) future[i].actions.push(action);
        else {
          future.push({
            date: d.date,
            actions: [action],
          });
        }
      });
      future.sort((a, b) => dayjs(a.date).diff(dayjs(b.date)));
      setFutureDates([...future]);
    },
  });
  console.log(dayjs().startOf('day'));
  useQuery<ResponseDashboardInfoPending>(GET_DASHBOARD_INFO_PENDING, {
    skip: !isInfluentia(),
    onCompleted: (data) => {
      setCampaignsPending(data.dashboardInfoPending.data.slice(0, 4));
    },
  });

  return (
    <div className="w-full flex flex-col gap-y-5">
      <Breadcrumbs
        icon={<HomeIcon />}
        breadcrumbs={[
          {
            text: t('dashboard.dashboardTitle'),
          },
        ]}
      />
      <div className="flex lg:flex-row flex-col lg:gap-x-5 lg:gap-y-0 gap-y-5">
        <div className="flex flex-col gap-y-3 lg:w-1/2">
          <p className="text-base uppercase">
            {t('dashboard.activeCampaigns')}
          </p>
          <div className="grid 2xl:grid-cols-4 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 sm:grid-cols-2 gap-2.5">
            {campaigns.map((c) => (
              <DashboardInfoCard
                link={replaceIds({
                  url: ROUTES.SINGLE_CAMPAIGN_GENERAL,
                  params: [
                    {
                      influencerId: c?.users[0]?.id || '',
                    },
                    {
                      campaignId: c?.id,
                    },
                  ],
                })}
                key={c.name + Math.random()}
                status={c.status}
                campaignName={c.name}
                image={
                  isInfluencer() ? c.brand.profile_picture || '' : undefined
                }
                price={formatNumberMoney(c.price, 0)}
                influencerView={isInfluencer()}
                userCount={!isInfluencer() ? c.users.length : undefined}
                userImages={
                  !isInfluencer()
                    ? c.users.map((u) => u.profile_picture).slice(0, 4)
                    : undefined
                }
              />
            ))}
          </div>
          <div className="w-full flex items-center justify-center">
            <LinkButton
              text={t('dashboard.campaignsLink')}
              to={ROUTES.CAMPAIGNS}
            />
          </div>
          <div
            className={`flex flex-col gap-y-3 ${!isInfluentia() && 'hidden'}`}
          >
            <p className="text-base uppercase">
              {t('dashboard.pendingCampaigns')}
            </p>
            <div className="grid 2xl:grid-cols-4 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 sm:grid-cols-2 gap-2.5">
              {campaignsPending.map((c) => (
                <DashboardInfoCard
                  link={replaceIds({
                    url: ROUTES.SINGLE_CAMPAIGN_GENERAL,
                    params: [
                      {
                        influencerId: c?.users[0]?.id || '',
                      },
                      {
                        campaignId: c?.id,
                      },
                    ],
                  })}
                  key={c.name + Math.random()}
                  status={c.status}
                  campaignName={c.name}
                  image={
                    isInfluencer() ? c.brand.profile_picture || '' : undefined
                  }
                  price={formatNumberMoney(c.price, 0)}
                  influencerView={isInfluencer()}
                  userCount={!isInfluencer() ? c.users.length : undefined}
                  userImages={
                    !isInfluencer()
                      ? c.users.map((u) => u.profile_picture).slice(0, 4)
                      : undefined
                  }
                />
              ))}
            </div>
            <div className="w-full flex items-center justify-center">
              <LinkButton
                text={t('dashboard.campaignsLinkPending')}
                to={`${ROUTES.CAMPAIGNS}?status=pending`}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-y-3 lg:w-1/2">
          <p className="text-base uppercase">{t('dashboard.thisMonth')}</p>
          <div className="bg-dark-blue rounded-md p-4">
            <div className="flex md:flex-row flex-col md:divide-x-2 md:divide-y-0 divide-y-2">
              <div className="md:w-1/2">
                <SmallCalendar dates={dates} />
              </div>
              <div className="md:w-1/2 flex flex-col gap-y-3 md:pl-4 md:pt-0 pt-4 max-h-[380px] pr-2 overflow-y-auto customScrollDashboard">
                {futureDates.map((f) => (
                  <div
                    key={f.date + Math.random()}
                    className="flex flex-col border-b border-1 border-white pb-2 gap-y-2"
                  >
                    <div className="text-dark-blue bg-influentia-light-blue rounded-full w-max px-3 py-1 capitalize font-[500] text-sm">
                      {dayjs(f.date).locale('es').format('DD MMMM')}
                    </div>
                    <div className="text-white text-sm divide-y divide-dark-blue-popups">
                      {f.actions.map((a) => (
                        <p key={a + Math.random()} className="py-1">
                          {a}
                        </p>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="w-full flex items-center justify-center">
            <LinkButton
              text={t('dashboard.calendarLink')}
              to={ROUTES.CALENDAR}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
