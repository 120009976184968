import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { useOutletContext } from 'react-router-dom';

import { GET_INFLUENCER_CAMPAIGN_GENERAL } from '../../../graphql/campaign/query';
import { usePermissions } from '../../../hooks/usePermissions';
import type {
  InfluencerCampaignGeneral,
  ResInfluencerCampaignGeneral,
} from '../../../types';
import { getNoteType, getSegmentBeforeSecondUUID } from '../../../utils/utils';
import { ActionDropDown } from '../content/ActionDropDown';

export const SingleCampaignContentTab = () => {
  const [campaignId, influencerId]: [string, string] = useOutletContext();
  const [generalInfo, setGeneralInfo] = useState<InfluencerCampaignGeneral>();
  const location = getSegmentBeforeSecondUUID(window.location.pathname) || '';
  const { isInfluencer, isBrand, isInfluentia } = usePermissions();

  const { refetch } = useQuery<ResInfluencerCampaignGeneral>(
    GET_INFLUENCER_CAMPAIGN_GENERAL,
    {
      variables: {
        campaignId,
        influencerId,
        type: getNoteType(location, isInfluencer, isBrand, isInfluentia),
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        setGeneralInfo(data.influencerCampaignGeneral.data);
      },
    },
  );
  /* | 'upload_pending' | 'internal_validation' | 'external_validation' |
  'modification_pending' | 'validated' | 'old'; */
  return (
    <div className="flex flex-row gap-5">
      <div className="w-full">
        {generalInfo &&
          generalInfo.pieces &&
          generalInfo.pieces.map((piece, index) => (
            <ActionDropDown
              key={piece.id + index}
              indexAction={index + 1}
              refetch={refetch}
              piece={piece}
              total={generalInfo.pieces.length}
            />
          ))}
      </div>
    </div>
  );
};
