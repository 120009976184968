import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TrashIcon } from '../../../assets';
import { Confirm } from '../../../context/confirm/confirm';
import { TextInput } from '../../inputs/TextInput';
import PlatformIcons from './influencer/PlatformsIcons';

export default function DisclaimerPlatforms({
  disclaimer,
  setDisclaimerPlatforms,
  setDirty,
  deleteDisclaimer,
}: {
  disclaimer: {
    id: string;
    disclaimer: string;
    platform: any;
  };
  setDisclaimerPlatforms: any;
  setDirty: any;
  deleteDisclaimer: any;
}) {
  const platformsString = disclaimer.platform ? disclaimer.platform : '';
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);
  const [platforms, setPlatforms] = useState(
    platformsString === ''
      ? []
      : platformsString.split(',').map((item: any) => item.trim()),
  );
  const [textDisclaimer, setTextDisclaimer] = useState(disclaimer.disclaimer);

  const handleUpdate = (newPlatforms: string[]) => {
    setDisclaimerPlatforms((prevPlatforms: any) => {
      const updatePlatforms = prevPlatforms.map((r: any) => {
        if (r.id === disclaimer.id) {
          return {
            ...r,
            platform: newPlatforms.join(','),
            disclaimer: textDisclaimer,
          };
        }
        return r;
      });
      return updatePlatforms;
    });
    setDirty(true);
  };
  const unSelectPlatform = (platform: string) => () => {
    setPlatforms((prevPlatforms: any) => {
      const updatedPlatforms = prevPlatforms.includes(platform)
        ? prevPlatforms.filter((p: any) => p !== platform)
        : [...prevPlatforms, platform];
      handleUpdate(updatedPlatforms);
      return updatedPlatforms;
    });
    setDirty(true);
  };
  return (
    <div className="flex flex-row gap-3 items-center">
      <Confirm
        open={open}
        onConfirm={() => deleteDisclaimer()}
        setOpen={setOpen}
        type="delete"
        title={t('global.deleteTitle')}
        subtitle={t('global.deleteSubtitle')}
        confirmText={t('global.delete')}
        cancelText={t('global.cancel')}
      />

      <div className="w-full">
        <div className="flex flex-row gap-2.5">
          <TextInput
            maxLength={6000}
            selectedValue={disclaimer.disclaimer}
            type="text"
            onSelectChange={(value: any) => {
              setTextDisclaimer(String(value));
              setDirty(true);
            }}
            onBlur={() => handleUpdate(platforms)}
            extraClassname="!h-[34px]"
          />
          <PlatformIcons
            platforms={platforms}
            unSelectPlatform={unSelectPlatform}
          />
        </div>
      </div>
      <div className="flex items-center justify-center w-px h-4 bg-dark-blue"></div>
      <div
        className={`flex items-center justify-center cursor-pointer`}
        onClick={() => setOpen(true)}
      >
        <TrashIcon className="size-[15px] text-red-status" />
      </div>
    </div>
  );
}
