import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FilterIcon } from '../../assets';
import {
  CAMPAIGN_RECURRENT_STATUS,
  CAMPAIGN_STATUS,
  CAMPAIGN_USER_STATUS,
  PLATFORMS,
} from '../../config/constants';
import { usePermissions } from '../../hooks/usePermissions';
import type { CampaignFilters } from '../../types';
import { Button } from '../common/Button';
import { GenericDropdown } from '../inputs/GenericDropdown';
import { MoneyInput } from '../inputs/MoneyInput';
import { UserDropdown } from '../inputs/UserDropdown';
import { FiltersPopover } from './FiltersPopover';

type CampaignFiltersProps = {
  setFilters: (val: CampaignFilters) => void;
  filters: CampaignFilters;
  recurrent?: boolean;
};

export const CampaignFiltersPopover = ({
  setFilters,
  filters,
  recurrent = false,
}: CampaignFiltersProps) => {
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);
  const { ...methods } = useForm<CampaignFilters>({
    defaultValues: { ...filters },
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  useEffect(() => {
    reset(filters);
  }, [filters]);

  const handleSearch = (data: CampaignFilters) => {
    setFilters({
      ...data,
      minPrice: data.minPrice !== undefined ? Number(data.minPrice) : undefined,
      maxPrice: data.maxPrice !== undefined ? Number(data.maxPrice) : undefined,
    });
    setOpen(false);
  };

  const onReset = () => {
    setFilters({});
    reset({});
  };
  const { isInfluencer, isBrand, isInfluentia } = usePermissions();
  let options = [];

  if (isInfluencer()) {
    options = CAMPAIGN_USER_STATUS.filter(
      (status) => status !== 'not_send',
    ).map((s) => ({
      value: s,
      label: t(`influencerStatus.${s}`),
    }));
  } else if (isBrand()) {
    options = CAMPAIGN_STATUS.filter((status) =>
      ['active', 'finished', 'canceled'].includes(status),
    ).map((s) => ({
      value: s,
      label: t(`campaignStatus.${s}`),
    }));
  } else if (recurrent) {
    options = CAMPAIGN_RECURRENT_STATUS.map((s) => ({
      value: s,
      label: t(`campaignStatus.${s}`),
    }));
  } else {
    options = CAMPAIGN_STATUS.map((s) => ({
      value: s,
      label: t(`campaignStatus.${s}`),
    }));
  }

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        style="light-blue"
        text=""
        iconLeft={<FilterIcon />}
      />
      {open && (
        <FiltersPopover
          title={t('campaigns.filtersTitle')}
          closeModal={() => setOpen(false)}
          resetFilter={onReset}
          onSubmit={handleSubmit(handleSearch)}
        >
          <FormProvider {...methods}>
            <form
              className="flex flex-col gap-y-5"
              onSubmit={handleSubmit(handleSearch)}
            >
              <GenericDropdown
                isMulti
                control={control}
                name="status"
                label={t('campaigns.statusFilter')}
                placeholder={t('campaigns.statusFilterPlaceholder')}
                options={options}
              />
              {isInfluentia() && (
                <>
                  <UserDropdown
                    type="influencer"
                    label={t('campaigns.influencerLabelFilter')}
                    name="influencers"
                    multi={true}
                    control={control}
                    searchable={true}
                    storeFullOption={true}
                  />
                  <UserDropdown
                    multi={true}
                    control={control}
                    storeFullOption={true}
                    searchable={true}
                    name="client"
                    label={t('campaigns.createClient')}
                    placeholder={t('campaigns.createClientPlaceholder')}
                    type="brand"
                  />
                </>
              )}
              <GenericDropdown
                isMulti
                control={control}
                name="platforms"
                label={t('campaigns.platformsFilter')}
                placeholder={t('campaigns.platformsFilterPlaceholder')}
                options={[
                  ...PLATFORMS.map((p) => ({
                    value: p,
                    label: p,
                  })),
                ]}
              />
              <div className="flex flex-row gap-x-2">
                <MoneyInput
                  label={t('campaigns.princeRangeMinFilter')}
                  placeholder={t('campaigns.princeRangeMinFilterPlaceholder')}
                  control={control}
                  name={'minPrice'}
                />
                <MoneyInput
                  label={t('campaigns.princeRangeMaxFilter')}
                  placeholder={t('campaigns.princeRangeMaxFilterPlaceholder')}
                  control={control}
                  name={'maxPrice'}
                />
              </div>
            </form>
          </FormProvider>
        </FiltersPopover>
      )}
    </>
  );
};
