import React from 'react';

import { DefaultInfluencerIcon, DefaultInfluentiaIcon } from '../../assets';

export const UsersImages = ({
  userCount,
  userImages,
  size = 'size-[38px]',
  influencers = true,
}: {
  userCount: number;
  userImages: string[];
  size?:
    | 'size-[38px]'
    | 'size-[24px]'
    | 'size-[35px]'
    | 'size-[20px]'
    | 'size-[50px]';
  influencers?: boolean;
}) => {
  if (userCount > 4)
    return (
      <div className="flex flex-row gap-x-[-10px]">
        {userImages.slice(0, 3).map((image, index) => (
          <React.Fragment key={image + index + Math.random()}>
            {image ? (
              <img
                src={`${process.env.REACT_APP_AWS_PUBLIC_ENDPOINT}/${image}`}
                className={`${size} rounded-full object-cover ${index > 0 && '-ml-3'}`}
              />
            ) : (
              <div
                className={`${size} rounded-full bg-influentia-light-blue ${index > 0 && '-ml-3'}`}
              >
                {influencers ? (
                  <DefaultInfluencerIcon className="size-[inherit]" />
                ) : (
                  <DefaultInfluentiaIcon className="size-[inherit]" />
                )}
              </div>
            )}
          </React.Fragment>
        ))}
        <div
          className={`${size} rounded-full bg-influentia-light-blue -blue -ml-3 flex items-center justify-center font-[500]`}
        >
          +{userCount - userImages.slice(0, 3).length}
        </div>
      </div>
    );

  return (
    <div className="flex flex-row gap-x-[-10px]">
      {userImages.map((image, index) => (
        <React.Fragment key={image + index + Math.random()}>
          {image ? (
            <img
              src={`${process.env.REACT_APP_AWS_PUBLIC_ENDPOINT}/${image}`}
              className={`${size} rounded-full object-cover ${index > 0 && '-ml-3'}`}
            />
          ) : (
            <div
              className={`${size} rounded-full bg-influentia-black -blue ${index > 0 && '-ml-3'}`}
            >
              {influencers ? (
                <DefaultInfluencerIcon className="size-[inherit]" />
              ) : (
                <DefaultInfluentiaIcon className="size-[inherit]" />
              )}
            </div>
          )}
        </React.Fragment>
      ))}
      {userImages.length === 0 && (
        <div className={`${size} rounded-full`}>
          {influencers ? (
            <DefaultInfluencerIcon className="size-[inherit]" />
          ) : (
            <DefaultInfluentiaIcon className="size-[inherit]" />
          )}
        </div>
      )}
    </div>
  );
};
