import { useMemo } from 'react';

import { SortIcon, SortIconASC, SortIconDESC } from '../../assets';

type Props = {
  onClick?: () => void;
  title: string;
  sortable?: boolean;
  sorting?: string;
  accessor: string;
  sortingDirection?: string;
  first?: boolean;
  last?: boolean;
};

const DTHeader = ({
  onClick,
  title,
  sortable,
  sorting,
  accessor,
  sortingDirection,
  first,
  last,
}: Props) => {
  const icon = useMemo(() => {
    if (sorting === accessor) {
      if (sortingDirection?.toLowerCase() === 'asc') return <SortIconASC />;
      if (sortingDirection?.toLowerCase() === 'desc') return <SortIconDESC />;
    }
    return <SortIcon />;
  }, [accessor, sorting, sortingDirection]);

  return (
    <div
      className={`flex gap-2 items-center ${first && 'justify-start'} ${last && 'justify-end'} ${!first && !last && 'justify-center'}`}
    >
      <span className="text-base font-medium">{title}</span>
      {sortable && <button onClick={onClick}>{icon}</button>}
    </div>
  );
};

export default DTHeader;
