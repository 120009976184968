import { useMutation } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { validate as validateUuid } from 'uuid';

// eslint-disable-next-line import/no-extraneous-dependencies
import { PublishedContentImage } from '../../../assets';
import { ROUTES } from '../../../config/routes/config';
import { UPDATE_PIECE } from '../../../graphql/campaign/mutation';
import { useNotification } from '../../../hooks/useNotification';
import { AuthLoader } from '../../auth/AuthLoader';
import { Button } from '../../common/Button';
import { Error } from '../../common/Error';
import { TextInput } from '../../inputs/TextInput';

export function ContentConfirm() {
  const [searchParams] = useSearchParams();
  const [id] = useState(searchParams.get('piece_id'));
  const { t } = useTranslation('common');
  const [updatePiece, { loading }] = useMutation(UPDATE_PIECE);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(true);
  const hasRun = useRef(false); // UseRef to ensure the effect runs only once
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (hasRun.current) return; // Prevent the effect from running more than once

    if (validateUuid(String(id))) {
      updatePiece({
        variables: {
          pieceId: id,
          key: 'status',
          value: 'published',
        },
        onCompleted: () => {
          setSuccess(true);
          setError('');
          hasRun.current = true; // Mark the effect as having run
        },
        onError: (errorapi: any) => {
          setSuccess(false);
          if (
            errorapi.message === 'Piece not exist' ||
            errorapi.message === 'Piece not updated'
          )
            setError(t(`contentUpload.${errorapi.message}`));
        },
      });
    }
  }, [id]);
  const navigate = useNavigate();
  const { notifySaveSuccess } = useNotification();
  const handleSave = (data: any) => {
    updatePiece({
      variables: {
        pieceId: id,
        key: 'link',
        value: data.link.startsWith('https://')
          ? data.link
          : `https://${data.link}`,
      },
      onCompleted: () => {
        reset();
        navigate(ROUTES.CAMPAIGNS);
        notifySaveSuccess();
      },
      onError: () => {
        setError(t('contentUpload.error'));
      },
    });
  };
  return (
    <div className="flex h-full items-center justify-center md:mt-52 mt-0">
      <div className="flex flex-col items-center justify-center">
        <div className="mb-5">
          <PublishedContentImage />
        </div>
        <div
          className={`flex flex-col items-center justify-center max-w-[490px] text-center ${success ? 'block' : 'hidden'}`}
        >
          <p className="text-2xl font-bold">{t('contentUpload.title')}</p>
          <span className="text-[16px] mb-5 mt-2.5">
            {t('contentUpload.subtitle')}
          </span>
          <form
            className="flex flex-col gap-5"
            onSubmit={handleSubmit(handleSave)}
          >
            <TextInput
              register={register}
              registerName="link"
              placeholder={t('contentUpload.placeholder')}
              extraClassname="min-w-[480px]"
              type="text"
              errors={errors.link}
              labelClassname="text-white"
              validation={{
                required: t('contentUpload.required'),
              }}
            />
            <div className="flex items-center justify-center">
              <Button
                type="submit"
                style="light-blue"
                text={t('contentUpload.button')}
              />
            </div>
          </form>
        </div>
        <div className={`mb-5 mt-2.5 ${error !== '' ? 'block' : 'hidden'}`}>
          <Error error={error} />
        </div>
        <div
          className={`mb-5 mt-2.5 ${error === '' && !success ? 'block' : 'hidden'}`}
        >
          <Error error={t('contentUpload.errorNoApi')} />
        </div>
      </div>
      {loading && <AuthLoader />}
    </div>
  );
}
