import { gql } from '@apollo/client';

export const HAS_UNREAD_NOTIFICATIONS = gql`
  query HasUnreadedNotifications {
    hasUnreadedNotifications {
      status
      code
      data
      error
    }
  }
`;

export const GET_ALL_NOTIFICATIONS = gql`
  query Notifications {
    notifications {
      status
      code
      error
      data {
        id
        type
        object_id
        variables
        link
        createdAt
        updatedAt
        readAt
      }
    }
  }
`;
