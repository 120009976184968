import {
  BlogIcon,
  InstagramIcon,
  SubstackIcon,
  TelegramIcon,
  TiktokIcon,
  TwitterIcon,
  YoutubeIcon,
} from '../../assets';
import type { PlatformType } from '../../types';

type SocialNetworkImageProps = {
  socialNetwork: PlatformType;
  color?: string;
  size?: string;
};

export const SocialNetworkImage = ({
  socialNetwork,
  color = 'text-white',
  size = 'size-[14px]',
}: SocialNetworkImageProps) => {
  const className = `${color} ${size}`;
  switch (socialNetwork) {
    case 'Youtube':
      return <YoutubeIcon className={className} />;

    case 'Instagram':
      return <InstagramIcon className={className} />;

    case 'Substack':
      return <SubstackIcon className={className} />;

    case 'TikTok':
      return <TiktokIcon className={className} />;

    case 'Twitter':
      return <TwitterIcon className={className} />;
    case 'Blog':
      return <BlogIcon className={className} />;
    case 'Telegram':
      return <TelegramIcon className={className} />;

    default:
      return <></>;
  }
};
