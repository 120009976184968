// eslint-disable-next-line import/no-extraneous-dependencies

import { useQuery } from '@apollo/client';
import { datadogRum } from '@datadog/browser-rum';
import { Outlet } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ToastContainer } from 'react-toastify';
import { useSetRecoilState } from 'recoil';

import { Header } from '../components/header/Header';
import { GET_PROFILE } from '../graphql/user/query';
import { userAtom } from '../states/atoms';
import type { ResponseProfile } from '../types';
import { changeLanguage, usePushSubscription } from '../utils/utils';

const DashboardLayout = () => {
  const setUser = useSetRecoilState(userAtom);
  const { subscribeUserToPush } = usePushSubscription();

  useQuery<ResponseProfile>(GET_PROFILE, {
    onCompleted: (data) => {
      setUser(data.profile.data);
      changeLanguage(
        data.profile.data.language,
        data.profile.data.language_automatic,
      );
      subscribeUserToPush();
      datadogRum.setUser({
        id: data.profile.data.id || '',
        email: data.profile.data.email || '',
        name: data.profile.data.name || '',
      });
    },
  });

  return (
    <div
      id="layout"
      className="bg-influentia-black min-h-screen text-white p-5"
    >
      <Header />

      <Outlet />
      <div>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </div>
  );
};

export default DashboardLayout;
