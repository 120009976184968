import { useMutation } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FileIcon, Link, TrashIcon } from '../../../assets';
import { Confirm } from '../../../context/confirm/confirm';
import { DELETE_RESOURCE } from '../../../graphql/campaign/mutation';
import { useNotification } from '../../../hooks/useNotification';
import { usePermissions } from '../../../hooks/usePermissions';
import { downloadDocumentClick } from '../../../utils/utils';

export function Resource({
  resource,
  refetch,
}: {
  resource: any;
  refetch: any;
}) {
  const { getAccessTokenSilently } = useAuth0();
  const { isInfluencer } = usePermissions();
  const fetchItems = async () => {
    if (resource.File && !resource.File.url) return;
    await downloadDocumentClick(
      resource.File.url,
      await getAccessTokenSilently(),
    );
  };
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);
  const { notifyDeleteOk, notifyDeleteError } = useNotification();
  const [deleteResourceDB] = useMutation(DELETE_RESOURCE);

  const deleteResource = () => {
    deleteResourceDB({
      variables: {
        resourceId: resource.id,
      },
      onCompleted: () => {
        refetch();
        notifyDeleteOk();
      },
      onError: () => {
        notifyDeleteError();
      },
    });
  };

  return (
    <div className="flex flex-col mr-5">
      <Confirm
        open={open}
        onConfirm={deleteResource}
        setOpen={setOpen}
        type="delete"
        title={t('global.deleteTitle')}
        subtitle={t('global.deleteSubtitle')}
        confirmText={t('global.delete')}
        cancelText={t('global.cancel')}
      />
      <a
        onClick={
          resource.File
            ? fetchItems
            : () => {
                if (resource.link) {
                  window.open(resource.link, '_blank');
                }
              }
        }
        target="_blank"
        rel="noreferrer noopener"
        className="cursor-pointer bg-white flex flex-col p-5 rounded items-center justify-center text-center gap-2.5 max-w-[115px] max-h-[102px]"
      >
        {resource.File && resource.File.name && (
          <>
            <FileIcon className={`size-[35px] text-influentia-black`} />
            <div className="text-influentia-black text-sm font-inter truncate max-w-[100px]">
              {resource.File.name}
            </div>
          </>
        )}
        {resource.link && (
          <>
            <Link className={`size-[35px] text-influentia-black`} />
            <div className="text-influentia-black text-sm font-inter truncate max-w-[100px]">
              {resource.linkName}
            </div>
          </>
        )}
      </a>
      {!isInfluencer() && (
        <div
          className={`flex cursor-pointer mt-1.5 flex-row gap-1.5`}
          onClick={() => setOpen(true)}
        >
          <TrashIcon className="size-[15px] text-red-status" />
          <span className="text-red-status text-[10px] font-medium">
            {t('singleCampaign.deleteResource')}
          </span>
        </div>
      )}
    </div>
  );
}
