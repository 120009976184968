import type { ColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { type TFunction } from 'i18next';

import type { InfluencersTable } from '../../../types';
import { SocialNetworkImage } from '../../images/SocialNetworkImage';
import { UsersImages } from '../../images/UserImages';
import DTBoldCell from '../DTBoldCell';
import { DTCell } from '../DTCell';
import DTHeader from '../DTHeader';

type GetInfluencersTableHeadersProps = {
  columnHelper: ColumnHelper<InfluencersTable>;
  handleChangeSortDirection: (id: string) => void;
  t: TFunction;
  extras: {};
};

export const getInfluencersTableHeaders = ({
  columnHelper,
  handleChangeSortDirection,
  t,
}: GetInfluencersTableHeadersProps) => [
  // columnHelper.accessor('select', {
  //   size: 30,
  //   minSize: 30,
  //   maxSize: 30,
  //   header: ({ table }) => (
  //     <input
  //       checked={table.getIsAllPageRowsSelected()}
  //       onChange={table.getToggleAllPageRowsSelectedHandler()}
  //       type="checkbox"
  //       name="select"
  //       id="select"
  //       className="table-checkbox-th flex size-[18px] items-center justify-center rounded-md border border-white bg-green focus:ring-0 focus:ring-transparent focus:ring-offset-0"
  //     />
  //   ),
  //   cell: ({ row }) => (
  //     <input
  //       checked={row.getIsSelected()}
  //       disabled={!row.getCanSelect()}
  //       onChange={row.getToggleSelectedHandler()}
  //       type="checkbox"
  //       name="select"
  //       id="select"
  //       className="flex size-[18px] items-center justify-center rounded-md border border-green bg-transparent text-green checked:bg-green focus:ring-0 focus:ring-transparent focus:ring-offset-0"
  //     />
  //   ),
  //   enableSorting: false,
  //   enableHiding: false,
  // }),
  columnHelper.accessor('name', {
    size: 150,
    minSize: 150,
    maxSize: 200,
    cell: (info) => {
      return (
        <DTBoldCell
          content={info.getValue()}
          id={info.cell.id}
          image={
            <UsersImages
              size="size-[38px]"
              userCount={1}
              userImages={[info.row.original.profile_picture || '']}
            />
          }
        ></DTBoldCell>
      );
    },
    header: ({ header, ...info }) => {
      return (
        <DTHeader
          sortable
          first
          onClick={() => handleChangeSortDirection(header.id)}
          title={t('influencers.header1')}
          sorting={(info as any).orderBy}
          sortingDirection={(info as any).orderDirection}
          accessor="name"
        />
      );
    },
  }),
  columnHelper.accessor('platforms', {
    size: 150,
    minSize: 150,
    maxSize: 200,
    header: ({ header }) => (
      <DTHeader
        onClick={() => handleChangeSortDirection(header.id)}
        title={t('influencers.header2')}
        accessor="platforms"
      />
    ),
    cell: (info) => (
      <div className="flex flex-row gap-x-1">
        {info.getValue().map((p) => (
          <SocialNetworkImage
            key={p.name + Math.random()}
            size="size-[30px]"
            socialNetwork={p.name}
          />
        ))}
      </div>
    ),
  }),
  columnHelper.accessor('email', {
    size: 150,
    minSize: 150,
    maxSize: 200,
    cell: (info) => <DTCell text={info.getValue()} />,
    header: ({ header, ...info }) => (
      <DTHeader
        onClick={() => handleChangeSortDirection(header.id)}
        title={t('influencers.header3')}
        accessor={'email'}
        sorting={(info as any).orderBy}
        sortingDirection={(info as any).orderDirection}
        sortable
      />
    ),
  }),
  columnHelper.accessor('createdAt', {
    size: 150,
    minSize: 150,
    maxSize: 200,
    header: ({ header, ...info }) => (
      <DTHeader
        sortable
        onClick={() => handleChangeSortDirection(header.id)}
        title={t('influencers.header4')}
        sorting={(info as any).orderBy}
        sortingDirection={(info as any).orderDirection}
        accessor="createdAt"
        last
      />
    ),
    cell: (info) => (
      <DTCell
        text={
          info.getValue()
            ? dayjs(new Date(parseInt(info.getValue(), 10))).format(
                'DD-MM-YYYY',
              )
            : ''
        }
      />
    ),
  }),
];
