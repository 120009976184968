import { InfluentiaLogoBig } from '../../assets';

export const AuthLoader = () => {
  return (
    <div className="fixed z-10 flex min-h-screen size-full bg-influentia-black items-center justify-center">
      <div className="relative">
        <div className="opacity-20">
          <InfluentiaLogoBig />
        </div>
        <div className="absolute top-0 left-0 animate-loading-icon overflow-hidden">
          <InfluentiaLogoBig />
        </div>
      </div>
    </div>
  );
};
