/* eslint-disable import/no-extraneous-dependencies */
import 'dayjs/locale/es';

import { useQuery } from '@apollo/client';
import type { DatesSetArg } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import FullCalendar from '@fullcalendar/react';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import i18next from 'i18next';
import type { ReactNode } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import {
  CampaignPublishIcon,
  CrossIconNoCircle,
  DefaultBrandIcon,
  DefaultInfluencerIcon,
  FileIcon,
  FilePlayIcon,
  OpenIcon,
} from '../assets';
import { StatusText } from '../components/common/StatusText';
import { SocialNetworkImage } from '../components/images/SocialNetworkImage';
import { GET_DATES_BY_CAMPAIGN } from '../graphql/campaign/query';
import { useNotification } from '../hooks/useNotification';
import { usePermissions } from '../hooks/usePermissions';
import { formatDateSimple } from '../utils/numberFormatter';

dayjs.extend(localizedFormat);
dayjs.locale('es');

const StyleWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <style>{`
        .fc-dayGridWeek-view {
          height: 100% !important;
        }
        .fc th {
          text-align: start !important;
        }
        .fc-scrollgrid {
          height: 100% !important;
        }
        .fc-daygrid-body {
          height: 100% !important;
        }
        .fc-scrollgrid {
          background: #213A51 !important;
        }
        .fc-dayGridMonth-button {
          min-width: 77px !important;
        }
        .fc-button-group {
          padding-bottom: 5px !important;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .fc-button {
          font-size: 14px !important;
          padding-top: 0px !important
          max-height: 25px !important;
          background: #213A51;
        }
        .fc-button-active {
          background: #00E7FF !important;
          color: #213A51 !important;
        }

        .fc-day {
          border-color: #16162C !important;
        }
        .fc-header-toolbar {
          display: flex;
          margin-bottom: 0px !important;
          font-family: 'Montserrat', sans-serif !important;
          font-size: 18px !important;
          font-style: normal !important;
          font-weight: 500 !important;
          line-height: normal !important;
          text-transform: capitalize;
        }
        .fc-toolbar-chunk {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .fc-next-button {
          width: 32px !important;
          height: 32px !important;
          border: 0px !important;
          padding: 0px !important;
          display: flex !important;
          justify-content: center !important;
          align-items: center !important;
          box-shadow: none !important;
        }
        .fc-prev-button {
          width: 32px !important;
          height: 32px !important;
          border: 0px !important;
          padding: 0px !important;
          display: flex !important;
          justify-content: center !important;
          align-items: center !important;
          box-shadow: none !important;
        }
        .fc-day-other .fc-daygrid-day-top {
          opacity: 1 !important;
        }
        .fc-daygrid-day-number {
          width: 100% !important;
        }
        .fc-day-today {
          background: transparent !important;
        }
        .fc-highlight {
          background: #213A51 !important;
          border-radius: 4px;
        }
        .fc .fc-scrollgrid-section > * {
          border-radius: 0.5rem;
          border: none !important;
        }
        .fc-view, fc-col-header, .fc-scrollgrid  {
          border: none !important;
          border-radius: 0.5rem;
          text-transform: capitalize;
        }
        .fc-h-event {
          background: transparent !important;
          border: none !important;
        }
        .fc-scroller-harness {
          height: 100% !important;
        }
        .fc-scroller {
          height: 100% !important;
        }
        .fc-scrollgrid-sync-table {
          height: 100% !important;
        }
        .fc-day {
          height: 100% !important;
        }
        .fc-daygrid-day-frame {
          padding: 10px !important;
          height: 100% !important;
        }
        .fc-more-popover {
          opacity: 1 !important;
          border-radius: 10px !important;
          height: fit-content !important;
          background-color: #16162C !important;
        }
        .fc-popover-header {
          background: transparent !important;
        }
        .fc-more-popover-misc {
          display: none !important;
        }
        .fc-col-header-cell {
          padding-left: 0.5rem !important;
        }
      `}</style>
      {children}
    </>
  );
};

function Calendar() {
  const { t } = useTranslation('common');
  const { isInfluencer } = usePermissions();
  const calendarRef = useRef<FullCalendar>(null);
  const [dates, setDates] = useState<any[]>([]);
  const [currentMonthYear, setCurrentMonthYear] = useState<{
    year: number;
    month: number;
  }>({ month: dayjs().get('month') + 1, year: dayjs().get('year') });
  const { notifyWarning } = useNotification();
  const { refetch } = useQuery(GET_DATES_BY_CAMPAIGN, {
    variables: {
      year: String(currentMonthYear.year),
      month: String(currentMonthYear.month),
    },
    skip: !currentMonthYear.year || !currentMonthYear.month,
    onCompleted: (data) => {
      if (!data.getDates.data) {
        return notifyWarning(t('global.errorDates'));
      }
      setDates(data.getDates.data.dates);
      return null;
    },
    onError: () => {
      notifyWarning(t('global.errorDates'));
    },
    notifyOnNetworkStatusChange: true,
  });
  const [popupContent, setPopupContent] = useState<any>(null);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const navigate = useNavigate();
  const [currentView, setCurrentView] = useState('dayGridMonth'); // New state variable for current view
  useEffect(() => {
    if (calendarRef.current) {
      setCurrentView(calendarRef.current.getApi().view.type);
    }
  }, []);

  const handleMonthChange = (e: DatesSetArg) => {
    setDates([]);
    setTooltipVisible(false);
    setCurrentView(calendarRef.current?.getApi().view.type || 'dayGridMonth');
    // This is because with other timezones it will not get the 1st of the month as currentStart
    let addMonth;
    if (e.view.type === 'dayGridMonth') {
      addMonth = e.view.currentStart.getDate() === 1 ? 1 : 2;
    } else {
      addMonth = 1;
    }

    const month = e.view.currentStart.getMonth() + addMonth;
    const year = e.view.currentStart.getFullYear() + (month / 13 === 1 ? 1 : 0);
    setCurrentMonthYear({ month, year });
    refetch({ month: String(month), year: String(year) });
  };

  return (
    <div className="flex flex-col min-h-screen">
      <div
        className={`${
          currentView === 'dayGridMonth'
            ? 'min-h-[100vh-150px)]'
            : 'min-h-[calc(100vh-150px)]'
        } flex`}
      >
        <StyleWrapper>
          <FullCalendar
            ref={calendarRef}
            unselectAuto={false}
            headerToolbar={{
              start: 'title prev next',
              center: '',
              end: 'dayGridMonth,dayGridWeek',
            }}
            buttonText={{
              month: t('calendar.month'),
              week: t('calendar.week'),
            }}
            views={{
              dayGridWeek: {
                dayMaxEventRows: false,
                titleFormat: {
                  month: 'short',
                  day: 'numeric',
                },
              },
              dayGridMonth: {
                dayMaxEventRows: 4,
                text: t('calendar.month'),
                button: t('calendar.month'),
              },
            }}
            datesSet={handleMonthChange}
            aspectRatio={0.5}
            nowIndicator={false}
            firstDay={1}
            locale={i18next.language}
            dayCellContent={(info) => {
              const isToday = dayjs(info.date).isSame(dayjs(), 'day');
              const getColor = () => {
                if (isToday) return 'text-dark-blue bg-influentia-light-blue';
                return 'text-white';
              };
              if (
                calendarRef &&
                calendarRef.current?.getApi().view.type === 'dayGridMonth'
              ) {
                return (
                  <div
                    className={`${getColor()} rounded-[22px] size-5 text-xs flex items-center inter font-bold justify-center`}
                  >
                    {info.dayNumberText}
                  </div>
                );
              }

              return <></>;
            }}
            titleFormat={{ month: 'long' }}
            plugins={[dayGridPlugin]}
            events={dates.map((date) => ({
              id: date.id,
              title: `${t(`actionType.${date.actionType}`)} - ${date.campaign.name}`,
              picture: date.user.profile_picture,
              platform: date.actionPlatform,
              influencerName: date.user.name,
              actionType: date.actionType,
              campaignName: date.campaign.name,
              status: date.status,
              campaignId: date.campaign.id,
              userId: date.user.id,
              dateSelected: new Date(date.date),
              date: date.date,
              allDay: true,
              type: date.type,
            }))}
            eventContent={(info) => {
              const { type, picture, platform } = info.event.extendedProps;
              const getColor = () => {
                if (popupContent && info.event.id === popupContent?.id)
                  return 'bg-white text-influentia-black';
                if (info && type === 'publication')
                  return 'bg-influentia-light-blue text-dark-blue';
                if (info && type === 'script_validation')
                  return 'bg-violet-status text-white';
                if (info && type === 'preview_validation')
                  return 'bg-influentia-yellow text-black';
                return 'text-white';
              };
              return (
                <div
                  id={`eventTooltip-${info.event.id}`}
                  className={`${getColor()} flex flex-row px-1 rounded mb-0.5 cursor-pointer`}
                >
                  <div className="items-center justify-center flex mr-2.5">
                    {picture ? (
                      <div className="size-4 rounded-full">
                        <img
                          src={`${process.env.REACT_APP_AWS_PUBLIC_ENDPOINT}/${picture}`}
                          className="size-4 object-cover rounded-full"
                        />
                      </div>
                    ) : (
                      <>
                        {!isInfluencer() && (
                          <div className="size-4 rounded-full">
                            <DefaultInfluencerIcon className="size-[inherit]" />
                          </div>
                        )}
                        {isInfluencer() && (
                          <div className="size-4 rounded-full">
                            <DefaultBrandIcon className="size-[inherit]" />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <div className="mr-[2px]">
                    <SocialNetworkImage
                      socialNetwork={platform}
                      size="size-[18px]"
                      color="text-influentia-black"
                    />
                  </div>
                  <span className="truncate">{info.event.title}</span>
                </div>
              );
            }}
            eventClick={(info) => {
              const { event } = info;
              setPopupContent(event);
              setTooltipVisible(true);
            }}
            initialView="dayGridMonth"
            height="100%"
            contentHeight="auto"
            dayPopoverFormat={{
              month: 'long',
              day: 'numeric',
            }}
          />
          {popupContent && (
            <Tooltip
              anchorSelect={`#eventTooltip-${popupContent.id}`}
              place="right"
              noArrow
              isOpen={tooltipVisible}
              clickable={true}
              className="!bg-influentia-black/100 z-[9999] !p-0 !rounded-10 !opacity-100"
            >
              <div className="w-[200px] max-w-[200px] p-2.5 !rounded-10 !bg-influentia-black/100">
                <div className="flex justify-end items-center">
                  <OpenIcon
                    className="size-3 cursor-pointer"
                    onClick={() =>
                      navigate(
                        `/campaign/${popupContent.extendedProps.campaignId}/content/${popupContent.extendedProps.userId}`,
                      )
                    }
                  />
                  <CrossIconNoCircle
                    className="size-4 cursor-pointer"
                    onClick={() => setTooltipVisible(false)}
                  />
                </div>

                <div className="flex items-center justify-center flex-col ">
                  <div className="flex flex-row items-center gap-1">
                    <div>
                      {popupContent.extendedProps.type ===
                        'script_validation' && (
                        <FileIcon className="size-[14px]" />
                      )}
                      {popupContent.extendedProps.type ===
                        'preview_validation' && (
                        <FilePlayIcon className="size-[14px]" />
                      )}
                      {popupContent.extendedProps.type === 'publication' && (
                        <CampaignPublishIcon className="size-[14px]" />
                      )}
                    </div>
                    <span className="font-inter text-[14px] font-medium">
                      {t(`dateType.${popupContent.extendedProps.type}`)}
                    </span>
                  </div>
                  <span className="font-inter font-medium text-[10px]">
                    {formatDateSimple(popupContent.extendedProps.dateSelected)}
                  </span>
                  <div className="my-1">
                    {popupContent.extendedProps.picture ? (
                      <div className="size-[45px] rounded-full">
                        <img
                          src={`${process.env.REACT_APP_AWS_PUBLIC_ENDPOINT}/${popupContent.extendedProps.picture}`}
                          className="size-[45px] object-cover rounded-full"
                        />
                      </div>
                    ) : (
                      <>
                        {!isInfluencer() && (
                          <div className="size-[45px] rounded-full">
                            <DefaultInfluencerIcon className="size-[inherit]" />
                          </div>
                        )}
                        {isInfluencer() && (
                          <div className="size-[45px] rounded-full">
                            <DefaultBrandIcon className="size-[inherit]" />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <span className="font-inter text-[14px] font-medium mt-[5px]">
                    {popupContent.extendedProps.influencerName}
                  </span>
                  <div className="flex flex-row justify-center items-center gap-0.5 mt-[5px]">
                    <SocialNetworkImage
                      size="size-[16px]"
                      socialNetwork={popupContent.extendedProps.platform}
                    />
                    <span className="font-inter text-[14px] font-medium">
                      {t(`actionType.${popupContent.extendedProps.actionType}`)}
                    </span>
                  </div>
                  <StatusText
                    extraClassnames="my-[5px]"
                    type="action"
                    status={popupContent.extendedProps.status}
                  />
                  <span className="font-inter text-[14px] font-medium text-center">
                    {popupContent.extendedProps.campaignName}
                  </span>
                </div>
              </div>
            </Tooltip>
          )}
        </StyleWrapper>
      </div>
      <div className="flex lg:flex-row flex-col gap-5 text-sm mt-2">
        <div className="flex flex-row gap-2.5 items-center justify-center">
          <div className="size-[13px] bg-influentia-light-blue rounded-full"></div>
          {t('singleCampaign.publicationDate')}
        </div>
        <div className="flex flex-row gap-2.5 items-center justify-center">
          <div className="size-[13px] bg-influentia-yellow  rounded-full"></div>
          {t('singleCampaign.validationPreview')}
        </div>
        <div className="flex flex-row gap-2.5 items-center justify-center">
          <div className="size-[13px] bg-violet-status rounded-full"></div>
          {t('singleCampaign.validationScript')}
        </div>
      </div>
    </div>
  );
}

export default Calendar;
