import { useMutation, useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { DeclineIcon, HandshakeIcon, TickIcon } from '../../../assets';
import { ROUTES } from '../../../config/routes/config';
import { RefetchLateral } from '../../../context/refetchLateral/refetchProvider';
import { UPDATE_INFLUENCER_SETTINGS } from '../../../graphql/campaign/mutation';
import { GET_INFLUENCER_CAMPAIGN_GENERAL } from '../../../graphql/campaign/query';
import { useNotification } from '../../../hooks/useNotification';
import { usePermissions } from '../../../hooks/usePermissions';
import type {
  InfluencerCampaignGeneral,
  ResInfluencerCampaignGeneral,
} from '../../../types';
import {
  downloadDocumentClick,
  getNoteType,
  getSegmentBeforeSecondUUID,
} from '../../../utils/utils';
import { Button } from '../../common/Button';
import { ActionInfo } from '../content/ActionInfo';
import { InternalNotes } from '../InternalNotes';
import CopyText from '../resources/CopyText';
import { ScheduledDate } from '../ScheduledDate';
import { CampaignPrice } from '../Sidebar/CampaignPrice';
import { HiredPlatforms } from '../Sidebar/HiredPlatforms';
import { UserInfoCard } from '../UserInfoCard';

export const SingleCampaignGeneralTab = () => {
  const [campaignId, influencerId, brandId]: [string, string, string] =
    useOutletContext();
  const [generalInfo, setGeneralInfo] = useState<InfluencerCampaignGeneral>();
  const { t } = useTranslation('common');
  const { getAccessTokenSilently } = useAuth0();
  const { refetchTabs } = useContext(RefetchLateral);
  const [referralKey, setReferral] = useState(0);
  const { isInfluencer, isBrand, isInfluentia } = usePermissions();
  const location = getSegmentBeforeSecondUUID(window.location.pathname) || '';

  const { refetch } = useQuery<ResInfluencerCampaignGeneral>(
    GET_INFLUENCER_CAMPAIGN_GENERAL,
    {
      variables: {
        campaignId,
        influencerId,
        type: getNoteType(location, isInfluencer, isBrand, isInfluentia),
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        setReferral((prev) => prev + 1);
        setGeneralInfo(data.influencerCampaignGeneral.data);
      },
    },
  );
  const { notifySaveSuccess, notifySaveError } = useNotification();
  const [updateInfluencerSettings] = useMutation(UPDATE_INFLUENCER_SETTINGS);
  const navigate = useNavigate();
  const handleSave = async (status?: string) => {
    updateInfluencerSettings({
      variables: {
        influencerId,
        status,
        campaignId,
      },
    })
      .then(() => {
        if (status === 'declined') navigate(ROUTES.CAMPAIGNS);
        notifySaveSuccess();
        refetch();
      })
      .catch(() => {
        notifySaveError();
      });
  };
  const [loading, setLoading] = useState(false);
  return (
    <div>
      {isInfluencer() && generalInfo?.status === 'accepted_pending' && (
        <div className="bg-white mb-2 rounded">
          <div className="py-px px-1 flex flex-row items-center justify-between">
            <div className="flex flex-row items-center gap-2">
              <HandshakeIcon />
              <span className="text-influentia-black text-[14px] ">
                {t('global.campaignAcceptedPending')}
              </span>
            </div>
            <div className="flex flex-row gap-2">
              <Button
                text={t('global.acceptCampaign')}
                style="dark-blue"
                onClick={() => handleSave('active')}
                iconLeft={<TickIcon className="text-white" />}
              />
              <Button
                text={t('global.declineCampaign')}
                style="dark-blue"
                onClick={() => handleSave('declined')}
                iconLeft={<DeclineIcon className="text-white" />}
              />
            </div>
          </div>
        </div>
      )}

      <div className="flex flex-col lg:flex-row">
        <div className="w-full">
          <div className="bg-dark-blue p-5 flex flex-col gap-3 rounded-10 w-full">
            <div key={referralKey}>
              {generalInfo && (
                <UserInfoCard
                  showDate
                  campaignId={campaignId}
                  influencerId={influencerId}
                  name={generalInfo.name}
                  date={generalInfo.publicationDate}
                  refetch={refetch}
                  refetchTabs={refetchTabs}
                  status={generalInfo.status}
                  image={generalInfo.profilePicture}
                  isBrand={brandId === influencerId || isInfluencer()}
                  influencerSidebar
                />
              )}
            </div>
            {generalInfo && (
              <>
                {isInfluencer() && <CampaignPrice price={generalInfo.money} />}

                <HiredPlatforms platforms={generalInfo.platforms} />
                {generalInfo.briefing && (
                  <Button
                    text={t('global.downloadBriefing')}
                    style="light-blue"
                    loading={loading}
                    customClassnames={`w-full items-center flex justify-center hover:!bg-influentia-black`}
                    onClick={async () => {
                      if (generalInfo.briefing && !loading) {
                        try {
                          const accessToken = await getAccessTokenSilently();
                          await downloadDocumentClick(
                            String(generalInfo.briefing),
                            accessToken,
                            setLoading,
                          );
                        } catch (error) {
                          setLoading(false);
                        }
                      }
                    }}
                  />
                )}
                {!generalInfo.briefing && (
                  <span className="flex items-center justify-center text-center italic text-sm">
                    {t('global.noBriefing')}
                  </span>
                )}
              </>
            )}
          </div>
          {generalInfo && (
            <div className="mt-7">
              <InternalNotes
                notes={generalInfo.notes}
                campaignId={campaignId}
                influencerId={influencerId}
                refetch={refetch}
              />
            </div>
          )}
        </div>
        {generalInfo && generalInfo.pieces && generalInfo.pieces.length > 0 && (
          <div className="bg-dark-blue p-5 flex flex-col gap-5 lg:ml-5 lg:mt-0 mt-5 rounded-10 lg:min-w-[500px] self-start w-full max-w-[900px]">
            <span className="font-medium text-sm uppercase">
              {t('global.actions')}
            </span>
            <div className="grow-1 flex flex-col gap-5">
              {generalInfo.pieces.map((piece, index) => (
                <ActionInfo
                  key={index}
                  piece={piece}
                  index={index + 1}
                  refetch={refetch}
                  totalActions={generalInfo.pieces.length}
                />
              ))}
            </div>
            <div className={`${!isInfluencer() && 'hidden'}`}>
              {generalInfo &&
                generalInfo.descriptions &&
                generalInfo.descriptions.map((description, index) => (
                  <CopyText
                    key={index}
                    type="Description"
                    text={description.description}
                  />
                ))}
              {generalInfo &&
                generalInfo.disclaimers &&
                generalInfo.disclaimers.map((disclaimer, index) => (
                  <CopyText
                    key={index}
                    type="Disclaimer"
                    text={disclaimer.disclaimer}
                  />
                ))}
              {generalInfo &&
                generalInfo.referrals &&
                generalInfo.referrals.map((referral, index) => (
                  <CopyText
                    key={index}
                    type={referral.type}
                    text={referral.referral}
                  />
                ))}
            </div>
          </div>
        )}
        {generalInfo &&
          generalInfo.pieces &&
          generalInfo.pieces.length === 0 && (
            <div className="bg-dark-blue p-5 flex flex-col gap-5 lg:ml-5 lg:mt-0 mt-5 rounded-10 lg:min-w-[500px] self-start w-full max-w-[900px]">
              <span className="font-medium text-sm uppercase">
                {t('global.actions')}
              </span>
              <span className="flex items-center justify-center text-center italic text-sm">
                {t('global.noActions')}
              </span>
            </div>
          )}
        {generalInfo && generalInfo.dates && generalInfo.dates.length > 0 && (
          <div className="bg-dark-blue p-5 flex flex-col gap-2 lg:ml-5 lg:mt-0 mt-5 rounded-10 self-start min-w-[290px] w-full">
            <span className="font-medium text-sm uppercase">
              {t('global.events')}
            </span>
            {generalInfo.dates.map((date, index) => (
              <ScheduledDate key={index} type={date.type} date={date.date} />
            ))}
          </div>
        )}
        {generalInfo && generalInfo.dates && generalInfo.dates.length === 0 && (
          <div className="bg-dark-blue p-5 flex flex-col gap-5 lg:ml-5 lg:mt-0 mt-5 rounded-10 self-start min-w-[290px] w-full">
            <span className="font-medium text-sm uppercase">
              {t('global.events')}
            </span>
            <span className="flex items-center justify-center text-center italic text-sm">
              {t('global.noEvents')}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

/*

*/
