import { useMutation } from '@apollo/client';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { CREATE_INTERNAL_NOTE } from '../../graphql/campaign/mutation';
import { useNotification } from '../../hooks/useNotification';
import { usePermissions } from '../../hooks/usePermissions';
import type { InternalNote, ResCreateInternalNote } from '../../types';
import { getNoteType, getSegmentBeforeSecondUUID } from '../../utils/utils';
import { TextArea } from '../inputs/TextArea';
import { SingleNote } from './SingleNote';

type InternalNotesProp = {
  notes?: InternalNote[];
  influencerId: string;
  campaignId: string;
  refetch: () => void;
};

export const InternalNotes = ({
  notes,
  influencerId,
  campaignId,
  refetch,
}: InternalNotesProp) => {
  const { t } = useTranslation('common');
  const { notifySaveSuccess, notifySaveError } = useNotification();
  const { isInfluentia, isBrand, isInfluencer } = usePermissions();
  const location = getSegmentBeforeSecondUUID(window.location.pathname) || '';
  const getTitleText = (currentLocation: string) => {
    if (
      ['general', 'content', 'dates', 'settings-influencer'].includes(
        currentLocation,
      )
    ) {
      return t('singleCampaign.internalNotesInfluencer');
    }
    if (
      ['resources', 'metrics', 'invoices', 'settings'].includes(currentLocation)
    ) {
      return t('singleCampaign.internalNotesBrand');
    }
    return '';
  };

  const scrollRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [notes]);
  const [createInternalNote] =
    useMutation<ResCreateInternalNote>(CREATE_INTERNAL_NOTE);

  const createNote = (message: string) => {
    createInternalNote({
      variables: {
        message,
        campaignId,
        influencerId,
        type: getNoteType(location, isInfluencer, isBrand, isInfluentia),
      },
      onCompleted: () => {
        refetch();
        notifySaveSuccess();
      },
      onError: () => {
        notifySaveError();
      },
    });
  };

  return (
    <div className="bg-dark-blue rounded-10 px-[10px] pt-[10px] ">
      <div className={`mb-[10px] ${isInfluentia() && 'hidden'}`}>
        {t('singleCampaign.internalNotes')}
      </div>
      <div className={`mb-[10px] ${!isInfluentia() && 'hidden'}`}>
        {getTitleText(location)}
      </div>
      {notes && notes.length > 0 ? (
        <div
          className="overflow-y-scroll px-1 internalNotes min-w-[400px] min-h-[245px] max-h-[245px]"
          ref={scrollRef}
        >
          <div id="internalNotes" className="flex flex-col gap-2">
            {notes.map((note) => (
              <SingleNote key={note.id} {...note} />
            ))}
          </div>
        </div>
      ) : (
        <div className="min-w-[400px] min-h-[245px] px-1 items-center justify-center flex italic font-light text-xs">
          {t('singleCampaign.noNotes')}
        </div>
      )}
      <div className="mt-1">
        <TextArea id="textAreaInternal" send={createNote} />
      </div>
    </div>
  );
};
