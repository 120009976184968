import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { BrandsIcon } from '../assets';
import { Tab } from '../components/campaigns/SingleCampaignTabs';
import { SubrowTable } from '../components/campaigns/SubrowsTable';
import { Breadcrumbs } from '../components/common/Breadcrumbs';
import { LateralProfileBrandMenu } from '../components/common/LateralProfileBrandMenu';
import DataTable from '../components/tables/DataTable';
import type { ExtraInfoCampaignHeader } from '../components/tables/headers/campaigns';
import { getCampaignsTableHeaders } from '../components/tables/headers/campaigns';
import { replaceIds, ROUTES } from '../config/routes/config';
import { GET_BRAND } from '../graphql/brands/query';
import { GET_CAMPAIGNS_LIST } from '../graphql/campaign/query';
import { useNotification } from '../hooks/useNotification';
import { usePermissions } from '../hooks/usePermissions';
import { useTableDataAndFilters } from '../hooks/useTableDataAndFilters';
import { campaignFilterBrandAtom, userAtom } from '../states/atoms';
import type {
  CampaignFilters,
  CampaignTable,
  ResCampaignsList,
} from '../types';

export const SingleBrandCampaigns = () => {
  const { id } = useParams();
  const { t } = useTranslation('common');
  const myUser = useRecoilValue(userAtom);

  const navigate = useNavigate();
  const { notifyWarning } = useNotification();
  const location = useLocation();

  const { isInfluentia, isInfluencer } = usePermissions();
  const [brand, setBrand] = useState<any>({});
  const { data, refetch } = useQuery(
    // const { loading } = useQuery<ResSingleCampaignType>(
    GET_BRAND,
    {
      variables: {
        brandId: id,
      },
      onError: () => {
        notifyWarning(t('global.errorSingleBrand'));
        navigate(ROUTES.BRANDS);
      },
      onCompleted: (info: any) => {
        setBrand(info.brand.data);
      },
      skip: !id,
    },
  );

  const {
    data: campaigns,
    resetPagination,
    total,
    error,
    loading,
    filters,
    setFilters,
    table,
    refetch: refetchTable,
  } = useTableDataAndFilters<
    CampaignFilters,
    CampaignTable,
    ExtraInfoCampaignHeader,
    ResCampaignsList
  >({
    filterAtom: campaignFilterBrandAtom,
    getColumns: getCampaignsTableHeaders,
    columnsExtras: { isInfluencer: isInfluencer() },
    responseAccessKey: 'campaigns',
    query: GET_CAMPAIGNS_LIST,
    subrowAccesor: 'users',
  });

  useEffect(() => {
    if (brand) {
      setFilters((prev) => ({
        ...prev,
        client: [{ label: '', value: brand?.id || '' }],
      }));
      refetchTable();
    }
    return () => {
      resetPagination();
      setFilters((prev) => ({
        ...prev,
        client: undefined,
      }));
    };
  }, [brand]);

  const handleTableClick = (idClick: string, influencer_id?: string) => {
    const campaign = campaigns.find((c) => c.id === idClick);
    if (campaign)
      navigate(
        replaceIds({
          url: ROUTES.SINGLE_CAMPAIGN_GENERAL,
          params: [
            {
              campaignId: campaign.id,
            },
            {
              influencerId:
                influencer_id ||
                (campaign.users[0] ? campaign.users[0].user_id : '') ||
                '',
            },
          ],
        }),
      );
  };

  return (
    <div className="w-full flex flex-col gap-y-5">
      <Breadcrumbs
        icon={<BrandsIcon />}
        breadcrumbs={[
          {
            text: t('singleBrand.Breadcrumps'),
            link: ROUTES.BRANDS,
          },
          {
            text: data?.brand.data.name || '',
            link: ROUTES.SINGLE_BRAND.replace(':id', brand.id),
          },
          {
            text: t('campaigns.title'),
          },
        ]}
      />
      <div>
        {isInfluentia() && (
          <div className="flex-row gap-x-2 overflow-x-auto customScroll pb-1 lg:hidden flex">
            <Tab
              to={ROUTES.SINGLE_BRAND.replace(':id', brand.id)}
              text={t('profile.menu.title1')}
            />
            <Tab
              to={ROUTES.SINGLE_BRAND_CAMPAIGNS.replace(':id', brand.id)}
              text={t('profile.menu.title2')}
            />
          </div>
        )}
        <div className="flex flex-row gap-12">
          {isInfluentia() && <LateralProfileBrandMenu brand={brand} />}
          <div>
            <DataTable
              error={error}
              handleRowClick={(idClick) => handleTableClick(idClick)}
              handleSubrowClick={(idClick, influencer_id) =>
                handleTableClick(idClick, influencer_id)
              }
              loading={loading}
              table={table}
              total={total}
              tableId="CampaignsTable"
              sorting={{ ...filters }}
              SubrowsComponent={SubrowTable}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
