import { useMutation, useQuery } from '@apollo/client';
import type { DatesSetArg } from '@fullcalendar/core';
// import { useAuth0 } from '@auth0/auth0-react';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // for selectable
// for selectable
import FullCalendar from '@fullcalendar/react';
import dayjs from 'dayjs';
import i18next from 'i18next';
import type { ReactNode } from 'react';
import { useEffect, useRef, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { UPDATE_PUBLISH_DATE } from '../../../graphql/campaign/mutation';
import {
  GET_DATES_BY_CAMPAIGN,
  GET_INFLUENCER_CAMPAIGN_GENERAL,
} from '../../../graphql/campaign/query';
import { useNotification } from '../../../hooks/useNotification';
import { usePermissions } from '../../../hooks/usePermissions';
import { usePrompt } from '../../../hooks/usePrompt';
import type {
  InfluencerCampaignGeneral,
  ResInfluencerCampaignGeneral,
} from '../../../types';
import { getNoteType, getSegmentBeforeSecondUUID } from '../../../utils/utils';
import { Button } from '../../common/Button';
import { DatePickerInput } from '../../inputs/DatePicker';
import { ActionDate } from '../dates/ActionDate';

const StyleWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <style>{`  
        td {
            padding-bottom: 15px !important;
        }
        .fc-day {
          border: none !important;
        }
        .fc-header-toolbar {
          display: flex;
          margin-bottom: 0px !important;
          font-family: 'Montserrat', sans-serif !important;
          font-size: 18px !important;
          font-style: normal !important;
          font-weight: 500 !important;
          line-height: normal !important;
          text-transform: capitalize;
        }
        .fc-toolbar-chunk {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .fc-toolbar-title {
          margin-left: 15px !important;
        }
        .fc-next-button {
          width: 32px !important;
          height: 32px !important;
          border: 0px !important;
          padding: 0px !important;
          display: flex !important;
          justify-content: center !important;
          align-items: center !important;
          box-shadow: none !important;
        }
        .fc-prev-button {
          width: 32px !important;
          height: 32px !important;
          border: 0px !important;
          padding: 0px !important;
          display: flex !important;
          justify-content: center !important;
          align-items: center !important;
          box-shadow: none !important;
        }
        .fc-col-header-cell {
          border: none !important;
          font-weight: 500 !important;
        }
        .fc-day-other .fc-daygrid-day-top {
          opacity: 1 !important;
        }
        .fc-view, fc-col-header, .fc-scrollgrid  {
          border: none !important;
          text-transform: capitalize;
        }
        .fc .fc-scrollgrid-section > * {
          border: none !important;
        }
        .fc-daygrid-day-events {
          display: none !important;
        }
        .fc-daygrid-day-number {
          width: 100% !important;
        }
        .fc-day-today {
          background: transparent !important;
        }
        .fc-highlight {
          background: #213A51 !important;
          border-radius: 4px;
        }
      `}</style>
      {children}
    </>
  );
};

function transformDate(dateStr: string): string {
  const [month, day, year] = dateStr.split('/');

  if (!month || !day || !year) {
    throw new Error('Invalid date format');
  }

  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
}

export const SingleCampaignDatesTab = () => {
  const [campaignId, influencerId]: [string, string] = useOutletContext();
  const { isInfluentia, isBrand, isInfluencer } = usePermissions();

  const [dates, setDates] = useState<any>();
  const [selectedDate, setSelectedDate] = useState<any>([]);
  const { t } = useTranslation('common');
  const [generalInfo, setGeneralInfo] = useState<InfluencerCampaignGeneral>();

  // const { getAccessTokenSilently } = useAuth0();
  const calendarRef = useRef<FullCalendar>(null);
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { dirtyFields },
  } = useForm();
  const [updateCampaign, { loading }] = useMutation(UPDATE_PUBLISH_DATE);
  const { notifySaveSuccess, notifySaveError } = useNotification();
  const [actionDates, setActionDates] = useState(0);
  const [currentMonthYear, setCurrentMonthYear] = useState<{
    year: number;
    month: number;
  }>({ month: dayjs().get('month') + 1, year: dayjs().get('year') });
  const { refetch } = useQuery(GET_DATES_BY_CAMPAIGN, {
    variables: {
      userId: influencerId,
      campaignId,
      month: String(currentMonthYear.month),
      year: String(currentMonthYear.year),
    },
    skip:
      !influencerId ||
      !campaignId ||
      !currentMonthYear.month ||
      !currentMonthYear.year,
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const info = data.getDates.data;
      const infoDates = info.dates;
      setDates(infoDates);
      const thisCampaign = info.campaign;
      if (thisCampaign.from_date && thisCampaign.to_date) {
        setSelectedDate([thisCampaign.from_date, thisCampaign.to_date]);
        setValue('Desde', thisCampaign.from_date, { shouldDirty: false });
        setValue('Hasta', thisCampaign.to_date, { shouldDirty: false });
        if (!calendarRef.current) return;
        calendarRef.current
          .getApi()
          .select(thisCampaign.from_date, thisCampaign.to_date);
      }
      reset({}, { keepValues: true });
    },
  });
  const handleMonthChange = (e: DatesSetArg) => {
    setDates([]);
    // This is because with other timezones it will not get the 1st of the month as currentStart
    let addMonth;
    if (e.view.type === 'dayGridMonth') {
      addMonth = e.view.currentStart.getDate() === 1 ? 1 : 2;
    } else {
      addMonth = 1;
    }

    const month = e.view.currentStart.getMonth() + addMonth;
    const year = e.view.currentStart.getFullYear() + (month / 13 === 1 ? 1 : 0);
    setCurrentMonthYear({ month, year });
    refetch({
      userId: influencerId,
      campaignId,
      month: String(month),
      year: String(year),
    });

    if (!calendarRef.current || !selectedDate[0] || !selectedDate[1]) return;
    calendarRef.current.getApi().select(selectedDate[0], selectedDate[1]);
  };

  /**
   * Function to add new events
   */
  const addEvent = (event: any) => {
    if (!calendarRef.current) return;

    const calendar = calendarRef.current.getApi();
    const eventObj = calendar.getEventById(event.id);

    if (eventObj) {
      // @ts-ignore
      eventObj.mutate({ standardProps: { ...event } });
    } else {
      calendar.addEvent(event);
    }
  };

  useEffect(() => {
    if (dates) {
      dates.forEach(addEvent);
    }
  }, [dates]);

  useEffect(() => {
    if (selectedDate.length) {
      setValue('Desde', selectedDate[0]);
      setValue('Hasta', selectedDate[1]);
    }
  }, [selectedDate]);
  const handleDateChange = (date: string | null) => {
    if (date) {
      setSelectedDate([transformDate(date), selectedDate[1]]);
      if (!calendarRef.current) return;
      calendarRef.current.getApi().select(transformDate(date), selectedDate[1]);
    }
  };
  const handleDateChange1 = (date: string | null) => {
    if (date) {
      setSelectedDate([selectedDate[0], transformDate(date)]);
      if (!calendarRef.current) return;
      calendarRef.current.getApi().select(selectedDate[0], transformDate(date));
    }
  };
  const location = getSegmentBeforeSecondUUID(window.location.pathname) || '';

  const { refetch: refectGeneral } = useQuery<ResInfluencerCampaignGeneral>(
    GET_INFLUENCER_CAMPAIGN_GENERAL,
    {
      variables: {
        campaignId,
        influencerId,
        type: getNoteType(location, isInfluencer, isBrand, isInfluentia),
      },
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        setGeneralInfo(data.influencerCampaignGeneral.data);
        setActionDates(actionDates + 1);
      },
    },
  );

  const handleSave: SubmitHandler<any> = () => {
    updateCampaign({
      variables: {
        campaignId,
        fromDate: new Date(selectedDate[0]),
        toDate: new Date(selectedDate[1]),
      },
      onCompleted: () => {
        notifySaveSuccess();
        refetch();
        reset({}, { keepValues: true });
      },
      onError: () => {
        notifySaveError();
      },
    });
  };
  usePrompt({
    isDirty: Object.keys(dirtyFields).length > 0,
  });
  return (
    <div className="flex flex-col">
      <div>
        <StyleWrapper>
          <FullCalendar
            ref={calendarRef}
            unselectAuto={false}
            headerToolbar={{
              start: 'title prev next',
              center: '',
              end: '',
            }}
            select={(info) => {
              setSelectedDate([info.startStr, info.endStr]);
              setValue('Desde', info.startStr, { shouldDirty: true });
              setValue('Hasta', info.endStr, { shouldDirty: true });
            }}
            selectable={isInfluentia() || isBrand()}
            aspectRatio={0.5}
            nowIndicator={false}
            firstDay={1}
            locale={i18next.language}
            datesSet={handleMonthChange}
            dayCellContent={(info) => {
              const hasEvents = dates?.some((d: any) =>
                dayjs(d.date).isSame(dayjs(info.date, 'day')),
              );
              const event = dates?.find((d: any) =>
                dayjs(d.date).isSame(dayjs(info.date, 'day')),
              );
              const isThisCampaign = event?.campaign?.id === campaignId;
              const isToday = dayjs(info.date).isSame(dayjs(), 'day');
              const getColor = () => {
                if (isToday && hasEvents) return 'bg-violet-status text-white';
                if (event && !isThisCampaign && isInfluencer())
                  return 'bg-influentia-red';
                if (event && event.type === 'publication')
                  return 'bg-influentia-light-blue text-dark-blue';
                if (event && event.type === 'script_validation')
                  return 'bg-violet-status text-white';
                if (event && event.type === 'preview_validation')
                  return 'bg-influentia-yellow text-black';
                if (isToday) return 'text-dark-blue bg-white';

                return 'text-white';
              };
              return (
                <div className="w-full flex items-center justify-center size-10">
                  <div
                    className={`${getColor()} rounded-md size-8 flex items-center justify-center`}
                  >
                    {info.dayNumberText}
                  </div>
                </div>
              );
            }}
            titleFormat={{ month: 'long' }}
            plugins={[dayGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            height="100%"
            contentHeight="auto"
          />
        </StyleWrapper>
      </div>
      <div className="flex lg:flex-row flex-col gap-5 text-sm">
        <div className="flex flex-row gap-2.5 items-center justify-center">
          <div className="size-[13px] bg-dark-blue rounded-full"></div>
          {t('singleCampaign.suggestedDates')}
        </div>
        {!isBrand() && (
          <div className="flex flex-row gap-2.5 items-center justify-center">
            <div className="size-[13px] bg-influentia-red rounded-full"></div>
            {t('singleCampaign.otherCampaigns')}
          </div>
        )}
        <div className="flex flex-row gap-2.5 items-center justify-center">
          <div className="size-[13px] bg-influentia-light-blue rounded-full"></div>
          {t('singleCampaign.publicationDate')}
        </div>
        <div className="flex flex-row gap-2.5 items-center justify-center">
          <div className="size-[13px] bg-influentia-yellow  rounded-full"></div>
          {t('singleCampaign.validationPreview')}
        </div>
        <div className="flex flex-row gap-2.5 items-center justify-center">
          <div className="size-[13px] bg-violet-status rounded-full"></div>
          {t('singleCampaign.validationScript')}
        </div>
      </div>
      <div className={`mt-9 flex flex-col ${isInfluencer() ? 'hidden' : ''}`}>
        {t('singleCampaign.campaignPublication')}
        <div className="flex flex-col gap-2.5 mt-5">
          <p>
            <span className="font-bold text-sm">
              {t('singleCampaign.selectCampaignPublication')}
            </span>{' '}
            <span className="text-sm">
              {t('singleCampaign.selectCampaignPublicationDisclaimer')}
            </span>
          </p>
          <div className="flex lg:flex-row flex-col gap-2.5">
            <DatePickerInput
              name="Desde"
              control={control}
              placeholder={t('global.from')}
              onChange={handleDateChange}
            />
            <DatePickerInput
              name="Hasta"
              control={control}
              placeholder={t('global.to')}
              onChange={handleDateChange1}
            />
            <Button
              text={t('global.save')}
              style="light-blue"
              loading={loading}
              customClassnames="min-w-[185px] flex items-center justify-center"
              onClick={handleSubmit(handleSave)}
            />
          </div>
        </div>
        <div
          className={`w-full h-px bg-white mt-5 ${isBrand() && 'hidden'}`}
        ></div>
      </div>
      <div
        key={actionDates}
        className={`mt-9 flex flex-col ${isBrand() && 'hidden'}`}
      >
        {t('singleCampaign.actionPublication')}
        <div className="grid grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 3xl:grid-cols-4 gap-3 mt-5">
          {generalInfo &&
            generalInfo.pieces &&
            generalInfo.pieces.map((piece, index) => (
              <ActionDate
                key={piece.id}
                indexAction={index + 1}
                piece={piece}
                total={generalInfo.pieces.length}
                refetch={refectGeneral}
                refectDates={refetch}
              />
            ))}
        </div>
      </div>
    </div>
  );
};
