import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import { replaceIds, ROUTES } from '../../../config/routes/config';
import type { InfluencerCampaignStatusType } from '../../../types';
import { UsersImages } from '../../images/UserImages';

type SingleCampaignTabsProps = {
  campaignId: string;
  idState: string;
  influencerId: string;
  influencers: {
    id: string;
    profile_picture: string;
    name: string;
    status: InfluencerCampaignStatusType;
  }[];
  brandName: string;
  brandProfilePicture?: string;
  brandId: string;
};

type TabProps = {
  to: string;
  text: string;
};

const Tab = ({ to, text }: TabProps) => {
  return (
    <NavLink
      className={({ isActive }) =>
        `font-semibold flex flex-none text-sm rounded-[5px] py-2 px-5 ${isActive ? 'bg-influentia-light-blue text-dark-blue' : 'bg-dark-blue text-white hover:bg-influentia-light-blue hover:text-dark-blue'}`
      }
      to={to}
    >
      {text}
    </NavLink>
  );
};

export const SingleCampaignRecurrentTabs = ({
  campaignId,
  idState,
  influencerId,
  influencers,
  brandName,
  brandProfilePicture,
  brandId,
}: SingleCampaignTabsProps) => {
  const { t } = useTranslation('common');
  const location = useLocation();

  const PARAMS: { [key: string]: string }[] = [
    { campaignId },
    { influencerId },
    { id: idState },
  ];

  const getUrl = (url: string, id?: string) => {
    return replaceIds({
      url,
      params: id ? [...PARAMS, { id }] : [...PARAMS, { id: idState }],
    });
  };

  const isResourcesTab = useMemo(
    () => /^\/campaign-recurrent\/.*\/resources$/.test(location.pathname),
    [location],
  );
  const isSettingsCampaign = useMemo(
    () => /^\/campaign-recurrent\/.*\/settings$/.test(location.pathname),
    [location],
  );

  const getInfluencerTabUrl = (id: string) => {
    return `${location.pathname.split('/').slice(0, -1).join('/')}/${id}`;
  };

  return (
    <div className="flex flex-col gap-y-3">
      <div className="flex flex-row gap-x-2 overflow-x-auto customScroll pb-1">
        <Tab
          to={getUrl(ROUTES.SINGLE_CAMPAIGN_RESOURCES_RECURRENT)}
          text={t('singleCampaign.tabs.resources')}
        />
        <Tab
          to={getUrl(ROUTES.SINGLE_CAMPAIGN_RECURRENT_SETTINGS_INFLUENCER)}
          text={t('singleCampaign.tabs.settingsInfluencer')}
        />
        <Tab
          to={getUrl(ROUTES.SINGLE_CAMPAIGN_RECURRENT_SETTINGS)}
          text={t('singleCampaign.tabs.settings')}
        />
      </div>
      <div
        className={`flex flex-row overflow-x-auto customScroll pb-1 gap-x-2 ${(isResourcesTab || isSettingsCampaign) && 'hidden'}`}
      >
        {influencers.map((i) => (
          <NavLink
            key={i.id + campaignId}
            className={({ isActive }) =>
              `${isActive ? '!bg-influentia-light-blue' : 'bg-white'} flex-none text-sm items-center font-semibold text-influentia-black flex flex-row gap-x-2 rounded-full px-4 py-1`
            }
            to={getInfluencerTabUrl(i.id)}
          >
            <UsersImages
              userCount={1}
              userImages={[i.profile_picture]}
              size="size-[24px]"
            />
            {i.name}
          </NavLink>
        ))}
      </div>
    </div>
  );
};
