import React from 'react';
import { Link } from 'react-router-dom';

import type { CampaignStatusType } from '../../types';
import { StatusText } from '../common/StatusText';
import { BrandImage } from '../images/BrandImage';
import { UsersImages } from '../images/UserImages';

type DashboardInfoCardProps = {
  image?: string;
  status: CampaignStatusType;
  campaignName: string;
  price: string;
  influencerView: boolean;
  userCount?: number;
  userImages?: string[];
  link: string;
};

export const DashboardInfoCard = ({
  image,
  status,
  campaignName,
  price,
  influencerView,
  userCount,
  userImages,
  link,
}: DashboardInfoCardProps) => {
  return (
    <Link
      to={link}
      className="flex flex-col p-2.5 gap-y-1 min-w-[180px] flex-1 bg-dark-blue hover:bg-gray-hover rounded-10 items-center cursor-pointer"
    >
      <div>
        {influencerView ? (
          <BrandImage image={image} />
        ) : (
          <UsersImages userCount={userCount!} userImages={userImages!} />
        )}
      </div>
      <div className="flex flex-col text-center items-center justify-between h-full">
        <p className="font-bold">{campaignName}</p>
        <div>
          <StatusText
            type="campaign"
            status={status}
            extraClassnames="uppercase"
          />
          {influencerView && <p className="text-sm">{price}</p>}
        </div>
      </div>
    </Link>
  );
};
