import 'dayjs/locale/es';

import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import {
  AppNotificationIcon,
  MailNotificationIcon,
  PushNotificationIcon,
  UserIcon,
} from '../assets';
import { Breadcrumbs } from '../components/common/Breadcrumbs';
import { LateralProfileMenu } from '../components/common/LateralProfileMenu';
import ProfileTabs from '../components/common/ProfileTabs';
import NotificationGroup from '../components/profile/NotificationGroup';
import { NOTIFICATIONS } from '../config/constants';
import { ROUTES } from '../config/routes/config';
import { GET_PROFILE_NOTIFICATION } from '../graphql/user/query';
import { userAtom } from '../states/atoms';

dayjs.extend(localizedFormat);
dayjs.locale('es');

function ProfileNotifications() {
  const { t } = useTranslation('common');
  const [user, setUser] = useState<any>();
  const location = useLocation();
  const myUser = useRecoilValue(userAtom);

  const { refetch } = useQuery(GET_PROFILE_NOTIFICATION, {
    onCompleted: (data) => {
      setUser(data.profileNotifications.data);
    },
    notifyOnNetworkStatusChange: true,
  });

  return (
    <div className="w-full flex flex-col gap-y-5">
      <div className="h-[32px] flex items-center">
        <Breadcrumbs
          icon={<UserIcon />}
          breadcrumbs={[
            {
              text: t('profile.title'),
              link: ROUTES.PROFILE,
            },
            {
              text: t('profile.menu.title3'),
            },
          ]}
        />
      </div>
      {user && (
        <>
          <div className="lg:hidden flex flex-row gap-5">
            <ProfileTabs location={location} user={user} myUser={myUser} />
          </div>
          <div className="flex flex-row gap-12">
            {!location.pathname.includes('user') && (
              <div className="h-screen sticky top-0 lg:flex hidden">
                <LateralProfileMenu
                  user={user}
                  isProfile={user.id === myUser.id}
                  isBrand={myUser.role === 'Brand'}
                />
              </div>
            )}
            <div className="md:w-4/5 w-full">
              <span className="uppercase">{t('profile.notifications')}</span>
              <div className="mt-3">
                <div>
                  <div className="flex flex-row justify-evenly items-center md:hidden my-5">
                    <div className="flex-row gap-1 flex-none flex">
                      <AppNotificationIcon />
                      <span>In-App</span>
                    </div>
                    <div className="flex-row gap-1 flex-none flex">
                      <PushNotificationIcon />
                      <span>Push</span>
                    </div>
                    <div className="flex-row gap-1 flex-none flex">
                      <MailNotificationIcon />
                      <span>Email</span>
                    </div>
                  </div>
                  {Object.keys(NOTIFICATIONS).map((key: string) => (
                    <NotificationGroup
                      key={key}
                      groupTitle={key}
                      notifications={NOTIFICATIONS[key].notifications}
                      userNotifications={user.notifications}
                      refetch={refetch}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ProfileNotifications;
