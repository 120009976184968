import { useMutation, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DoubleCheck } from '../../assets';
import {
  MARK_ALL_NOTIFICATIONS_READ,
  MARK_NOTIFICATION_READ,
} from '../../graphql/notifications/mutation';
import { GET_ALL_NOTIFICATIONS } from '../../graphql/notifications/query';
import type {
  ResNotifications,
  SingleNotification,
} from '../../types/notifications';
import { Button } from '../common/Button';

interface NotificationModalProps {
  closeModal: () => void;
  refetchHasNotifications: () => void;
}

export const NotificationModal = ({
  closeModal,
  refetchHasNotifications,
}: NotificationModalProps) => {
  const { t } = useTranslation('common');
  const [notifications, setNotifications] = useState<SingleNotification[]>([]);
  const modalRef = useRef(null);
  // QUERIES AND MUTATIONS
  const { refetch, loading } = useQuery<ResNotifications>(
    GET_ALL_NOTIFICATIONS,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        setNotifications(data.notifications.data);
      },
    },
  );

  const [setRead] = useMutation(MARK_NOTIFICATION_READ);
  const [setAllRead] = useMutation(MARK_ALL_NOTIFICATIONS_READ);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (
        modalRef.current &&
        !(modalRef.current as any).contains(event.target) &&
        event.target.id === 'openedModal'
      ) {
        closeModal();
      }
    }
    // Bind the event listener
    document.addEventListener('click', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('click', handleClickOutside);
    };
  }, [modalRef]);

  const onMarkRead = (id: string) => {
    setRead({
      variables: { id },
      onCompleted: () => {
        refetch();
        refetchHasNotifications();
      },
    });
  };

  const onMarkAllRead = () => {
    setAllRead({
      onCompleted: () => {
        refetch();
        refetchHasNotifications();
      },
    });
  };

  const getNotificationVariables = (
    variables: string | object,
  ): { [key: string]: string } => {
    if (typeof variables === 'object')
      return variables as { [key: string]: string };
    return { ...JSON.parse(variables) };
  };

  const getNotificationActionType = (variables: string | object): string => {
    if (typeof variables === 'object') return (variables as any).actionType;
    return JSON.parse(variables).actionType;
  };

  return (
    <div id="openedModal" className="fixed left-0 top-0 z-50 size-full">
      <div
        ref={modalRef}
        className="absolute top-16 right-5 sm:w-[450px] w-[calc(100%-40px)] sm:h-[500px] h-[90vh] bg-white rounded-lg text-influentia-black"
      >
        <div className="flex flex-col w-full p-8 h-[inherit] gap-y-4">
          <div className="flex sm:flex-row flex-col justify-between w-full items-center">
            <p className="font-bold">{t('notifications.title')}</p>
            <Button
              onClick={onMarkAllRead}
              text={t('notifications.markAllRead')}
              style="black"
              iconLeft={<DoubleCheck />}
            />
          </div>
          <div className="h-full overflow-y-auto flex flex-col divide-y divide-gray-status pr-2 notifications-scrollbar">
            {notifications.map((n) => (
              <div
                key={n.id + Math.random()}
                className="flex flex-row justify-between gap-x-2 py-3"
              >
                <a
                  className="flex flex-col"
                  href={n.link}
                  onClick={() => onMarkRead(n.id)}
                >
                  <p className="text-sm font-semibold">
                    {t(`notifications.titles.${n.type}`, {
                      ...getNotificationVariables(n.variables),
                      actionType: t(
                        `actionType.${getNotificationActionType(n.variables)}`,
                      ),
                    })}
                  </p>
                  <p className="text-sm font-normal">
                    {t(`notifications.description.${n.type}`, {
                      ...getNotificationVariables(n.variables),
                      actionType: t(
                        `actionType.${getNotificationActionType(n.variables)}`,
                      ),
                    })}
                  </p>
                  <p className="text-xs font-normal capitalize">
                    {dayjs(new Date(parseInt(n.createdAt, 10))).format(
                      'MMM DD, YYYY · hh:mm A',
                    )}
                  </p>
                </a>
                <div className="h-full flex items-center justify-center">
                  {!n.readAt && (
                    <button
                      onClick={() => onMarkRead(n.id)}
                      className="p-2 hover:bg-gray-status rounded-full"
                    >
                      <div className="size-[11px] bg-influentia-light-blue rounded-full" />
                    </button>
                  )}
                </div>
              </div>
            ))}
            {notifications.length === 0 && !loading && (
              <div className="flex items-center justify-center mt-10">
                <p>{t('notifications.noNotifications')}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
