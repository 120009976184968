import type { ApolloError } from '@apollo/client';
import type { Table } from '@tanstack/react-table';
import { flexRender } from '@tanstack/react-table';
import type { FC } from 'react';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { BeforeIcon, BeforeMaxIcon, NextIcon, NextMaxIcon } from '../../assets';
import { PAGE_SIZES } from '../../config/constants';
import Loader from '../loaders/LoaderSpinner';

interface Props {
  table: Table<any>;
  handleRowClick?: (val: string, event: any) => void;
  handleSubrowClick?: (val: string, val2: string, event: any) => void;
  error: ApolloError | undefined;
  total: number;
  loading: boolean;
  tableId: string;
  sorting: { orderBy?: string | null; orderDirection?: string | null };
  pagination?: boolean;
  tableLayout?: 'auto' | 'fixed';
  SubrowsComponent?: FC<any>;
}

const widths = [
  'w-[35%]',
  'w-[45%]',
  'w-[55%]',
  'w-[65%]',
  'w-[75%]',
  'w-[85%]',
  'w-full',
];

const DataTable = ({
  table,
  handleRowClick,
  error,
  total,
  loading,
  tableId,
  sorting,
  pagination = true,
  tableLayout = 'fixed',
  SubrowsComponent,
  handleSubrowClick,
}: Props) => {
  const { t } = useTranslation('common');
  const rowId = (row: any) => {
    if (row.original.license) return row.original.license;
    if (row.original.name) return row.original.name;
    return row.original.id;
  };

  const getRandomWidth = () => {
    const rnd = Math.floor(Math.random() * (7 + 1));
    return widths[rnd];
  };

  const loadingSkeleton = useMemo(
    () =>
      Array.from({ length: table.getAllColumns().length }, (_, i) => i + 1).map(
        (_i) => (
          <tr
            key={_i}
            className="h-[58px] border-b-DEFAULT border-b-transparent"
          >
            {table.getHeaderGroups()[0]?.headers.map((h) => (
              <td key={h.id} className="cell-class truncate px-2">
                <div
                  className={`h-[15px] animate-skeleton rounded-[5px] ${
                    h.id === 'select' ? 'w-1/2' : getRandomWidth()
                  }`}
                ></div>
              </td>
            ))}
          </tr>
        ),
      ),
    [loading],
  );

  return (
    <>
      <table
        style={{
          tableLayout,
          width: '100%',
          borderSpacing: '0px 4px',
          borderCollapse: 'separate',
        }}
        className="w-full table-fixed"
        id={tableId}
      >
        <thead className="h-[36px] bg-light-blue text-influentia-black font-medium">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header, index) => {
                let className = '';
                if (index === 0) {
                  className = 'rounded-l-lg';
                } else if (index === headerGroup.headers.length - 1) {
                  className = 'rounded-r-lg';
                }
                return (
                  <th
                    colSpan={header.colSpan}
                    style={{
                      width: header.getSize(),
                    }}
                    key={header.id}
                    className={`px-2 text-left ${className}`}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, {
                          ...header.getContext(),
                          ...sorting,
                        })}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody className="border-separate">
          {loading && loadingSkeleton}
          {!loading && error ? (
            <tr>
              <td colSpan={table.getAllColumns().length}>
                <p>Error: {error.message}</p>
              </td>
            </tr>
          ) : (
            total === 0 && (
              <tr className="h-[70px]">
                <td colSpan={table.getAllColumns().length}>
                  <p className="w-full text-center">{t('global.no-data')}</p>
                </td>
              </tr>
            )
          )}

          {!loading &&
            table.getRowModel().rows.map((row) => {
              return (
                <Fragment key={row.id}>
                  <tr
                    onClick={(event) => {
                      if (handleRowClick) {
                        handleRowClick(row.original.id as string, event);
                      }
                    }}
                    className={`relative h-[58px] overflow-auto bg-dark-blue py-2  rounded-2xl ${
                      handleRowClick
                        ? 'hover:cursor-pointer hover:bg-gray-hover'
                        : ''
                    } ${row.depth > 0 ? '!bg-[#EBF0F1]' : ''}`}
                    key={row.id}
                    id={rowId(row).replace(/\s/g, '')}
                  >
                    {row.getVisibleCells().map((cell, index) => {
                      let className = '';
                      let classNameDiv = '';
                      if (index === 0) {
                        className = 'rounded-l-[10px] text-start';
                        classNameDiv = 'flex justify-start';
                      } else if (index === row.getVisibleCells().length - 1) {
                        className = 'rounded-r-[10px] text-end';
                        classNameDiv = 'flex justify-end';
                      } else {
                        className = 'text-center align-middle';
                        classNameDiv = 'flex justify-center';
                      }
                      return (
                        <td
                          key={cell.id}
                          className={`cell-class truncate px-2 ${className}`}
                        >
                          <div className={classNameDiv}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                  {row.getIsExpanded() &&
                    SubrowsComponent &&
                    row.subRows.map((sr) => (
                      <tr
                        key={sr.id + Math.random()}
                        onClick={(event) => {
                          if (handleSubrowClick) {
                            handleSubrowClick(
                              row.original.id as string,
                              sr.original.user_id as string,
                              event,
                            );
                          }
                        }}
                      >
                        <td colSpan={table.getAllColumns().length}>
                          <div className="relative h-[58px] overflow-auto hover:bg-gray-hover bg-[#374E62] cursor-pointer rounded-2xl flex flex-row items-center w-full">
                            {/* 2nd row is a custom component */}
                            <SubrowsComponent {...sr.original} />
                          </div>
                        </td>
                      </tr>
                    ))}
                </Fragment>
              );
            })}
        </tbody>
        <tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header, index) => {
                let className = '';

                if (index === 0) {
                  className = 'rounded-l-lg';
                } else if (index === footerGroup.headers.length - 1) {
                  className = 'rounded-r-lg';
                }

                return (
                  <th key={header.id} className={`px-2 ${className}`}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.footer,
                          header.getContext(),
                        )}
                  </th>
                );
              })}
            </tr>
          ))}
        </tfoot>
      </table>
      {/* Pagination */}
      {pagination && (
        <div className="my-6">
          <div className="flex items-center gap-2 ">
            <span className="text-[14px] font-medium">{t('tables.page')}</span>
            <div className="flex h-[29px] items-center justify-center rounded-[5px] bg-white divide-x divide-influentia-black">
              <button
                onClick={() => table.setPageIndex(0)}
                disabled={!table.getCanPreviousPage()}
                className={`${!table.getCanPreviousPage() ? 'opacity-30' : ''} `}
              >
                <BeforeMaxIcon />
              </button>
              <button
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
                className={`${!table.getCanPreviousPage() ? 'opacity-30' : ''}`}
              >
                <BeforeIcon />
              </button>
              <div className="flex items-center gap-1">
                <div className="dropdown-container relative inline-flex text-dark-blue font-medium font-montserrat">
                  <select
                    value={table.getState().pagination.pageIndex + 1}
                    onChange={(e) => {
                      const page = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      table.setPageIndex(page);
                    }}
                    style={{
                      // backgroundImage: `url(${arrowDownIcon})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'right 2px center',
                      backgroundSize: '16px 16px',
                      cursor: 'pointer',
                    }}
                    className="inline-block h-[24px] w-[40px] border-none pr-1 text-center text-[14px] focus:border-transparent focus:outline-none focus:ring-0 focus:ring-offset-0"
                  >
                    {Array.from(
                      { length: table.getPageCount() },
                      (_, index) => (
                        <option
                          className="text-center text-white"
                          key={index + 1}
                          value={index + 1}
                        >
                          {index + 1}
                        </option>
                      ),
                    )}
                  </select>
                </div>
              </div>
              <button
                onClick={() => table.nextPage()}
                id="nextPage"
                disabled={!table.getCanNextPage()}
                className={`${!table.getCanNextPage() ? 'opacity-30' : ''}`}
              >
                <NextIcon />
              </button>
              <button
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                disabled={!table.getCanNextPage()}
                className={`${!table.getCanNextPage() ? 'opacity-30' : ''}`}
              >
                <NextMaxIcon />
              </button>
            </div>

            <span className="text-[14px] font-medium">
              {t('tables.showPerPage')}
            </span>
            <div className="dropdown-container text-dark-blue">
              <select
                className=" inline-block h-[28px] w-[63px] rounded-[5px] border border-greenlight pl-2 pr-4 text-center text-[14px] font-normal focus:outline-none focus:ring-0 focus:ring-offset-0"
                value={table.getState().pagination.pageSize}
                onChange={(e) => {
                  table.setPageSize(Number(e.target.value));
                }}
                style={{
                  // backgroundImage: `url(${arrowDownIcon})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'right 10px center',
                  backgroundSize: '16px 16px',
                  cursor: 'pointer',
                }}
              >
                {PAGE_SIZES.map((_pageSize) => (
                  <option
                    className="text-white"
                    key={_pageSize}
                    value={_pageSize}
                  >
                    {_pageSize}
                  </option>
                ))}
              </select>
            </div>
            {loading && <Loader size="size-[20px]" />}
          </div>
        </div>
      )}
    </>
  );
};

export default DataTable;
