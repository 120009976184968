/* eslint-disable max-len */
import { useMutation } from '@apollo/client';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CloseIcon, UploadIcon } from '../../assets';
import { Button } from '../../components/common/Button';
import { TextInput } from '../../components/inputs/TextInput';
import { SET_PIECE_AS_PUBLISHED } from '../../graphql/campaign/mutation';
import { useNotification } from '../../hooks/useNotification';
import type { ConfirmOptions } from '../../types/confirm';

type Props = ConfirmOptions & { open: boolean } & { id: string };

type ColorValues = {
  bgColor: string;
  textColor: string;
  iconColor: string;
};

type NotOptional<T> = T extends undefined ? never : T;

const colorMap: Record<NotOptional<Props['type']>, ColorValues> = {
  error: {
    bgColor: '#213A51',
    iconColor: '#00E7FF',
    textColor: '#FFFFFF',
  },
  info: {
    bgColor: '#213A51',
    iconColor: '#00E7FF',
    textColor: '#FFFFFF',
  },
  success: {
    bgColor: '#213A51',
    iconColor: '#00E7FF',
    textColor: '#FFFFFF',
  },
  warning: {
    bgColor: '#213A51',
    iconColor: '#00E7FF',
    textColor: '#FFFFFF',
  },
  delete: {
    bgColor: '#213A51',
    iconColor: '#00E7FF',
    textColor: '#FFFFFF',
  },
};

export function ConfirmUpload({
  open,
  title,
  subtitle,
  cancelText,
  confirmText,
  onCancel,
  onConfirm,
  type = 'success',
  id,
}: Props) {
  useEffect(() => {
    if (open) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [open]);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [setPieceAsPublished, { loading }] = useMutation(
    SET_PIECE_AS_PUBLISHED,
  );
  const { notifySaveSuccess, notifySaveError } = useNotification();
  const handleSave = (data: any) => {
    if (!id) return;
    setPieceAsPublished({
      variables: {
        pieceId: id,
        link: data.link.startsWith('https://')
          ? data.link
          : `https://${data.link}`,
      },
      onCompleted: () => {
        reset();
        notifySaveSuccess();
        if (onConfirm) onConfirm();
      },
      onError: () => {
        notifySaveError();
      },
    });
  };
  const { t } = useTranslation('common');
  return (
    <dialog
      onClick={onCancel}
      className={`fixed top-0 left-0 z-50 flex-col items-center justify-center size-full overflow-auto bg-black/50 min-h-full ${
        open ? 'flex' : 'hidden'
      }`}
    >
      <div
        className="flex flex-col items-center justify-between w-10/12 p-4 rounded-lg lg:w-5/12"
        onClick={(e) => e.stopPropagation()}
        style={{
          background: colorMap[type].bgColor,
        }}
      >
        <header
          className="flex items-center justify-between w-full pb-2 border-b-2"
          style={{
            color: colorMap[type].textColor,
            borderColor: colorMap[type].textColor,
          }}
        >
          <div className="flex flex-row items-center justify-center gap-x-4">
            <UploadIcon className="text-influentia-light-blue size-[24px]" />
            <label className="text-lg font-bold ">{title}</label>
          </div>
          <CloseIcon className="cursor-pointer" onClick={onCancel} />
        </header>
        <div className="flex items-center justify-center m-auto rounded-lg mt-3">
          <label
            className="text-center"
            style={{
              color: colorMap[type].textColor,
            }}
          >
            {subtitle}
          </label>
        </div>
        <form onSubmit={handleSubmit(handleSave)} className="w-full">
          <TextInput
            register={register}
            registerName="link"
            validation={{ required: t('contentUpload.required') }}
            label={t('contentUpload.placeholder')}
            extraClassname=""
            labelClassname="text-white"
            placeholder={t('contentUpload.placeholder')}
            errors={errors.link}
            type="text"
          />
          <div className="flex items-end justify-end gap-x-4 mt-2">
            <Button
              text={String(cancelText)}
              type="button"
              style="black"
              onClick={onCancel}
            />
            <Button
              text={String(confirmText)}
              type="submit"
              style="light-blue"
              customClassnames={`${type === 'delete' ? '!bg-red-status !text-white' : ''}`}
            />
          </div>
        </form>
      </div>
    </dialog>
  );
}
