import { useMutation, useQuery } from '@apollo/client';
import { useContext, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { RefetchLateral } from '../../../context/refetchLateral/refetchProvider';
import {
  CREATE_RESOURCE,
  UPDATE_CAMPAIGN_RECURRENT,
} from '../../../graphql/campaign/mutation';
import {
  GET_PLATFORMS_RECURRENT_INFO,
  GET_RESOURCES,
} from '../../../graphql/campaign/query';
import { useNotification } from '../../../hooks/useNotification';
import { usePermissions } from '../../../hooks/usePermissions';
import { usePrompt } from '../../../hooks/usePrompt';
import { urlRegex } from '../../../utils/utils';
import { Button } from '../../common/Button';
import FileUploader from '../../common/FileUploader';
import { TextInput } from '../../inputs/TextInput';
import { File } from '../content/File';
import { PlatformInfo } from '../resources/PlatformInfo';
import { Resource } from '../resources/Resource';

export const SingleCampaignRecurrentResourcesTab = () => {
  const [campaignId]: [string] = useOutletContext();
  const { t } = useTranslation('common');
  const [briefing, setBriefing] = useState<any | null>();
  const { refetchLateral, refetchTabs } = useContext(RefetchLateral);
  const [resources, setResources] = useState<any>([]);
  const {
    reset,
    register,
    handleSubmit,
    formState: { dirtyFields, errors },
  } = useForm();
  const [dirty, setDirty] = useState(false);
  const [platformInfo, setPlatformInfo] = useState<any>([]);
  const { refetch } = useQuery(GET_PLATFORMS_RECURRENT_INFO, {
    variables: {
      campaignId,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const info = data.getPlatformRecurrentInfo.data;
      setPlatformInfo(info.platforms);
      setBriefing(
        info.briefing !== null && info.briefing.id !== null
          ? info.briefing
          : null,
      );
      setDirty(false);
    },
  });

  const { notifySaveSuccess, notifySaveError } = useNotification();
  const [updateCampaign, { loading: loadingBriefing }] = useMutation(
    UPDATE_CAMPAIGN_RECURRENT,
  );
  const [createResourceDB, { loading: loadingResource }] =
    useMutation(CREATE_RESOURCE);

  const { refetch: refetchResources } = useQuery(GET_RESOURCES, {
    variables: {
      campaignId,
      recurrent: true,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setResources(data.resources.data);
    },
  });

  const updateCampaignDB = (file?: File) => {
    const headers: any = {
      'x-apollo-operation-name': 'setCampaignSettings',
    };
    if (file) {
      headers['content-type'] = 'multipart/form-data';
    }
    updateCampaign({
      variables: {
        file: file || null,
        campaignId,
      },
      context: {
        headers,
      },
      onCompleted: () => {
        notifySaveSuccess();
        refetch();
        setDirty(false);
        reset({}, { keepValues: true });
        refetchLateral();
        refetchTabs();
      },
      onError: () => {
        notifySaveError();
      },
    });
  };
  const deleteFile = () => {
    updateCampaignDB();
  };

  const createResource = (file?: File) => {
    const headers: any = {
      'x-apollo-operation-name': 'createResource',
    };
    if (file) {
      headers['content-type'] = 'multipart/form-data';
    }

    createResourceDB({
      variables: {
        file,
        campaignId,
        recurrent: true,
      },
      context: {
        headers,
      },
      onCompleted: () => {
        notifySaveSuccess();
        refetchResources();
        refetch();
        setDirty(false);
      },
      onError: () => {
        notifySaveError();
      },
    });
  };

  const createResourceLink: SubmitHandler<any> = (data) => {
    createResourceDB({
      variables: {
        link: data.link,
        linkName: data.linkName,
        campaignId,
        recurrent: true,
      },
      onCompleted: () => {
        notifySaveSuccess();
        refetchResources();
        refetch();
        setDirty(false);
        reset();
      },
      onError: () => {
        notifySaveError();
      },
    });
  };
  const submitFile = (file: File) => {
    updateCampaignDB(file);
  };

  usePrompt({
    isDirty: Object.keys(dirtyFields).length > 0 || dirty,
  });
  const { isInfluencer } = usePermissions();

  return (
    <div className="flex flex-row gap-5 flex-initial">
      <div className="rounded-10 bg-dark-blue p-5 w-full">
        <div className="h-full">
          <span className="font-medium uppercase">
            {t('singleCampaign.platformInfo')}
          </span>
          <div className="h-full">
            {platformInfo &&
              platformInfo.length > 0 &&
              platformInfo.map((platform: any) => (
                <PlatformInfo key={Math.random()} platformInfo={platform} />
              ))}
            {(!platformInfo || platformInfo.length === 0) && (
              <div className="h-full flex items-center justify-center text-center">
                <span className="italic">
                  {t('singleCampaign.noPlatformInfo')}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        className={`flex flex-col ${!isInfluencer() && 'mt-5 gap-5'} w-full `}
      >
        <div className="w-full">
          <div className={`${isInfluencer() && 'hidden'}`}>
            {!briefing && (
              <>
                <label
                  className={`${!isInfluencer() && 'my-2'} block text-[14px] font-[600]`}
                >
                  {t('singleCampaign.uploadBriefing')}
                </label>
                <FileUploader
                  fileType="Briefing"
                  submitFile={submitFile}
                  loading={loadingBriefing}
                  setDirty={setDirty}
                />
              </>
            )}
          </div>
          {briefing && (
            <>
              <label
                className={`${!isInfluencer() && 'my-2'} block text-[14px] font-[600]`}
              >
                {t('singleCampaign.briefing')}
              </label>
              <File
                name={briefing.name}
                fileUrl={briefing.url}
                deleteContent={() => deleteFile()}
              />
            </>
          )}
        </div>
        <div>
          <label className="my-2 block text-[14px] font-[600]">
            {t('singleCampaign.resources')}
          </label>
          <div className="mt-2 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5 gap-2">
            {resources &&
              resources.length > 0 &&
              resources.map((resource: any) => (
                <Resource
                  key={resource.id}
                  resource={resource}
                  refetch={refetchResources}
                />
              ))}
          </div>
          {resources && resources.length === 0 && (
            <div className="italic mt-2 text-xs flex items-center justify-center text-center">
              {t('singleCampaign.noResources')}
            </div>
          )}
        </div>
        <div className={`${isInfluencer() && 'hidden'}`}>
          <label className="my-2 block text-[14px] font-[600]">
            {t('singleCampaign.addResource')}
          </label>
          <div className="w-full">
            <FileUploader
              fileType="Resource"
              loading={loadingResource}
              submitFile={createResource}
              setDirty={setDirty}
            />
            <div className="flex items-center justify-center my-5">
              <div className="h-px w-full bg-dark-blue max-w-[50%]"></div>
            </div>
            <form onSubmit={handleSubmit(createResourceLink)}>
              <TextInput
                register={register}
                registerName="linkName"
                type="text"
                label={t('singleCampaign.linkName')}
                placeholder={t('singleCampaign.linkNamePlaceholder')}
              />
              <div className="mt-3 flex flex-row gap-3">
                <TextInput
                  register={register}
                  registerName="link"
                  type="text"
                  placeholder={t('singleCampaign.placeholderLink')}
                  validation={{
                    pattern: urlRegex,
                    required: true,
                  }}
                  errors={errors.link}
                />
                <Button
                  style="light-blue"
                  text={t('global.post')}
                  // loading={loading}
                  customClassnames="flex items-center justify-center px-10"
                  type="submit"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
