import { useMutation } from '@apollo/client';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { CREATE_TIMELINE } from '../../../graphql/campaign/mutation';
import { useNotification } from '../../../hooks/useNotification';
import type { TimeLineType } from '../../../types';
import { TextArea } from '../../inputs/TextArea';
import { SingleTimeLine } from './SingleTimeLine';

type TimeLineProp = {
  timeline?: TimeLineType[];
  refetch: () => void;
  pieceId: string;
};

export const TimeLine = ({ timeline, refetch, pieceId }: TimeLineProp) => {
  const { t } = useTranslation('common');
  const { notifySaveSuccess, notifySaveError } = useNotification();
  const [createTimeLine] = useMutation(CREATE_TIMELINE);

  const createTime = (description: string) => {
    createTimeLine({
      variables: {
        description,
        pieceId,
      },
      onCompleted: () => {
        notifySaveSuccess();
        refetch();
      },
      onError: () => {
        notifySaveError();
      },
    });
  };

  const scrollRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [timeline]);

  return (
    <div className="bg-influentia-black rounded-10 px-[10px] pt-[10px] h-fit w-full xl:min-w-[500px]">
      {timeline && timeline.length > 0 ? (
        <div
          className="overflow-y-scroll px-1 TimeLine max-h-[450px] min-h-[450px]"
          ref={scrollRef}
        >
          <div className="flex flex-col gap-2 mb-5">
            {timeline?.map((line: TimeLineType) => {
              return (
                <SingleTimeLine
                  key={line.id}
                  id={line.id}
                  type={line.type}
                  description={line.description}
                  user={line.user}
                  automatic={line.automatic}
                  createdAt={Number(line.createdAt)}
                  refetch={refetch}
                />
              );
            })}
          </div>
        </div>
      ) : (
        <div className="size-full min-h-[450px] px-1 items-center justify-center flex italic font-light text-xs">
          {t('singleCampaign.noNotes')}
        </div>
      )}
      <div className="mt-1 shrink-0">
        <TextArea send={createTime} />
      </div>
    </div>
  );
};
