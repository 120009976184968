import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { ROUTES } from '../../config/routes/config';

export const LateralProfileMenu = ({
  user,
  isProfile,
  isBrand,
}: {
  user: any;
  isProfile?: boolean;
  isBrand?: boolean;
}) => {
  const { t } = useTranslation('common');
  return (
    <div className="bg-dark-blue min-w-[230px] flex-none rounded-10 p-2.5 lg:flex hidden sticky top-8 h-[calc(100vh-150px)]">
      <div className="flex flex-col gap-2.5 size-full">
        <NavLink
          key={`${user.id}profile`}
          className={({ isActive }) =>
            `${isActive ? '!bg-influentia-light-blue text-influentia-black' : 'text-white'} text-sm items-center font-semibold  flex flex-row gap-x-2 rounded-[5px] px-4 py-1`
          }
          to={
            isProfile
              ? ROUTES.PROFILE
              : ROUTES.SINGLE_INFLUENCER.replace(':id', user.id)
          }
        >
          {t('profile.menu.title1')}
        </NavLink>
        {isProfile && isBrand && (
          <NavLink
            key={`${user.id}access`}
            className={({ isActive }) =>
              `${isActive ? '!bg-influentia-light-blue text-influentia-black' : 'text-white'} text-sm items-center font-semibold flex flex-row gap-x-2 rounded-[5px] px-4 py-1`
            }
            to={ROUTES.PROFILE_ORG_AND_ACCESS.replace(':id', user.id)}
          >
            {t('profile.menu.title4')}
          </NavLink>
        )}
        {isProfile && (
          <NavLink
            key={`${user.id}notifications`}
            className={({ isActive }) =>
              `${isActive ? '!bg-influentia-light-blue text-influentia-black' : 'text-white'} text-sm items-center font-semibold  flex flex-row gap-x-2 rounded-[5px] px-4 py-1`
            }
            to={ROUTES.PROFILE_NOTIFICATIONS}
          >
            {t('profile.menu.title3')}
          </NavLink>
        )}
        {!isProfile && (
          <NavLink
            key={`${user.id}campaigns`}
            className={({ isActive }) =>
              `${isActive ? '!bg-influentia-light-blue text-influentia-black' : 'text-white'} text-sm items-center font-semibold flex flex-row gap-x-2 rounded-[5px] px-4 py-1`
            }
            to={ROUTES.SINGLE_INFLUENCER_CAMPAIGNS.replace(':id', user.id)}
          >
            {t('profile.menu.title2')}
          </NavLink>
        )}
      </div>
    </div>
  );
};
