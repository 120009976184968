import React from 'react';
import { useTranslation } from 'react-i18next';

import { LockIcon } from '../../../assets';

type DisclaimerProps = {
  disclaimers: {
    id: string;
    platform: string;
    disclaimer: string;
  }[];
};

export const Disclaimer = ({ disclaimers }: DisclaimerProps) => {
  const { t } = useTranslation('common');

  return (
    <>
      {disclaimers && disclaimers.length > 0 ? (
        disclaimers.map((disclaimer, index) => (
          <div key={index} className="flex flex-row gap-[6px]">
            <div className="rounded-full bg-white flex justify-center items-center size-[28px] text-influentia-black">
              <LockIcon />
            </div>
            <div className="flex flex-col gap-[5px]">
              <span className="text-influentia-light-blue font-medium text-[10px]">
                {t('singleCampaign.disclaimer')}
              </span>
              {disclaimer && (
                <span className="font-medium text-sm text-white">
                  {disclaimer.disclaimer}
                </span>
              )}
            </div>
          </div>
        ))
      ) : (
        <div className="flex flex-row gap-[6px]">
          <div className="rounded-full bg-white flex justify-center items-center size-[28px] text-influentia-black">
            <LockIcon />
          </div>
          <div className="flex flex-col gap-[5px]">
            <span className="text-influentia-light-blue font-medium text-[10px]">
              {t('singleCampaign.disclaimer')}
            </span>
            <div className="flex flex-col gap-[5px]">
              <span className="text-[10px] text-white italic">
                {t('singleCampaign.noDisclaimer')}
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
