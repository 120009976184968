import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CloseIcon } from '../../assets';
import type {
  BrandsFilters,
  CampaignFilters,
  InfluencersFilters,
  InfluentiaUsersFilters,
  InvoicesFilters,
} from '../../types';
import { formatNumberMoney } from '../../utils/numberFormatter';

type SingleFilterProps = {
  title: string;
  value: string;
  removeFilter: () => void;
};

const SingleFilter = ({ title, value, removeFilter }: SingleFilterProps) => {
  return (
    <div className="flex px-2 rounded-md flex-row gap-x-2 text-sm bg-influentia-light-blue text-dark-blue items-center justify-center font-medium">
      <span className="capitalize">
        {title}
        {': '}
        {value}
      </span>
      <button onClick={removeFilter}>
        <CloseIcon />
      </button>
    </div>
  );
};

type AllFilters = CampaignFilters &
  InfluencersFilters &
  BrandsFilters &
  InfluentiaUsersFilters &
  InvoicesFilters;

type ActiveFiltersProps = {
  setFilters: (filters: any) => void;
  filters: AllFilters;
  type?: string;
};

const EXCLUDE = ['orderDirection', 'orderBy'];

export const ActiveFilters = ({
  setFilters,
  filters,
  type,
}: ActiveFiltersProps) => {
  const { t } = useTranslation('common');
  const formatValue = (key: keyof AllFilters) => {
    switch (key) {
      case 'status':
        return filters[key]?.map((s) => t(`${type}Status.${s}`)).join(', ');
      case 'platforms':
        return filters[key]?.join(', ');
      case 'fromMoney':
      case 'toMoney':
      case 'minPrice':
      case 'maxPrice':
        return formatNumberMoney(filters[key] as number);
      case 'fromBilling':
      case 'toBilling':
      case 'fromPayment':
      case 'toPayment':
        return dayjs(filters[key]).format('DD/MM/YYYY');
      case 'client':
      case 'influencers':
        return filters[key]?.map((i) => i.label).join(', ');
      default:
        return filters[key] as string;
    }
  };
  const hasValue = (key: keyof AllFilters) => {
    if (EXCLUDE.includes(key)) return false;
    const f: any = { ...filters };
    switch (key) {
      case 'platforms':
      case 'status':
        return !!(f[key] && f[key].length);
      default:
        return !!filters[key];
    }
  };

  const removeFilter = (key: string) => {
    const newFilters = { ...filters };
    newFilters[key as keyof AllFilters] = undefined;
    setFilters(newFilters);
  };

  return (
    <div className="flex flex-row gap-x-2">
      {Object.keys(filters).map((k) => (
        <React.Fragment key={k + Math.random()}>
          {hasValue(k as keyof AllFilters) &&
            k !== 'name' &&
            k !== 'campaignName' && (
              <SingleFilter
                removeFilter={() => removeFilter(k)}
                title={t(`filters.${k}`)}
                value={formatValue(k as keyof AllFilters) as string}
              />
            )}
        </React.Fragment>
      ))}
    </div>
  );
};
