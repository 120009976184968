import 'dayjs/locale/es';

import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { InfluencersIcon } from '../assets';
import { Breadcrumbs } from '../components/common/Breadcrumbs';
import { ActiveFilters } from '../components/filters/ActiveFilters';
import { InfluencersFiltersPopover } from '../components/filters/InfluencerFilters';
import { TableSearchInput } from '../components/inputs/TableSearchInput';
import { CreateInfluencer } from '../components/popups/CreateInfluencer';
import DataTable from '../components/tables/DataTable';
import { getInfluencersTableHeaders } from '../components/tables/headers/influencers';
import { ROLES } from '../config/constants';
import { replaceIds, ROUTES } from '../config/routes/config';
import { GET_USERS } from '../graphql/user/query';
import { useTableDataAndFilters } from '../hooks/useTableDataAndFilters';
import { influencersFilterAtom } from '../states/atoms';
import type {
  InfluencersFilters,
  InfluencersTable,
  ResponseDataUsersList,
} from '../types';

dayjs.extend(localizedFormat);
dayjs.locale('es');

function Influencers() {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  /**
   * Hook in charge of the logic of the data, filtering and pagination
   */
  const {
    resetPagination,
    total,
    filters,
    setFilters,
    table,
    error,
    loading,
    refetch,
  } = useTableDataAndFilters<
    InfluencersFilters,
    InfluencersTable,
    {},
    ResponseDataUsersList
  >({
    filterAtom: influencersFilterAtom,
    getColumns: getInfluencersTableHeaders,
    columnsExtras: {},
    responseAccessKey: 'users',
    query: GET_USERS,
    extraVariables: {
      role: ROLES.influencer,
    },
  });

  /**
   * Handles click on any row of the table and navigates to single influencer
   * @param id id of the row clicked
   */
  const handleTableClick = (id: string) => {
    navigate(
      replaceIds({
        url: ROUTES.SINGLE_INFLUENCER,
        params: [{ id }],
      }),
    );
  };

  return (
    <div className="w-full flex flex-col gap-y-5">
      <Breadcrumbs
        icon={<InfluencersIcon />}
        breadcrumbs={[
          {
            text: t('influencers.title'),
          },
        ]}
      />
      <div className="flex flex-col gap-y-4">
        <div className="flex flex-row justify-between">
          <div>
            <TableSearchInput
              placeholder={t('influencers.searchInputPlaceholder')}
              filters={{ ...filters }}
              setFilters={(val) => {
                setFilters(val);
                resetPagination();
              }}
              searchKey="name"
            />
          </div>
          <div className="flex flex-row gap-x-2">
            <InfluencersFiltersPopover
              setFilters={(val) => {
                setFilters(val);
                resetPagination();
              }}
              filters={filters}
            />
            <CreateInfluencer refetch={refetch} />
          </div>
        </div>
        <ActiveFilters filters={filters} setFilters={setFilters} />
        <DataTable
          error={error}
          handleRowClick={(id) => handleTableClick(id)}
          loading={loading}
          table={table}
          total={total}
          tableId="InfluencersTable"
          sorting={{ ...filters }}
        />
      </div>
    </div>
  );
}

export default Influencers;
