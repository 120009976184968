import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';

import { CopyIcon } from '../../assets';

type CopyButtonProps = {
  text: string;
};

export const CopyButton = ({ text }: CopyButtonProps) => {
  const { t } = useTranslation('common');
  const [open, setIsOpen] = useState(false);
  const id = `tooltip-copy-button${Math.random()}`;
  return (
    <>
      <button
        onClick={() => {
          navigator.clipboard.writeText(text);
          setIsOpen(true);
          setTimeout(() => setIsOpen(false), 2000);
        }}
        data-tooltip-id={id}
        data-tooltip-content={t('global.copied')}
        className="text-white hover:!text-influentia-light-blue"
      >
        <CopyIcon className="size-[16px]" />
      </button>
      <Tooltip
        className="!bg-influentia-light-blue !text-dark-blue font-montserrat !p-1 !text-sm"
        noArrow
        id={id}
        place="bottom"
        isOpen={open}
      />
    </>
  );
};
