import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FilterIcon } from '../../assets';
import type { InvoicesFilters } from '../../types';
import { Button } from '../common/Button';
import { DatePickerInput } from '../inputs/DatePicker';
import { MoneyInput } from '../inputs/MoneyInput';
import { FiltersPopover } from './FiltersPopover';

type BrandInvoicesFiltersProps = {
  setFilters: (val: InvoicesFilters) => void;
  filters: InvoicesFilters;
};

export const BrandInvoicesFilters = ({
  setFilters,
  filters,
}: BrandInvoicesFiltersProps) => {
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);

  const { handleSubmit, reset, control } = useForm<InvoicesFilters>({
    defaultValues: { ...filters },
  });

  useEffect(() => {
    reset(filters);
  }, [filters]);

  const handleSearch = (data: InvoicesFilters) => {
    setFilters({
      ...data,
      toMoney: data.toMoney !== undefined ? Number(data.toMoney) : undefined,
      fromMoney:
        data.fromMoney !== undefined ? Number(data.fromMoney) : undefined,
    });
    setOpen(false);
  };

  const onReset = () => {
    setFilters({});
    reset({});
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        style="light-blue"
        text=""
        iconLeft={<FilterIcon />}
      />
      {open && (
        <FiltersPopover
          title={t('invoices.brandFilterTitle')}
          closeModal={() => setOpen(false)}
          resetFilter={onReset}
          onSubmit={handleSubmit(handleSearch)}
        >
          <div className="flex flex-row gap-x-2">
            <MoneyInput
              label={t('invoices.brandFilterFromMoney')}
              placeholder={t('invoices.brandFilterFromMoney')}
              control={control}
              name={'fromMoney'}
            />
            <MoneyInput
              label={t('invoices.brandFilterToMoney')}
              placeholder={t('invoices.brandFilterToMoney')}
              control={control}
              name={'toMoney'}
            />
          </div>
          <div className="flex flex-row gap-x-2">
            <DatePickerInput
              control={control}
              label={t('invoices.brandFilterFromBilling')}
              name="fromBilling"
            />
            <DatePickerInput
              label={t('invoices.brandFilterToBilling')}
              control={control}
              name="toBilling"
            />
          </div>
          <div className="flex flex-row gap-x-2">
            <DatePickerInput
              control={control}
              label={t('invoices.brandFilterFromPayment')}
              name="fromPayment"
            />
            <DatePickerInput
              label={t('invoices.brandFilterToPayment')}
              control={control}
              name="toPayment"
            />
          </div>
        </FiltersPopover>
      )}
    </>
  );
};
