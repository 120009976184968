import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FilterIcon } from '../../assets';
import { PLATFORMS } from '../../config/constants';
import type { InfluencersFilters } from '../../types';
import { Button } from '../common/Button';
import { GenericDropdown } from '../inputs/GenericDropdown';
import { FiltersPopover } from './FiltersPopover';

type InfluencersFiltersProps = {
  setFilters: (val: InfluencersFilters) => void;
  filters: InfluencersFilters;
};

export const InfluencersFiltersPopover = ({
  setFilters,
  filters,
}: InfluencersFiltersProps) => {
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);

  const { control, handleSubmit, reset } = useForm<InfluencersFilters>({
    defaultValues: { ...filters },
  });

  useEffect(() => {
    reset(filters);
  }, [filters]);

  const handleSearch = (data: InfluencersFilters) => {
    setFilters({
      ...data,
    });
    setOpen(false);
  };

  const onReset = () => {
    setFilters({});
    reset({});
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        style="light-blue"
        text=""
        iconLeft={<FilterIcon />}
      />
      {open && (
        <FiltersPopover
          title={t('influencers.filtersTitle')}
          closeModal={() => setOpen(false)}
          resetFilter={onReset}
          onSubmit={handleSubmit(handleSearch)}
        >
          <form
            className="flex flex-col gap-y-5"
            onSubmit={handleSubmit(handleSearch)}
          >
            <GenericDropdown
              isMulti
              control={control}
              name="platforms"
              label={t('influencers.platformsFilter')}
              placeholder={t('influencers.platformsFilterPlaceholder')}
              options={[
                ...PLATFORMS.map((p) => ({
                  value: p,
                  label: p,
                })),
              ]}
            />
          </form>
        </FiltersPopover>
      )}
    </>
  );
};
