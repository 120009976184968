import { Auth0Provider } from '@auth0/auth0-react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { datadogRum } from '@datadog/browser-rum';
import i18next from 'i18next';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { RecoilRoot } from 'recoil';

import App from './App';
import ApolloWrapper from './config/ApolloWrapper';
import { ConfirmProvider } from './context/confirm/confirm.provider';
import common_de from './translations/de/common.json';
import common_en from './translations/en/common.json';
import common_es from './translations/es/common.json';

declare global {
  interface Window {
    installedApp: boolean;
  }
}

datadogRum.init({
  applicationId: process.env.REACT_APP_DATADOG_APPID || '',
  clientToken: process.env.REACT_APP_DATADOG_TOKEN || '',
  // `site` refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  site: 'datadoghq.eu',
  service: 'influentia-portal',
  env: process.env.NODE_ENV,
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 70,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
});

i18next.init({
  interpolation: {
    escapeValue: false,
  },
  lng: 'es',
  resources: {
    es: {
      common: common_es,
    },
    en: {
      common: common_en,
    },
    de: {
      common: common_de,
    },
  },
});

if ('serviceWorker' in navigator && 'PushManager' in window) {
  navigator.serviceWorker
    .register('/sw.js')
    .then(function (registration) {
      console.log('Service Worker registered');
    })
    .catch(function (error) {
      console.log('Service Worker registration failed:', error);
    });
}

// Adding global beforeinstallprompt event listener
window.deferredPrompt = null;
window.installedApp = false;

const ready = (e: Event) => {
  e.preventDefault();
  window.deferredPrompt = e;
};

window.addEventListener('beforeinstallprompt', ready);

window.addEventListener('appinstalled', () => {
  window.deferredPrompt = null; // Clear the deferred prompt after installation
  window.installedApp = true;
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
      authorizationParams={{
        redirect_uri: process.env.REACT_APP_AUTH0_REDIRECT_URI || '',
        audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
      }}
      cacheLocation="localstorage"
    >
      <RecoilRoot>
        <ApolloWrapper>
          <I18nextProvider i18n={i18next}>
            <ConfirmProvider>
              <App />
            </ConfirmProvider>
          </I18nextProvider>
        </ApolloWrapper>
      </RecoilRoot>
    </Auth0Provider>
  </React.StrictMode>,
);

window.addEventListener('unload', () => {
  window.removeEventListener('beforeinstallprompt', ready);
});
