type LoaderProps = {
  size?: 'size-[30px]' | 'size-[80px]' | 'size-[20px]';
  color?: string; // Add color prop
};

const BORDERS = ['border-4', 'border-2'];

const Loader = ({ size = 'size-[30px]', color = '' }: LoaderProps) => {
  const border = size === 'size-[80px]' ? BORDERS[0] : BORDERS[1];
  return (
    <div
      className={`inline-block ${size} ${border} ${color} animate-spin rounded-full border-solid border-current border-e-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]`}
      role="status"
    >
      <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)] ">
        {/* Loading... */}
      </span>
    </div>
  );
};

export default Loader;
