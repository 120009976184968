import { useRecoilValue } from 'recoil';

import { ROLES } from '../config/constants';
import { userAtom } from '../states/atoms';

type RoleType = typeof ROLES;

export const usePermissions = () => {
  const user = useRecoilValue(userAtom);

  const userLoaded = !!user.id;

  const checkRole = (role: keyof RoleType) => {
    return user.role.includes(role.valueOf());
  };

  const isInfluencer = () => user?.role?.includes(ROLES.influencer);
  const isInfluentia = () => user?.role?.includes(ROLES.influentia);
  const isBrand = () => user?.role?.includes(ROLES.brand);

  return {
    isInfluencer,
    isInfluentia,
    isBrand,
    checkRole,
    userLoaded,
  };
};
