import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { components } from 'react-select';

import { PauseIcon, PlayIcon } from '../../assets';
import { Confirm } from '../../context/confirm/confirm';
import {
  UPDATE_CAMPAIGN_KEY,
  UPDATE_CAMPAIGN_RECURRENT_KEY,
} from '../../graphql/campaign/mutation';
import { useNotification } from '../../hooks/useNotification';
import { usePermissions } from '../../hooks/usePermissions';
import type { CampaignStatusType } from '../../types';
import { StatusText } from '../common/StatusText';

const STATUS_COLOR = {
  facturation: '!bg-violet-status !text-white',
  pending: '!bg-gray-status !text-dark-blue',
  declined: '!bg-red-status !text-white',
  active: '!bg-blue-status !text-white',
  canceled: '!bg-red-status !text-white',
  finished: '!bg-green-status !text-dark-blue',
};

interface CampaignStatusDropdownProps {
  defaultStatus: CampaignStatusType;
  onChange?: (status: CampaignStatusType) => void;
  campaignId: string;
  refetch?: () => void;
  refetchTabs?: () => void;
  recurrent?: boolean;
  campaignPaused?: boolean;
}

const CampaignStatusDropdown = ({
  defaultStatus,
  onChange,
  campaignId,
  refetch,
  refetchTabs,
  recurrent,
  campaignPaused,
}: CampaignStatusDropdownProps) => {
  const { t } = useTranslation('common');
  const [selectedStatus, setSelectedStatus] = useState(defaultStatus);
  const { isInfluentia } = usePermissions();
  const statusOptions = Object.keys(STATUS_COLOR).map((status) => ({
    value: status,
    label: t(`campaignStatus.${status}`),
  }));
  const [paused, setPaused] = useState(campaignPaused);
  const [open, setOpen] = useState(false);
  const { notifySaveSuccess, notifySaveError } = useNotification();
  const [updateCampaign] = useMutation(UPDATE_CAMPAIGN_KEY);
  const [updateCampaignRecurrent] = useMutation(UPDATE_CAMPAIGN_RECURRENT_KEY);

  const handlePause = () => {
    updateCampaignRecurrent({
      variables: {
        key: 'paused',
        value: String(!paused),
        campaignId,
      },
    })
      .then(() => {
        notifySaveSuccess();
        if (refetch) refetch();
        if (refetchTabs) refetchTabs();
      })
      .catch(() => {
        notifySaveError();
      });
  };

  const handleSave = async (status: string) => {
    if (!recurrent) {
      updateCampaign({
        variables: {
          key: 'status',
          value: status,
          campaignId,
        },
      })
        .then(() => {
          notifySaveSuccess();
          if (refetch) refetch();
          if (refetchTabs) refetchTabs();
        })
        .catch(() => {
          notifySaveError();
        });
    } else {
      updateCampaignRecurrent({
        variables: {
          key: 'status',
          value: status,
          campaignId,
        },
      })
        .then(() => {
          notifySaveSuccess();
          if (refetch) refetch();
          if (refetchTabs) refetchTabs();
        })
        .catch(() => {
          notifySaveError();
        });
    }
  };

  useEffect(() => {
    setSelectedStatus(defaultStatus);
    setPaused(campaignPaused);
  }, [defaultStatus, campaignPaused]);

  const handleStatusChange = (
    selectedOption: {
      value: string;
      label: string;
    } | null,
  ) => {
    if (selectedOption) {
      const newStatus = selectedOption.value as CampaignStatusType;
      setSelectedStatus(newStatus);
      if (onChange) {
        onChange(newStatus);
      }
      handleSave(newStatus);
    }
  };

  const customStyles = {
    menuList: (base: any) => ({
      ...base,
      padding: 0,
      borderRadius: '4px',
      '::-webkit-scrollbar': {
        width: '6px',
        height: '0px',
      },
      '::-webkit-scrollbar-track': {
        background: 'white',
        borderRadius: '20px',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#16162C',
        borderRadius: '20px',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: '#16162C',
        borderRadius: '20px',
      },
    }),
    option: (base: any, props: any) => ({
      ...base,
      background: props.isSelected ? '#00E7FF' : 'white',
      ':hover': {
        border: 'none',
        boxShadow: 'none',
        background: props.isSelected ? '#00E7FF' : '#00E7FF',
      },
    }),
  };
  const CustomOption = (props: any) => (
    <components.Option
      className="bg-white hover:!bg-dark-blue hover:!text-white !text-dark-blue"
      {...props}
    >
      <div className="flex items-center justify-center">
        <div
          className={`flex flex-row items-center justify-center gap-x-2 !cursor-pointer text-center rounded-10 px-7 py-1`}
        >
          <div
            className={`size-[10px] flex-none rounded-full ${STATUS_COLOR[props.data.value as keyof typeof STATUS_COLOR]}`}
          />
          <div className="items-center text-center text-[14px] font-medium">
            {props.data.label}
          </div>
        </div>
      </div>
    </components.Option>
  );
  return (
    <>
      <Confirm
        open={open}
        onConfirm={() => handlePause()}
        setOpen={setOpen}
        type="info"
        title={
          paused
            ? t('global.titlePlayCampaign')
            : t('global.titlePauseCampaign')
        }
        subtitle={
          paused
            ? t('global.subTitlePlayCampaign')
            : t('global.subTitlePauseCampaign')
        }
        confirmText={
          paused ? t('global.confirmPlay') : t('global.confirmPause')
        }
        cancelText={t('global.cancel')}
      />
      {!campaignPaused && (
        <div className="relative inline-block">
          <div className="flex flex-row gap-2">
            <Select
              value={statusOptions.find(
                (option) => option.value === selectedStatus,
              )}
              onChange={handleStatusChange}
              options={statusOptions}
              styles={customStyles}
              components={{
                Option: CustomOption,
              }}
              classNames={{
                control: () =>
                  `!border-none !shadow-none rounded-md !w-full ${recurrent ? 'min-w-[200px]' : 'min-w-[228px]'} text-center ${STATUS_COLOR[selectedStatus as keyof typeof STATUS_COLOR]}`,
                singleValue: () =>
                  `${STATUS_COLOR[selectedStatus as keyof typeof STATUS_COLOR]}`,
                indicatorSeparator: () => '!hidden',
                dropdownIndicator: () => '!hidden',
                indicatorsContainer: () => '!hidden',
              }}
              isDisabled={!isInfluentia()}
              classNamePrefix="react-select"
              isSearchable={false}
            />
            <button
              onClick={() => setOpen(true)}
              className={`${!recurrent && 'hidden'}`}
            >
              <div className="size-[26px] rounded-full bg-red-status flex items-center justify-center">
                <PauseIcon className="size-[14px]" />
              </div>
            </button>
          </div>
        </div>
      )}
      {campaignPaused && recurrent && (
        <div className="flex flex-row gap-2">
          <StatusText
            extraClassnames="px-12 min-h-[38px]"
            type="action"
            status={'paused'}
          />
          <button onClick={() => setOpen(true)}>
            <div className="size-[26px] rounded-full bg-influentia-light-blue flex items-center justify-center">
              <PlayIcon className="size-[14px]" />
            </div>
          </button>
        </div>
      )}
    </>
  );
};

export default CampaignStatusDropdown;
