import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BrandsIcon } from '../../assets';
import { Button } from '../common/Button';
import { BrandPopUp } from './BrandPopUp';

export const CreateBrand = ({ refetch }: { refetch: any }) => {
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        id="createBrand"
        style="light-blue"
        text={t('brands.createBrand')}
        iconLeft={<BrandsIcon />}
      />
      {open && <BrandPopUp setOpen={setOpen} refetch={refetch} />}
    </>
  );
};
