import { useMutation } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import {
  AppNotificationIcon,
  MailNotificationIcon,
  PushNotificationIcon,
} from '../../assets';
import { UPDATE_PROFILE_NOTIFICATION } from '../../graphql/user/mutation';
import { userAtom } from '../../states/atoms';
import { Toggle } from '../inputs/Toggle';

interface NotificationConfig {
  type: string;
  app: boolean;
  email: boolean;
  push: boolean;
}

interface NotificationGroupProps {
  groupTitle: string;
  notifications: {
    [key: string]: {
      roles: string[];
    };
  };
  userNotifications: NotificationConfig[];
  refetch: () => void;
}

const NotificationGroup: React.FC<NotificationGroupProps> = ({
  groupTitle,
  notifications,
  userNotifications,
  refetch,
}) => {
  const { t } = useTranslation('common');
  const user = useRecoilValue(userAtom);
  const [updateNotification] = useMutation(UPDATE_PROFILE_NOTIFICATION);

  const handleUpdate = async (type: string, key: string, value: boolean) => {
    await updateNotification({
      variables: {
        type,
        key,
        value,
      },
      onCompleted: () => {
        refetch();
      },
    });
  };

  const handleApp = async (type: string, value: boolean) => {
    await handleUpdate(type, 'app', value);
  };
  const handlePush = async (type: string, value: boolean) => {
    await handleUpdate(type, 'push', value);
  };
  const handleEmail = async (type: string, value: boolean) => {
    await handleUpdate(type, 'email', value);
  };

  // Filter notifications based on user's role
  const filteredNotifications = Object.keys(notifications).filter(
    (notification) => {
      const { roles } = notifications[notification];
      return roles.includes(user.role);
    },
  );

  // If there are no notifications for this user, don't render the group
  if (filteredNotifications.length === 0) {
    return null;
  }

  return (
    <>
      <div className="grid grid-cols-4 gap-4 mt-5 items-center">
        <span className="col-span-1">
          {t(`notifications.types.${groupTitle}`)}
        </span>
        <div className="md:flex hidden gap-1 col-span-1 items-center justify-center">
          <AppNotificationIcon />
          <span>In-App</span>
        </div>
        <div className="md:flex hidden gap-1 col-span-1 items-center justify-center">
          <PushNotificationIcon />
          <span>Push</span>
        </div>
        <div className="md:flex hidden gap-1 col-span-1 items-center justify-center">
          <MailNotificationIcon />
          <span>Email</span>
        </div>
      </div>
      <div className="mt-2 md:pl-5 pl-0">
        {filteredNotifications.map((notification) => {
          // Find the corresponding user notification config
          const userNotification = userNotifications.find(
            (userNotif) => userNotif.type === notification,
          );
          // Default values if the notification isn't found in userNotifications
          const appEnabled = userNotification?.app ?? true;
          const emailEnabled = userNotification?.email ?? true;
          const pushEnabled = userNotification?.push ?? true;

          return (
            <div
              key={notification}
              className="grid md:grid-cols-4 md:gap-4 gap-1 mt-2 items-center"
            >
              <span className="col-span-1 text-[14px]">
                {t(`notifications.types.${notification}`)}
              </span>
              {/* Phone toggles */}
              <div className="col-span-3 md:hidden grid grid-cols-3 gap-4">
                <div className="flex items-center gap-3">
                  <div>
                    <AppNotificationIcon className="size-[20px]" />
                  </div>
                  <Toggle
                    minWidth=""
                    value={appEnabled}
                    onChange={(value: boolean) => {
                      handleApp(notification, value);
                    }}
                  />
                </div>
                <div className="flex items-center gap-3">
                  <div>
                    <PushNotificationIcon className="size-[20px]" />
                  </div>
                  <Toggle
                    minWidth=""
                    value={pushEnabled}
                    onChange={(value: boolean) => {
                      handlePush(notification, value);
                    }}
                  />
                </div>
                <div className="flex items-center gap-3">
                  <div>
                    <MailNotificationIcon />
                  </div>
                  <Toggle
                    minWidth=""
                    value={emailEnabled}
                    onChange={(value: boolean) => {
                      handleEmail(notification, value);
                    }}
                  />
                </div>
              </div>
              {/* Desktop toggles */}
              <div className="col-span-1 hidden md:flex items-center justify-center">
                <Toggle
                  value={appEnabled}
                  onChange={(value: boolean) => {
                    handleApp(notification, value);
                  }}
                />
              </div>
              <div className="col-span-1 hidden md:flex items-center justify-center">
                <Toggle
                  value={pushEnabled}
                  onChange={(value: boolean) => {
                    handlePush(notification, value);
                  }}
                />
              </div>
              <div className="col-span-1 hidden md:flex items-center justify-center">
                <Toggle
                  value={emailEnabled}
                  onChange={(value: boolean) => {
                    handleEmail(notification, value);
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default NotificationGroup;
