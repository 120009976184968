import { type ChangeEvent, useRef } from 'react';

type UseChangeWithDebounceProps = {
  setFilterValue: (val: string) => void;
  filters: {
    [key: string]: any;
  };
  setFilters: (val: any) => void;
  searchKey: string;
};

export const useChangeWithDebounce = ({
  setFilterValue,
  filters,
  setFilters,
  searchKey,
}: UseChangeWithDebounceProps) => {
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);
  const handleFilterChangeWithDebounce = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const inputValue = event.target.value;
    setFilterValue(inputValue);

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      let newFilters: Record<string, any> = {};

      newFilters = { ...filters };

      if (inputValue) {
        newFilters[searchKey] = inputValue;
      } else {
        newFilters[searchKey] = null;
      }

      setFilters(newFilters);
    }, 500);
  };
  return {
    handleFilterChangeWithDebounce,
  };
};
