import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { ActionsIcon, DefaultInfluencerIcon, TrashIcon } from '../../../assets';
import { Confirm } from '../../../context/confirm/confirm';
import { DELETE_TIMELINE } from '../../../graphql/campaign/mutation';
import { useNotification } from '../../../hooks/useNotification';
import { userAtom } from '../../../states/atoms';
import { formatDate } from '../../../utils/numberFormatter';

type SingleTimeLineProps = {
  id: string;
  description: string;
  type: string;
  user: {
    user_id: string;
    name: string;
    profile_picture: string;
  };
  automatic: boolean;
  createdAt: number;
  refetch: () => void;
};

export const SingleTimeLine = ({
  id,
  description,
  user,
  type,
  createdAt,
  automatic,
  refetch,
}: SingleTimeLineProps) => {
  const me = useRecoilValue(userAtom);
  const [deleteIconState, setDeleteIconState] = useState(false);
  const [deleteTimeLine] = useMutation(DELETE_TIMELINE);
  const { notifyDeleteOk, notifyDeleteError } = useNotification();
  const deleteTime = () => {
    deleteTimeLine({
      variables: {
        timelineId: id,
      },
      onCompleted: () => {
        notifyDeleteOk();
        refetch();
      },
      onError: () => {
        notifyDeleteError();
      },
    });
  };
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('common');
  return (
    <>
      <Confirm
        open={open}
        onConfirm={() => deleteTime()}
        setOpen={setOpen}
        type="delete"
        title={t('global.deleteTitle')}
        subtitle={t('global.deleteSubtitle')}
        confirmText={t('global.delete')}
        cancelText={t('global.cancel')}
      />

      {me && me.id === user.user_id && !automatic && (
        <div className="bg-[#ffffff33] rounded-[5px] flex flex-row-reverse items-center p-[5px] gap-1.5">
          <div className="flex flex-col tracking-influentia min-w-[300px] w-full">
            <div className="flex flex-row-reverse gap-[10px] items-center mb-1 ">
              {user && !user.profile_picture && (
                <DefaultInfluencerIcon className="size-[24px]" />
              )}
              {user && user.profile_picture && (
                <img
                  src={`${process.env.REACT_APP_AWS_PUBLIC_ENDPOINT}/${user.profile_picture}`}
                  className={`rounded-full size-[24px] object-cover`}
                />
              )}
              <span className="font-bold text-sm">{user.name}</span>
              <span className="text-[10px]">
                {formatDate(Number(createdAt))}
              </span>
            </div>
            <div className="flex mt-[5px] text-end w-full items-end justify-end">
              <p className="text-xs">{description}</p>
            </div>
          </div>
          <div className="relative flex items-center justify-center">
            <button
              onClick={() => setDeleteIconState(!deleteIconState)}
              className=""
            >
              <ActionsIcon />
            </button>
            <button
              onClick={() => setOpen(true)}
              className={`absolute left-0 top-5 bg-white h-[25px] rounded-[5px] border border-greenlight p-2 items-center justify-center ${
                deleteIconState ? 'flex' : 'hidden'
              }`}
            >
              <div className="flex flex-row gap-1 text-influentia-black">
                <TrashIcon className="size-[16px]" />
                <span className="text-sm font-bold">Delete</span>
              </div>
            </button>
          </div>
        </div>
      )}
      {me && me.id !== user.user_id && !automatic && (
        <div className="flex flex-col tracking-influentia min-w-[300px] p-[5px]">
          <div className="flex flex-row gap-[10px] items-center mb-1">
            {user && !user.profile_picture && (
              <DefaultInfluencerIcon className="size-[24px]" />
            )}
            {user && user.profile_picture && (
              <img
                src={`${process.env.REACT_APP_AWS_PUBLIC_ENDPOINT}/${user.profile_picture}`}
                className={`rounded-full size-[24px] object-cover`}
              />
            )}
            <span className="font-bold text-sm">{user.name}</span>
            <span className="text-[10px]">{formatDate(Number(createdAt))}</span>
          </div>
          <div className="flex flex-col mt-[5px]">
            <p className="text-xs ">{description}</p>
          </div>
        </div>
      )}
      {automatic && (
        <div className="justify-between items-center inline-flex font-light leading-normal tracking-tight text-white">
          <div className="text-xs">
            {user.name}{' '}
            <span className="lowercase">{t(`timeline.${type}`)}</span>
          </div>
          <div className="text-center text-[10px]">
            {formatDate(Number(createdAt))}
          </div>
        </div>
      )}
    </>
  );
};
