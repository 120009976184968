import type { DocumentNode } from '@apollo/client';
import { useQuery } from '@apollo/client';
import type {
  ColumnDef,
  ColumnFiltersState,
  ColumnHelper,
  ExpandedState,
  PaginationState,
  SortingState,
} from '@tanstack/react-table';
import {
  createColumnHelper,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import type { TFunction } from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { RecoilState } from 'recoil';
import { useRecoilState } from 'recoil';

import { DEFAULT_PAGE_SIZE } from '../config/constants';
import { removePagination } from '../utils/filters';

interface GenericFiltersType {
  pageSize?: number;
  pageIndex?: number;
  orderDirection?: 'ASC' | 'DESC' | null;
  orderBy?: string | null;
}

interface GenericResType {
  [k: string]: {
    count: number;
    data: any;
    error: string;
    code: number;
  };
}

export const useTableDataAndFilters = <
  FilterType extends GenericFiltersType,
  TableType,
  ExtraColumnsType,
  QueryResType extends GenericResType,
>({
  filterAtom,
  getColumns,
  columnsExtras,
  responseAccessKey,
  query,
  extraVariables,
  subrowAccesor,
}: {
  filterAtom: RecoilState<any | null>;
  getColumns: ({
    columnHelper,
    handleChangeSortDirection,
    t,
    extras,
  }: {
    columnHelper: ColumnHelper<TableType>;
    handleChangeSortDirection: (columnName: string) => void;
    t: TFunction;
    extras: ExtraColumnsType;
  }) => ColumnDef<TableType, any>[];
  columnsExtras?: ExtraColumnsType;
  responseAccessKey: string;
  query: DocumentNode;
  extraVariables?: {
    [k: string]: string | number | string[] | number[] | boolean;
  };
  subrowAccesor?: string;
}) => {
  // Translations needed for table header titles
  const { t } = useTranslation('common');

  // Custom loading state to add 0.5s delay on loading state
  // when query complete
  const [loading, setLoading] = useState(false);

  // Need these 3 states for table component and funcionalities
  // Sorting and filters are saved also on "filters" with ATOM to
  // keep the filtering and pagination across pages
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [expanded, setExpanded] = useState<ExpandedState>({});

  // Column helper to create the headings and columns of the table
  const columnHelper = createColumnHelper<TableType>();

  // Data and total count
  const [data, setData] = useState<TableType[]>([]);
  const [total, setTotal] = useState<number>(0);

  // Filters states for managing the filtering to the API and mantaining
  // the filters along different views and routes with Atom
  const [filtersAtom, setFiltersAtom] = useRecoilState(filterAtom);
  const [filters, setFilters] = useState<FilterType>({
    ...(removePagination(filtersAtom || {}) as FilterType),
  });
  // Pagination
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: filtersAtom?.pageIndex || 0,
    pageSize: filtersAtom?.pageSize || DEFAULT_PAGE_SIZE,
  });

  // Reset pagination to page 0
  const resetPagination = () => {
    setPagination({
      pageIndex: 0,
      pageSize,
    });
  };

  // Update atom state on filters or pagination change to mantain the filters
  // along different views and routes
  useEffect(() => {
    setFiltersAtom({
      ...filters,
      pageIndex,
      pageSize,
    });
  }, [filters, pageIndex, pageSize]);

  // Handle change on sort
  const handleChangeSortDirection = (columnName: any) => {
    setFilters((prev) => ({
      ...prev,
      orderDirection: prev?.orderDirection === 'DESC' ? 'ASC' : 'DESC',
      orderBy: columnName,
    }));
  };

  const {
    loading: loadingQuery,
    error,
    refetch,
  } = useQuery<QueryResType>(query, {
    variables: {
      ...extraVariables,
      ...filters,
      offset: pageIndex * pageSize,
      limit: pageSize,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: (res: QueryResType) => {
      // This timeout will prevent the table from showing during 0.1s the
      // previous state saved on data.
      setTimeout(() => {
        setLoading(false);
      }, 200);
      const newData = res[responseAccessKey].data.map((d: TableType) => {
        if (!subrowAccesor) return d as TableType;
        return { ...d, subRows: (d as any)[subrowAccesor] };
      });
      setData(newData);
      setTotal(res[responseAccessKey].count);
    },
  });

  useEffect(() => {
    if (loadingQuery) setLoading(true);
  }, [loadingQuery]);

  // Table manager
  const table = useReactTable({
    data,
    columns: getColumns({
      columnHelper,
      handleChangeSortDirection,
      t,
      extras: columnsExtras || ({} as ExtraColumnsType),
    }),
    manualExpanding: true,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSubRows: (row: any) => row.subRows,
    onColumnFiltersChange: setColumnFilters,
    getSortedRowModel: getSortedRowModel(),
    onExpandedChange: setExpanded,
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    manualPagination: true,
    pageCount: Math.ceil(total / pageSize),
    state: {
      pagination: {
        pageIndex,
        pageSize,
      },
      columnFilters,
      sorting,
      expanded,
    },
    onPaginationChange: (newPagination) => setPagination(newPagination),
  });

  return {
    data,
    resetPagination,
    setData,
    setTotal,
    total,
    pageSize,
    pageIndex,
    filters,
    setFilters,
    table,
    error,
    loading,
    refetch,
  };
};
