import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthLoader } from './AuthLoader';

// import { AuthLoader } from './AuthLoader';

const CheckAuth = () => {
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();

  const navigate = useNavigate();

  const getToken = (options: any) => {
    getAccessTokenSilently(options)
      .then((res) => {
        if (!res) throw Error('No token');
        setTimeout(() => navigate('/dashboard'), 2000);
      })
      .catch((error: any) => {
        navigate('/');
      });
  };

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!isAuthenticated) {
      loginWithRedirect({
        authorizationParams: {
          redirect_uri: process.env.REACT_APP_AUTH0_REDIRECT_URI || '',
        },
      });
      return;
    }

    getToken(process.env.REACT_APP_AUTH0_AUDIENCE);
  }, [isLoading, isAuthenticated]);

  return <AuthLoader />;
};

export default CheckAuth;
