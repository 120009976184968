import type { ColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { type TFunction } from 'i18next';

import { MoneyIcon } from '../../../assets';
import type { InvoicesTable } from '../../../types';
import { formatNumberMoney } from '../../../utils/numberFormatter';
import { DownloadButton } from '../../common/DownloadButton';
import { StatusText } from '../../common/StatusText';
import { BrandImage } from '../../images/BrandImage';
import { UsersImages } from '../../images/UserImages';
import DTBoldCell from '../DTBoldCell';
import { DTCell } from '../DTCell';
import DTHeader from '../DTHeader';

type GetInvoicesTableHeadersProps = {
  columnHelper: ColumnHelper<InvoicesTable>;
  handleChangeSortDirection: (id: string) => void;
  t: TFunction;
  extras: {};
};

export const getInfluencersInvoicesTableHeaders = ({
  columnHelper,
  handleChangeSortDirection,
  t,
}: GetInvoicesTableHeadersProps) => [
  // columnHelper.accessor('select', {
  //   size: 30,
  //   minSize: 30,
  //   maxSize: 30,
  //   header: ({ table }) => (
  //     <input
  //       checked={table.getIsAllPageRowsSelected()}
  //       onChange={table.getToggleAllPageRowsSelectedHandler()}
  //       type="checkbox"
  //       name="select"
  //       id="select"
  //       className="table-checkbox-th flex size-[18px] items-center justify-center rounded-md border border-white bg-green focus:ring-0 focus:ring-transparent focus:ring-offset-0"
  //     />
  //   ),
  //   cell: ({ row }) => (
  //     <input
  //       checked={row.getIsSelected()}
  //       disabled={!row.getCanSelect()}
  //       onChange={row.getToggleSelectedHandler()}
  //       type="checkbox"
  //       name="select"
  //       id="select"
  //       className="flex size-[18px] items-center justify-center rounded-md border border-green bg-transparent text-green checked:bg-green focus:ring-0 focus:ring-transparent focus:ring-offset-0"
  //     />
  //   ),
  //   enableSorting: false,
  //   enableHiding: false,
  // }),
  columnHelper.accessor('userName', {
    size: 150,
    minSize: 150,
    maxSize: 200,
    cell: (info) => {
      return (
        <DTBoldCell
          content={info.getValue()}
          id={info.cell.id}
          image={
            <UsersImages
              size="size-[38px]"
              userImages={[info.row.original.userProfilePicture || '']}
              userCount={1}
            />
          }
        ></DTBoldCell>
      );
    },
    header: ({ header, ...info }) => {
      return (
        <DTHeader
          sortable
          first
          onClick={() => handleChangeSortDirection(header.id)}
          title={t('invoices.header1-influencer')}
          sorting={(info as any).orderBy}
          sortingDirection={(info as any).orderDirection}
          accessor="userName"
        />
      );
    },
  }),
  columnHelper.accessor('status', {
    size: 150,
    minSize: 150,
    maxSize: 200,
    header: ({ header, ...info }) => (
      <DTHeader
        onClick={() => handleChangeSortDirection(header.id)}
        title={t('invoices.header2')}
        accessor="status"
        sortable
        sorting={(info as any).orderBy}
        sortingDirection={(info as any).orderDirection}
      />
    ),
    cell: (info) => <StatusText type="invoices" status={info.getValue()} />,
  }),
  columnHelper.accessor('campaignName', {
    size: 150,
    minSize: 150,
    maxSize: 200,
    header: ({ header, ...info }) => (
      <DTHeader
        sortable
        onClick={() => handleChangeSortDirection(header.id)}
        title={t('invoices.header3')}
        sorting={(info as any).orderBy}
        sortingDirection={(info as any).orderDirection}
        accessor="campaignName"
      />
    ),
    cell: (info) => <DTCell text={info.getValue()} />,
  }),
  columnHelper.accessor('amount', {
    size: 150,
    minSize: 150,
    maxSize: 200,
    header: ({ header, ...info }) => (
      <DTHeader
        sortable
        onClick={() => handleChangeSortDirection(header.id)}
        title={t('invoices.header4')}
        sorting={(info as any).orderBy}
        sortingDirection={(info as any).orderDirection}
        accessor="amount"
      />
    ),
    cell: (info) => (
      <DTCell text={formatNumberMoney(info.getValue())} icon={<MoneyIcon />} />
    ),
  }),
  columnHelper.accessor('billingDate', {
    size: 150,
    minSize: 150,
    maxSize: 200,
    header: ({ header, ...info }) => (
      <DTHeader
        sortable
        onClick={() => handleChangeSortDirection(header.id)}
        title={t('invoices.header5')}
        sorting={(info as any).orderBy}
        sortingDirection={(info as any).orderDirection}
        accessor="billingDate"
      />
    ),
    cell: (info) => (
      <DTCell
        text={
          info.getValue()
            ? dayjs(new Date(info.getValue())).format('DD-MM-YYYY')
            : ''
        }
      />
    ),
  }),
  columnHelper.accessor('paymentDate', {
    size: 150,
    minSize: 150,
    maxSize: 200,
    header: ({ header, ...info }) => (
      <DTHeader
        sortable
        onClick={() => handleChangeSortDirection(header.id)}
        title={t('invoices.header6')}
        sorting={(info as any).orderBy}
        sortingDirection={(info as any).orderDirection}
        accessor="paymentDate"
      />
    ),
    cell: (info) => (
      <DTCell
        text={
          info.getValue()
            ? dayjs(new Date(info.getValue())).format('DD-MM-YYYY')
            : ''
        }
      />
    ),
  }),
  columnHelper.display({
    size: 30,
    minSize: 30,
    maxSize: 30,
    id: 'download',
    cell: (info) => (
      <div
        className={`text-white ${info.row.original.file && info.row.original.file.url ? 'hover:text-influentia-light-blue' : 'opacity-40'}`}
      >
        <DownloadButton documentName={info.row.original.file} />
      </div>
    ),
  }),
];

export const getBrandsInvoicesTableHeaders = ({
  columnHelper,
  handleChangeSortDirection,
  t,
}: GetInvoicesTableHeadersProps) => [
  // columnHelper.accessor('select', {
  //   size: 30,
  //   minSize: 30,
  //   maxSize: 30,
  //   header: ({ table }) => (
  //     <input
  //       checked={table.getIsAllPageRowsSelected()}
  //       onChange={table.getToggleAllPageRowsSelectedHandler()}
  //       type="checkbox"
  //       name="select"
  //       id="select"
  //       className="table-checkbox-th flex size-[18px] items-center justify-center rounded-md border border-white bg-green focus:ring-0 focus:ring-transparent focus:ring-offset-0"
  //     />
  //   ),
  //   cell: ({ row }) => (
  //     <input
  //       checked={row.getIsSelected()}
  //       disabled={!row.getCanSelect()}
  //       onChange={row.getToggleSelectedHandler()}
  //       type="checkbox"
  //       name="select"
  //       id="select"
  //       className="flex size-[18px] items-center justify-center rounded-md border border-green bg-transparent text-green checked:bg-green focus:ring-0 focus:ring-transparent focus:ring-offset-0"
  //     />
  //   ),
  //   enableSorting: false,
  //   enableHiding: false,
  // }),
  columnHelper.accessor('brandName', {
    size: 150,
    minSize: 150,
    maxSize: 200,
    cell: (info) => {
      return (
        <DTBoldCell
          content={info.getValue()}
          id={info.cell.id}
          image={
            <BrandImage
              size="size-[38px]"
              image={info.row.original.brandProfilePicture}
            />
          }
        ></DTBoldCell>
      );
    },
    header: ({ header, ...info }) => {
      return (
        <DTHeader
          sortable
          first
          onClick={() => handleChangeSortDirection(header.id)}
          title={t('invoices.header1-brand')}
          sorting={(info as any).orderBy}
          sortingDirection={(info as any).orderDirection}
          accessor="brandName"
        />
      );
    },
  }),
  columnHelper.accessor('status', {
    size: 150,
    minSize: 150,
    maxSize: 200,
    header: ({ header, ...info }) => (
      <DTHeader
        onClick={() => handleChangeSortDirection(header.id)}
        title={t('invoices.header2')}
        accessor="status"
        sorting={(info as any).orderBy}
        sortingDirection={(info as any).orderDirection}
        sortable
      />
    ),
    cell: (info) => <StatusText type="invoices" status={info.getValue()} />,
  }),
  columnHelper.accessor('campaignName', {
    size: 150,
    minSize: 150,
    maxSize: 200,
    header: ({ header, ...info }) => (
      <DTHeader
        sortable
        onClick={() => handleChangeSortDirection(header.id)}
        title={t('invoices.header3')}
        sorting={(info as any).orderBy}
        sortingDirection={(info as any).orderDirection}
        accessor="campaignName"
      />
    ),
    cell: (info) => <DTCell text={info.getValue()} />,
  }),
  columnHelper.accessor('amount', {
    size: 150,
    minSize: 150,
    maxSize: 200,
    header: ({ header, ...info }) => (
      <DTHeader
        sortable
        onClick={() => handleChangeSortDirection(header.id)}
        title={t('invoices.header4')}
        sorting={(info as any).orderBy}
        sortingDirection={(info as any).orderDirection}
        accessor="amount"
      />
    ),
    cell: (info) => (
      <DTCell text={formatNumberMoney(info.getValue())} icon={<MoneyIcon />} />
    ),
  }),
  columnHelper.accessor('billingDate', {
    size: 150,
    minSize: 150,
    maxSize: 200,
    header: ({ header, ...info }) => (
      <DTHeader
        sortable
        onClick={() => handleChangeSortDirection(header.id)}
        title={t('invoices.header5')}
        sorting={(info as any).orderBy}
        sortingDirection={(info as any).orderDirection}
        accessor="billingDate"
      />
    ),
    cell: (info) => (
      <DTCell
        text={
          info.getValue()
            ? dayjs(new Date(info.getValue())).format('DD-MM-YYYY')
            : ''
        }
      />
    ),
  }),
  columnHelper.accessor('paymentDate', {
    size: 150,
    minSize: 150,
    maxSize: 200,
    header: ({ header, ...info }) => (
      <DTHeader
        sortable
        onClick={() => handleChangeSortDirection(header.id)}
        title={t('invoices.header6')}
        sorting={(info as any).orderBy}
        sortingDirection={(info as any).orderDirection}
        accessor="paymentDate"
      />
    ),
    cell: (info) => (
      <DTCell
        text={
          info.getValue()
            ? dayjs(new Date(info.getValue())).format('DD-MM-YYYY')
            : ''
        }
      />
    ),
  }),
  columnHelper.display({
    size: 30,
    minSize: 30,
    maxSize: 30,
    id: 'download',
    cell: (info) => (
      <div
        className={`text-white ${info.row.original.file && info.row.original.file.url ? 'hover:text-influentia-light-blue' : 'opacity-40'}`}
      >
        <DownloadButton documentName={info.row.original.file} />
      </div>
    ),
  }),
];
