import { gql } from '@apollo/client';

export const GET_PROFILE = gql`
  query Profile {
    profile {
      status
      code
      error
      data {
        id
        name
        time_zone
        time_zone_automatic
        language
        language_automatic
        email
        brand_id
        email_enabled
        role
        phone
        profile_picture
        platforms {
          id
          name
          url
        }
      }
    }
  }
`;
export const GET_PROFILE_NOTIFICATION = gql`
  query ProfileNotifications {
    profileNotifications {
      status
      code
      error
      data {
        id
        notifications {
          type
          app
          email
          push
        }
      }
    }
  }
`;

export const GET_USERS = gql`
  query Users(
    $name: String
    $role: String
    $offset: Int
    $platforms: [String]
    $limit: Int
    $orderBy: String
    $orderDirection: String
  ) {
    users(
      name: $name
      role: $role
      offset: $offset
      platforms: $platforms
      limit: $limit
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      status
      code
      error
      count
      data {
        id
        name
        email
        role
        profile_picture
        createdAt
        platforms {
          name
          url
        }
      }
    }
  }
`;

export const GET_SINGLE_USER = gql`
  query User($id: String!) {
    user(userId: $id) {
      status
      code
      error
      data {
        id
        name
        email
        time_zone
        time_zone_automatic
        language
        language_automatic
        role
        phone
        profile_picture
        blocked
        last_login
        platforms {
          id
          name
          url
        }
      }
    }
  }
`;
