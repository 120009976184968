import { useTranslation } from 'react-i18next';

import { CalendarIcon } from '../../../assets';
import { formatDateFull } from '../../../utils/numberFormatter';

export function DateText({
  date,
  text,
}: {
  date: string | null;
  text: string;
}) {
  const { t } = useTranslation('common');
  return (
    <div className="flex flex-row gap-[6px]">
      <div className="rounded-full bg-white flex justify-center items-center size-[28px] text-influentia-black">
        <CalendarIcon className="size-[16px]" />
      </div>
      <div className="flex flex-col gap-[5px]">
        <span className="text-influentia-light-blue font-medium text-[10px]">
          {t(`singleCampaignRecurrent.${text}`)}
        </span>
        {date && (
          <span className="text-xs font-medium ">
            {formatDateFull(Number(new Date(date)))}
          </span>
        )}
        {!date && (
          <span className="text-[10px] text-white italic">
            {t(`singleCampaignRecurrent.no${text}Date`)}
          </span>
        )}
      </div>
    </div>
  );
}
