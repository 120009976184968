import type { ChangeEvent, DragEvent } from 'react';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useNotification } from '../../hooks/useNotification';

type Props = {
  submitFile: (file: File) => void;
  fileType?: string;
  loading?: boolean;
};

const FileUploaderInvisible = ({
  submitFile,
  fileType = 'Profile',
  loading,
}: Props) => {
  const [file, setFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation('common');
  let fileTypes: string[] = [];
  const maxSize: number = 10;

  if (fileType === 'Profile') {
    fileTypes = ['.png', '.jpg'];
  }
  const { notifyFileError, notifySelectedFile, notifyFileSizeError } =
    useNotification();

  const selectFile = (selectedFile: File) => {
    const filesize = (selectedFile.size / 1024 / 1024).toFixed(4); // MB
    if (Number(filesize) > maxSize) {
      notifyFileSizeError(maxSize);
      setFile(null);
      fileInputRef.current!.value = '';
      return;
    }
    const ext = `.${selectedFile.name.split('.').pop()?.toLowerCase()}`;
    if (fileTypes.includes(String(ext))) {
      setFile(selectedFile);
      submitFile(selectedFile);
      setFile(null);
      fileInputRef.current!.value = '';
      notifySelectedFile();
    } else {
      notifyFileError();
    }
  };
  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (event.dataTransfer.files) {
      const selectedFile = event.dataTransfer.files[0] || null;
      if (selectedFile) {
        selectFile(selectedFile);
      }
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    if (event.target.files) {
      const selectedFile = event.target.files[0] || null;
      if (selectedFile) {
        selectFile(selectedFile);
      }
    }
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const openFileDialog = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onClick={openFileDialog}
      className="absolute top-0 z-50 size-full"
    >
      <input
        type="file"
        onChange={handleFileChange}
        className="hidden"
        ref={fileInputRef}
        accept={fileTypes.map((type) => `${type}`).join(',')}
      />
    </div>
  );
};

export default FileUploaderInvisible;
