import type { ReactNode } from 'react';

type DTCellProps = {
  text: string;
  icon?: ReactNode;
};

export const DTCell = ({ text, icon }: DTCellProps) => {
  return (
    <div className="flex flex-row gap-x-2 items-center">
      {icon}
      <p className="text-white text-sm">{text}</p>
    </div>
  );
};
