import React from 'react';
import { useTranslation } from 'react-i18next';

import { CheckBoxIcon, Tick } from '../../../assets';
import type { PieceStatusType, PlatformType } from '../../../types';
import type { ActionType } from '../../../types/action';

type CampaignActionsProps = {
  actions: {
    id: string;
    type: ActionType;
    platform: PlatformType;
    status: PieceStatusType;
    publishingAt: string;
  }[];
};

export const CampaignActions = ({ actions }: CampaignActionsProps) => {
  const { t } = useTranslation('common');

  return (
    <div className="flex flex-row gap-[6px]">
      <div className="rounded-full bg-white flex justify-center items-center size-[28px] text-influentia-black">
        <CheckBoxIcon />
      </div>
      <div className="flex flex-col gap-[5px]">
        <span className="text-influentia-light-blue font-medium text-[10px]">
          {t('singleCampaign.influencerActions')}
        </span>
        {actions && actions.length > 0 && (
          <div className="flex flex-col gap-1 ">
            {actions.map((action, i) => (
              <div
                key={action.id + i + Math.random()}
                className="flex flex-row gap-1 text-sm items-center"
              >
                <Tick className="size-[14px]" />
                <span className="font-medium text-white">
                  {t(`actionType.${action.type}`)} ({action.platform})
                </span>
              </div>
            ))}
          </div>
        )}
        {!actions ||
          (actions && actions.length === 0 && (
            <span className="text-[10px] text-white italic">
              {t('singleCampaign.noActions')}
            </span>
          ))}
      </div>
    </div>
  );
};
