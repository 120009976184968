import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Link, PlayIcon, TrashIcon } from '../../../assets';
import { Confirm } from '../../../context/confirm/confirm';
import { usePermissions } from '../../../hooks/usePermissions';
import { fileLink } from '../../../utils/utils';
import Loader from '../../loaders/LoaderSpinner';

type PreviewVersionProps = {
  contentLink?: string;
  deleteContent: () => void;
  contentStatus: string;
  fileUrl: string;
};
export function PreviewVersion({
  contentLink,
  deleteContent,
  contentStatus,
  fileUrl,
}: PreviewVersionProps) {
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation('common');
  const videoRef = useRef<HTMLVideoElement>(null);
  const [video, setVideo] = useState<string>('');
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setIsPlaying(true);
      } else {
        videoRef.current.pause();
        setIsPlaying(false);
      }
    }
  };
  useEffect(() => {
    const fetchVideo = async () => {
      await fileLink(
        await getAccessTokenSilently(),
        fileUrl,
        setVideo,
        setIsLoading,
      );
    };

    if (fileUrl) {
      fetchVideo();
    }

    return () => {
      setVideo(''); // Clean up video URL on component unmount or fileUrl change
      setIsPlaying(false);
    };
  }, [fileUrl]);
  const { isBrand } = usePermissions();

  return (
    <div className="flex gap-5">
      <Confirm
        open={open}
        onConfirm={() => deleteContent()}
        setOpen={setOpen}
        type="delete"
        title={t('global.deleteTitle')}
        subtitle={t('global.deleteSubtitle')}
        confirmText={t('global.delete')}
        cancelText={t('global.cancel')}
      />
      {fileUrl && (
        <>
          <div className={`p-1 text-influentia-black rounded-[4px] w-full`}>
            <div className="max-h-[160px] flex">
              <div className="relative items-center justify-center flex w-full bg-black min-h-[160px]">
                {isLoading ? (
                  <Loader color="text-white" size="size-[20px]" />
                ) : (
                  <video
                    className="max-h-[160px] cursor-pointer w-full"
                    src={`${video}#t=0`}
                    ref={videoRef}
                    controls={isPlaying}
                    onClick={handlePlayPause}
                  >
                    <source src={`${video}`} type="video/mp4" />
                  </video>
                )}
                {!isPlaying && !isLoading && (
                  <PlayIcon
                    className="size-[25px] text-black absolute cursor-pointer"
                    onClick={handlePlayPause}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      )}
      {contentLink && !fileUrl && (
        <>
          <div
            className={`bg-white p-1 text-influentia-black rounded-[4px] w-full`}
          >
            <div className="flex flex-row justify-between ">
              <div className="flex flex-row gap-[10px]  items-center justify-center">
                <div className="flex items-center justify-center">
                  <Link className={`size-[25px]`} />
                </div>

                <a
                  className="underline text-sm font-inter cursor-pointer"
                  href={contentLink}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {t('singleCampaign.linkPreview')}
                </a>
              </div>
            </div>
          </div>
        </>
      )}
      <div
        className={`flex items-center justify-center cursor-pointer mr-[5px] ${contentStatus === 'validated' || isBrand() ? 'hidden' : ''}`}
        onClick={() => setOpen(true)}
      >
        <TrashIcon className="size-[15px] text-red-status" />
      </div>
    </div>
  );
}
