import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { ROUTES } from '../../config/routes/config';

export const LateralProfileBrandMenu = ({ brand }: { brand: any }) => {
  const { t } = useTranslation('common');
  return (
    <div className="bg-dark-blue min-w-[230px] flex-none rounded-10 p-2.5 lg:flex hidden sticky top-8 h-[calc(100vh-150px)]">
      <div className="flex flex-col gap-2.5 size-full">
        <NavLink
          key={brand.id}
          className={({ isActive }) =>
            `${isActive ? '!bg-influentia-light-blue text-influentia-black' : 'text-white'} text-sm items-center font-semibold  flex flex-row gap-x-2 rounded-[5px] px-4 py-1`
          }
          to={ROUTES.SINGLE_BRAND.replace(':id', brand.id)}
        >
          {t('profile.menu.title1')}
        </NavLink>
        <NavLink
          key={`${brand.id}campaigns`}
          className={({ isActive }) =>
            `${isActive ? '!bg-influentia-light-blue text-influentia-black' : 'text-white'} text-sm items-center font-semibold flex flex-row gap-x-2 rounded-[5px] px-4 py-1`
          }
          to={ROUTES.SINGLE_BRAND_CAMPAIGNS.replace(':id', brand.id)}
        >
          {t('profile.menu.title2')}
        </NavLink>
      </div>
    </div>
  );
};
