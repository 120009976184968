import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../common/Button';

type TextAreaProps = {
  send: (text: string) => void;
  id?: string;
};

export function TextArea({ send, id }: TextAreaProps) {
  const { t } = useTranslation('common');
  const [text, setText] = useState('');

  return (
    <div className="relative">
      <textarea
        id={id}
        className="w-full h-[65px] px-[5px] py-[2px] rounded-[5px] border-none outline-none text-sm text-dark-blue"
        value={text}
        onChange={(event) => {
          setText(event.target.value);
        }}
      />
      <Button
        customClassnames="absolute right-2 bottom-3 w-[73px] items-center justify-center h-[20px] text-xs"
        style="dark-blue"
        text={t('global.send')}
        id="sendTextArea"
        onClick={() => {
          if (text === '') return;
          send(text);
          setText('');
        }}
      ></Button>
    </div>
  );
}
