import { useAuth0 } from '@auth0/auth0-react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { usePermissions } from '../../../hooks/usePermissions';
import type {
  SingleCampaignData,
  SingleCampaignMainInfo,
} from '../../../types';
import { downloadDocumentClick } from '../../../utils/utils';
import { Button } from '../../common/Button';
import { InternalNotes } from '../InternalNotes';
import { UserInfoCard } from '../UserInfoCard';
import { CampaignActions } from './CampaignActions';
import { Disclaimer } from './CampaignDisclaimer';
import { CampaignInfluencers } from './CampaignInfluencers';
import { CampaignLinksSidebar } from './CampaignLinksSidebar';
import { CampaignPrice } from './CampaignPrice';
import { DateText } from './DateText';
import { HiredPlatforms } from './HiredPlatforms';
import { PlatformsUrl } from './PlatformsUrl';

type SingleCampaignInfoProps = {
  influencerInfo: SingleCampaignData;
  campaignInfo: SingleCampaignMainInfo;
  campaignId: string;
  influencerId: string;
  refetch: () => void;
  isBrand: boolean;
  refetchTabs: () => void;
  location: string;
  recurrent?: boolean;
};

export function SingleCampaignInfo({
  campaignInfo,
  influencerInfo,
  campaignId,
  isBrand,
  influencerId,
  refetch,
  refetchTabs,
  location,
  recurrent = false,
}: SingleCampaignInfoProps) {
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation('common');
  const { isInfluencer, isBrand: isBrandRole } = usePermissions();
  const [loading, setLoading] = useState(false);

  const showInfluencerSidebar = () => {
    // If user is influencer always show brand info in sidebar
    if (isInfluencer()) return true;
    // If user is a brand user, show brand only on resources, metrics and invoices
    if (isBrandRole()) {
      if (
        location === 'metrics' ||
        location === 'invoices' ||
        location === 'resources'
      )
        return false;
      return true;
    }
    // Logic for influentia users
    // If user is in metrics or settings tab, show brand info in sidebar
    if (location === 'metrics') return false;
    // If user is in invoices show brand sidebar
    if (location === 'invoices') return false;
    // In resources, show brand sidebar
    if (location === 'resources') return false;
    // In settings, show brand sidebar
    if (location === 'settings') return false;
    // if anything else, show selected influencer
    return true;
  };

  const getShowPrice = () => {
    // If user is influencer always show brand info in sidebar
    if (isBrandRole()) {
      if (
        location === 'dates' ||
        location === 'general' ||
        location === 'content'
      )
        return false;
    }
    return true;
  };

  const influencerSidebar = useMemo(
    () => showInfluencerSidebar(),
    [location, isBrand, showInfluencerSidebar],
  );

  const showPrice = useMemo(() => getShowPrice(), [location, getShowPrice]);

  if (influencerSidebar && !influencerInfo) return <></>;
  return (
    <>
      <div className="bg-dark-blue 2xl:p-5 p-2 flex flex-col gap-2.5 rounded-10 lg:w-[400px] 2xl:w-[420px]">
        <div>
          <UserInfoCard
            influencerSidebar={influencerSidebar}
            campaignId={campaignId}
            influencerId={influencerId}
            name={
              influencerSidebar && !isInfluencer()
                ? influencerInfo.name
                : campaignInfo.brand_name
            }
            recurrent={recurrent}
            date={influencerInfo.publicationDate}
            showDate={influencerSidebar && !isInfluencer() && !recurrent}
            status={influencerInfo.status ? influencerInfo.status : ''}
            campaignStatus={campaignInfo.status!}
            campaignPaused={campaignInfo.paused}
            influencerPaused={influencerInfo.paused}
            refetch={refetch}
            refetchTabs={refetchTabs}
            image={
              influencerSidebar && !isInfluencer()
                ? influencerInfo.profilePicture
                : campaignInfo.brand_profile_picture
            }
            isBrand={isBrand || !influencerSidebar}
          />
        </div>
        {showPrice && !recurrent && (
          <CampaignPrice
            price={
              influencerSidebar
                ? influencerInfo.influencerMoney || 0
                : campaignInfo.money || 0
            }
            moneyType={
              influencerSidebar
                ? influencerInfo.influencerMoneyType
                : campaignInfo.moneyType
            }
          />
        )}
        <HiredPlatforms
          platforms={
            influencerSidebar
              ? influencerInfo.platforms
              : campaignInfo.platforms
          }
        />
        {influencerSidebar && !recurrent && (
          <CampaignLinksSidebar links={influencerInfo.links} />
        )}
        {isInfluencer() && (
          <Disclaimer disclaimers={influencerInfo.disclaimers} />
        )}
        {influencerSidebar && !isInfluencer() && !recurrent && (
          <PlatformsUrl platforms={influencerInfo.platformsLinks} />
        )}
        {recurrent && influencerSidebar && (
          <DateText date={influencerInfo.initDate || null} text="init" />
        )}
        {recurrent && influencerSidebar && (
          <DateText date={influencerInfo.finishDate || null} text="finish" />
        )}
        {influencerSidebar && !recurrent && (
          <CampaignActions actions={influencerInfo.actions} />
        )}
        {!influencerSidebar && (
          <CampaignInfluencers influencers={campaignInfo.influencers} />
        )}
        {influencerInfo.briefing && (
          <Button
            text={t('global.downloadBriefing')}
            style="light-blue"
            loading={loading}
            customClassnames={`w-full items-center flex justify-center hover:!bg-influentia-black`}
            onClick={async () => {
              if (influencerInfo.briefing && !loading) {
                try {
                  const accessToken = await getAccessTokenSilently();
                  await downloadDocumentClick(
                    String(influencerInfo.briefing),
                    accessToken,
                    setLoading,
                  );
                } catch (error) {
                  setLoading(false);
                }
              }
            }}
          />
        )}
        {!influencerInfo.briefing && (
          <span className="flex items-center justify-center text-center italic text-sm">
            {t('global.noBriefing')}
          </span>
        )}
      </div>
      {!recurrent && (
        <div className="mt-7">
          <InternalNotes
            notes={influencerInfo.notes}
            campaignId={campaignId}
            influencerId={influencerId}
            refetch={refetch}
          />
        </div>
      )}
    </>
  );
}
