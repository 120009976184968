import { useTranslation } from 'react-i18next';

import { FileIcon, FilePlayIcon } from '../../../assets';
import type { ContentStatusType } from '../../../types';
import type { ContentType } from '../../../types/content';
import { StatusText } from '../../common/StatusText';

type ActionContentProps = {
  type: ContentType;
  status: ContentStatusType;
  className?: string;
  classNameIcons?: string;
};

export function ActionContentInfo({
  type,
  status,
  className = 'bg-white p-[4px]',
  classNameIcons = 'size-[20px]',
}: ActionContentProps) {
  const { t } = useTranslation('common');
  return (
    <div className={`${className} text-influentia-black rounded-[4px]`}>
      <div className="flex lg:flex-row flex-col justify-between">
        <div className="flex flex-row gap-[10px] items-center justify-center">
          <div className="flex items-center justify-center">
            {type === 'Script' && <FileIcon className={`${classNameIcons}`} />}
            {type === 'Preview' && (
              <FilePlayIcon className={`${classNameIcons}`} />
            )}
          </div>
          <div className="text-sm font-inter">{t(`contentType.${type}`)}</div>
        </div>
        <div className="ml-2 flex items-center justify-center lg:mt-0 mt-1">
          <StatusText type="content" status={status} />
        </div>
      </div>
    </div>
  );
}
