import type {
  CampaignStatusType,
  ContentStatusType,
  InfluencerCampaignStatusType,
  InvoicesStatusType,
  PieceStatusType,
  PlatformType,
} from '../types';

export const ROLES = {
  brand: 'Brand',
  influencer: 'Influencer',
  influentia: 'Influentia',
};

export const CAMPAIGN_USER_STATUS: InfluencerCampaignStatusType[] = [
  'not_send',
  'accepted_pending',
  'declined',
  'confirmation_pending',
  'active',
  'analysis',
  'facturation',
  'canceled',
  'finished',
];

export const ACTION_STATUS: PieceStatusType[] = [
  'tobe_scheduled',
  'script_upload',
  'script_validation',
  'preview_upload',
  'preview_validation',
  'scheduled',
  'canceled',
  'published',
];

export const CAMPAIGN_STATUS: CampaignStatusType[] = [
  'facturation',
  'pending',
  'declined',
  'active',
  'finished',
  'canceled',
];

export const CAMPAIGN_RECURRENT_STATUS: CampaignStatusType[] = [
  'pending',
  'declined',
  'active',
  'finished',
  'canceled',
];

export const CONTENT_STATUS: ContentStatusType[] = [
  'upload_pending',
  'internal_validation',
  'external_validation',
  'modification_pending',
  'validated',
  'old',
];

export const INVOICE_STATUS: InvoicesStatusType[] = ['payment_pending', 'paid'];

export const PLATFORMS: PlatformType[] = [
  'Youtube',
  'Instagram',
  'Telegram',
  'Substack',
  'TikTok',
  'Twitter',
  'Blog',
];

export const DEFAULT_PAGE_SIZE = 10;
export const PAGE_SIZES = [5, 10, 20, 30, 50];

export const ACTION_TYPES: Record<PlatformType, string[]> = {
  Youtube: ['Dedicated_Video', 'Mention', 'Semi_Dedicated_Video', 'Other'],
  Instagram: ['Reel', 'Story', 'Other'],
  TikTok: ['Video', 'Other'],
  Twitter: ['Tweet', 'Thread', 'Other'],
  Substack: ['Sponsored_Post', 'Dedicated_Post', 'Other'],
  Blog: ['Dedicated_Post', 'Sponsored_Post', 'Email', 'Other'],
  Telegram: ['Message', 'Story', 'Other'],
};

export const CURRENCY_TYPES = ['€', '$'];

export const LANGUAGES = ['en', 'es', 'de'];
export const NOTIFICATIONS: Record<string, any> = {
  campaigns: {
    notifications: {
      'new-campaign-note': {
        roles: ['Influencer', 'Brand', 'Influentia'],
      },
      'campaign-pending-validation': {
        roles: ['Influentia'],
      },
      'new-active-campaign': {
        roles: ['Influentia'],
      },
      'influencer-added-campaign': {
        roles: ['Influentia'],
      },
      'campaign-declined-influencer': {
        roles: ['Influentia'],
      },
      'campaign-accepted-influencer': {
        roles: ['Influentia'],
      },
      'campaign-canceled': {
        roles: ['Influencer'],
      },
      'new-campaign-proposal': {
        roles: ['Influencer'],
      },
    },
  },
  dates: {
    notifications: {
      'define-campaign-post-date-range': {
        roles: ['Influentia', 'Brand'],
      },
      'set-campaign-post-date-range': {
        roles: ['Influentia', 'Influencer'],
      },
      'update-campaign-post-date-range': {
        roles: ['Influentia'],
      },
      'post-date-defined': {
        roles: ['Influencer', 'Brand', 'Influentia'],
      },
      'post-date-updated': {
        roles: ['Influencer', 'Brand', 'Influentia'],
      },
    },
  },
  content: {
    notifications: {
      'script-pending-influentia': {
        roles: ['Influentia'],
      },
      'preview-pending-influentia': {
        roles: ['Influentia'],
      },
      'script-pending-influencer': {
        roles: ['Influencer'],
      },
      'preview-pending-influencer': {
        roles: ['Influencer'],
      },
      'script-pending-validation-influentia': {
        roles: ['Influentia'],
      },
      'preview-pending-validation-influentia': {
        roles: ['Influentia'],
      },
      'script-declined': {
        roles: ['Influencer', 'Influentia'],
      },
      'preview-declined': {
        roles: ['Influencer', 'Influentia'],
      },
      'new-validation-message': {
        roles: ['Influencer', 'Influentia', 'Brand'],
      },
      'script-pending-validation-brand': {
        roles: ['Brand'],
      },
      'preview-pending-validation-brand': {
        roles: ['Brand'],
      },
    },
  },
  invoices: {
    notifications: {
      'new-invoice-influentia': {
        roles: ['Influentia'],
      },
      'paid-invoice': {
        roles: ['Influencer'],
      },
      'new-invoice-brand': {
        roles: ['Brand'],
      },
    },
  },
};
