import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CrossIcon } from '../../../assets';
import { Confirm } from '../../../context/confirm/confirm';
import { downloadDocumentClick, fileLink } from '../../../utils/utils';

type MetricPictureProps = {
  fileUrl: string;
  deleteContent: () => void;
};

export function MetricPicture({ fileUrl, deleteContent }: MetricPictureProps) {
  const { getAccessTokenSilently } = useAuth0();
  const [picture, setPicture] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);

  const handleDownloadClick = async () => {
    if (!fileUrl) return;
    await downloadDocumentClick(fileUrl, await getAccessTokenSilently());
  };
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('common');
  useEffect(() => {
    const fetchImage = async () => {
      await fileLink(
        await getAccessTokenSilently(),
        fileUrl,
        setPicture,
        setIsLoading,
      );
    };

    if (fileUrl) {
      fetchImage();
    }

    return () => {
      setPicture('');
      setIsLoading(false);
    };
  }, [fileUrl]);
  return (
    <>
      <Confirm
        open={open}
        onConfirm={() => deleteContent()}
        setOpen={setOpen}
        type="delete"
        title={t('global.deleteTitle')}
        subtitle={t('global.deleteSubtitle')}
        confirmText={t('global.delete')}
        cancelText={t('global.cancel')}
      />
      {!isLoading && (
        <div className="size-[146px] bg-gray rounded-10 cursor-pointer relative z-0">
          <a
            onClick={handleDownloadClick}
            rel="noreferrer noopener"
            target="_blank"
          >
            <img
              src={picture}
              alt="Metric Picture"
              className="size-full object-cover rounded-10"
            />
          </a>
          <button
            className="absolute top-0 right-0 p-px z-5"
            onClick={() => setOpen(true)}
          >
            <CrossIcon />
          </button>
        </div>
      )}
    </>
  );
}
