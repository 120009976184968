import { useTranslation } from 'react-i18next';

import { NotFoundHumans } from '../../assets';
import { Button } from '../common/Button';

export function NotFound() {
  const { t } = useTranslation('common');
  return (
    <div className="h-full flex items-center justify-center mt-32">
      <div className="flex flex-col items-center justify-center">
        <NotFoundHumans />
        <div className="flex flex-col items-center justify-center text-center gap-2.5 mt-7 max-w-[533px]">
          <span className="text-[25px] font-bold capitalize">
            {t('notFound.title')}
          </span>
          <span className="text-[16px]">{t('notFound.subtitle')}</span>
          <Button
            style="light-blue"
            text={t('notFound.button')}
            customClassnames="mt-2.5 px-6"
            onClick={() => window.location.replace('/')}
          />
        </div>
      </div>
    </div>
  );
}
