import { useMutation } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  CalendarIcon,
  FileIcon,
  MoneyCashIcon,
  TrashIcon,
} from '../../../assets';
import { INVOICE_STATUS } from '../../../config/constants';
import { Confirm } from '../../../context/confirm/confirm';
import {
  DELETE_INVOICE,
  UPDATE_INVOICE,
} from '../../../graphql/invoices/mutation';
import { useNotification } from '../../../hooks/useNotification';
import { usePermissions } from '../../../hooks/usePermissions';
import { formatNumberMoney } from '../../../utils/numberFormatter';
import { downloadDocumentClick } from '../../../utils/utils';
import { StatusText } from '../../common/StatusText';
import { DatePickerInput } from '../../inputs/DatePicker';
import { GenericDropdown } from '../../inputs/GenericDropdown';
import { MoneyInput } from '../../inputs/MoneyInput';

export function SingleInvoice({
  invoice,
  refetch,
}: {
  invoice: any;
  refetch: any;
}) {
  const { t } = useTranslation('common');
  const { getAccessTokenSilently } = useAuth0();
  const { control, setValue, watch } = useForm();
  const { isInfluentia, isInfluencer } = usePermissions();
  const fetchItems = async () => {
    if (invoice.file && !invoice.file.url) return;
    await downloadDocumentClick(
      invoice.file.url,
      await getAccessTokenSilently(),
    );
  };
  useEffect(() => {
    setValue('Price', invoice.amount);
    setValue('billingDate', invoice.billingDate);
    setValue('paymentDate', invoice.paymentDate);
    setValue('status', invoice.status);
    setValue('currency', invoice.amountType);
  }, [invoice]);
  const [updateInvoiceDB, { loading }] = useMutation(UPDATE_INVOICE);
  const [deleteInvoiceDB] = useMutation(DELETE_INVOICE);
  const price = watch('Price');
  const paymentDate = watch('paymentDate');
  const billingDate = watch('billingDate');
  const status = watch('status');
  const {
    notifySaveSuccess,
    notifySaveError,
    notifyDeleteError,
    notifyDeleteOk,
  } = useNotification();

  const updateInvoice = () => {
    updateInvoiceDB({
      variables: {
        invoiceId: invoice.id,
        amount: Number(price),
        amountType: watch('currency'),
        paymentDate: watch('paymentDate')
          ? dayjs(watch('paymentDate')).format('YYYY-MM-DD')
          : undefined,
        billingDate: watch('billingDate')
          ? dayjs(watch('billingDate')).format('YYYY-MM-DD')
          : undefined,
        status: watch('status'),
      },
      onCompleted: () => {
        refetch();
        notifySaveSuccess();
      },
      onError: () => {
        notifySaveError();
      },
    });
  };
  const [open, setOpen] = useState(false);

  const deleteInvoice = () => {
    deleteInvoiceDB({
      variables: {
        invoiceId: invoice.id,
      },
      onCompleted: () => {
        refetch();
        notifyDeleteOk();
      },
      onError: () => {
        notifyDeleteError();
      },
    });
  };

  useEffect(() => {
    if (watch('currency') !== invoice.amountType) {
      updateInvoice();
    }
  }, [watch('currency')]);

  return (
    <>
      <Confirm
        open={open}
        onConfirm={deleteInvoice}
        setOpen={setOpen}
        type="delete"
        title={t('global.deleteTitle')}
        subtitle={t('global.deleteSubtitle')}
        confirmText={t('global.delete')}
        cancelText={t('global.cancel')}
      />
      {invoice.file && (
        <div className="flex flex-row">
          <div className="flex flex-col mr-5 bg-white min-w-[240px] min-h-[150px] items-center rounded-[5px]">
            <div className="mt-1.5">
              {isInfluentia() && (
                <GenericDropdown
                  height="h-[37px]"
                  isClearable={false}
                  control={control}
                  isLoading={loading}
                  onChangeManual={(val) => {
                    setValue('status', val.value);
                    updateInvoice();
                  }}
                  name="status"
                  colored={true}
                  options={INVOICE_STATUS.map((statusI) => ({
                    value: statusI,
                    label: t(`invoices.${statusI}`),
                  }))}
                ></GenericDropdown>
              )}
              {!isInfluentia() && status && (
                <StatusText type="invoices" status={status} />
              )}
            </div>
            <a
              onClick={fetchItems}
              target="_blank"
              rel="noreferrer noopener"
              className="cursor-pointer flex flex-col rounded items-center justify-center text-center gap-2.5 max-h-[102px] mt-2.5"
            >
              <FileIcon className={`size-[35px] text-influentia-black`} />
              <div className="text-influentia-black text-sm font-inter truncate max-w-[220px]">
                {invoice.file.name}
              </div>
            </a>
            {(isInfluentia() || isInfluencer()) && (
              <div
                className={`flex cursor-pointer flex-row items-end mb-1.5 gap-1.5 h-full`}
                onClick={() => setOpen(true)}
              >
                <TrashIcon className="size-[15px] text-red-status" />
                <span className="text-red-status text-[10px] font-medium">
                  {t('global.deleteInvoice')}
                </span>
              </div>
            )}
          </div>
          <div className="w-full max-w-[270px] ">
            <div
              className={`flex flex-row gap-1.5 ${isInfluencer() && 'hidden'}`}
            >
              <div className="bg-white rounded-full size-7 p-1.5 mt-4 flex items-center justify-center">
                <MoneyCashIcon className="size-[16px] text-influentia-black" />
              </div>
              {isInfluentia() && (
                <MoneyInput
                  height="h-[33px]"
                  control={control}
                  type="blue"
                  currencySelect={true}
                  currentCurrency={watch('currency')}
                  name="Price"
                  label={t('singleCampaign.invoiceCampaignPrice')}
                  onMouseLeave={updateInvoice}
                />
              )}
              {!isInfluentia() && (
                <div className="flex flex-col w-full">
                  <label className="mt-1.5 font-medium text-[10px] text-influentia-light-blue">
                    {t('singleCampaign.invoiceCampaignPrice')}
                  </label>
                  <span className="text-sm text-white font-medium">
                    {formatNumberMoney(price) || t('global.notSet')}
                  </span>
                </div>
              )}
            </div>
            <div className="flex flex-row gap-1.5 w-full">
              <div className="bg-white rounded-full size-7 p-1.5 mt-4 flex items-center justify-center">
                <CalendarIcon className="size-[16px] text-influentia-black" />
              </div>
              {isInfluentia() && (
                <DatePickerInput
                  fullSpace
                  height="h-[37px]"
                  control={control}
                  type="blue"
                  name="billingDate"
                  label={t('global.billingDate')}
                  onChange={updateInvoice}
                />
              )}
              {!isInfluentia() && (
                <div className="flex flex-col w-full">
                  <label className="mt-1.5 font-medium text-[10px] text-influentia-light-blue">
                    {t('global.billingDate')}
                  </label>
                  <span className="text-sm text-white font-medium">
                    {billingDate || t('global.notSet')}
                  </span>
                </div>
              )}
            </div>
            <div className="flex flex-row gap-1.5">
              <div className="bg-white rounded-full size-7 p-1.5 mt-4 flex items-center justify-center">
                <CalendarIcon className="size-[16px] text-influentia-black" />
              </div>
              {isInfluentia() && (
                <DatePickerInput
                  height="h-[37px]"
                  fullSpace
                  control={control}
                  type="blue"
                  name="paymentDate"
                  label={t('global.paymentDate')}
                  onChange={updateInvoice}
                />
              )}
              {!isInfluentia() && (
                <div className="flex flex-col w-full">
                  <label className="mt-1.5 font-medium text-[10px] text-influentia-light-blue">
                    {t('global.paymentDate')}
                  </label>
                  <span className="text-sm text-white font-medium">
                    {paymentDate || t('global.notSet')}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
