import { useAuth0 } from '@auth0/auth0-react';

import { FileIcon } from '../../assets';
import { downloadDocumentClick } from '../../utils/utils';

type Props = {
  documentName?:
    | {
        id: string;
        name: string;
        url: string;
      }
    | undefined;
};

export const DownloadButton = ({ documentName }: Props) => {
  const { getAccessTokenSilently } = useAuth0();
  const handleDownloadClick = async () => {
    if (!documentName) return;
    await downloadDocumentClick(
      documentName.url,
      await getAccessTokenSilently(),
    );
  };

  // const setImageModal = useSetRecoilState(isImageModalOpenAtom);

  // const handleOpenFullImageModal = () => {
  //   setImageModal({
  //     isOpen: true,
  //     isQR: false,
  //     documentUrl: url || documentName || '',
  //   });
  // };

  // const handleSeeDocument = () => {
  //   handleOpenFullImageModal();
  // };

  return (
    <div className="flex items-center gap-2">
      <button
        onClick={handleDownloadClick}
        id="downloadDocument"
        data-document={documentName?.url}
        rel="noreferrer"
        // download={document}
        className={`${documentName ? 'cursor-pointer' : 'cursor-default'}`}
      >
        <FileIcon />
      </button>
      {/* <div className="cursor-pointer" onClick={handleSeeDocument}>
              <img className="h-[16px]" src={iconSee} alt="icon see" />
            </div> */}
    </div>
  );
};
