import { atom } from 'recoil';

import type {
  BrandsFilters,
  CampaignFiltersAtom,
  InfluencersFilters,
  InfluentiaUsersFilters,
  InvoicesFilters,
  SingleUser,
} from '../types';

export const userAtom = atom({
  key: 'user', // unique ID
  default: {} as SingleUser, // default value
});

export const apolloErrorModal = atom({
  key: 'apolloErrorModal', // unique ID
  default: {
    errors: [],
    show: false,
  } as { errors: string[]; show: boolean }, // default value
});

export const campaignFilterAtom = atom<CampaignFiltersAtom | null>({
  key: 'campaignFilterAtom',
  default: null,
});
export const campaignFilterUserAtom = atom<CampaignFiltersAtom | null>({
  key: 'campaignFilterUserAtom',
  default: null,
});
export const campaignFilterBrandAtom = atom<CampaignFiltersAtom | null>({
  key: 'campaignFilterBrandAtom',
  default: null,
});

export const influencersFilterAtom = atom<InfluencersFilters | null>({
  key: 'influencersFilterAtom',
  default: null,
});

export const influentiaUsersFilterAtom = atom<InfluentiaUsersFilters | null>({
  key: 'influentiaUsersFilterAtom',
  default: null,
});

export const brandsFilterAtom = atom<BrandsFilters | null>({
  key: 'brandsFilterAtom',
  default: null,
});

export const influencersInvoicesFilterAtom = atom<InvoicesFilters | null>({
  key: 'influencersInvoicesFilterAtom',
  default: null,
});

export const brandsInvoicesFilterAtom = atom<InvoicesFilters | null>({
  key: 'brandsInvoicesFilterAtom',
  default: null,
});
